<template>
    <div class="about">
        <h1> Testing API </h1>

        <p v-html="$formatTime('2010-10-01', {format: 'short'})"></p>


        <div>
            <b-button>Button</b-button>
            <b-button variant="danger">Button</b-button>
            <b-button variant="success">Button</b-button>
            <b-button variant="outline-primary">Button</b-button>
        </div>

        <div>
            {{$store.state.authentication.user}}
        </div>
        <div>
            {{  new Date( decoded.exp * 1000)  }}
        </div>

        <!--<table>
        <tr>
            <td><input v-model="filter.fieldname" /></td>
            <td><input v-model="filter.fieldvalue" /></td>
        </tr>
    </table>-->
        <!--<div v-for="f in  $store.state.products.productsMetadata.groups" :key="f.groupid"  >

      {{f.groupid}}
          <div v-for="att in f.attributes" :key="att.attrid">
              {{att.name}} - {{att.type}}
          </div>
    </div>-->
        <!--<button v-on:click="filter_products">Filtrar</button>

    <div>
        {{$store.state.products.operation.processing }}
    </div>-->
        <!--<br />
    <h6>Products</h6>
    <table border="1">
        <tr v-for="(item,index) in $store.state.products.products.offerlist" :key="index">
            <td>  {{item.name}}      </td>
            <td>
                <div v-for="group in  item.groups" :key="group.groupid">
                    <table>
                        <tr>
                            <td>   {{group.name}} </td>
                            <td>
                                <ul v-for="att in group.attributes" :key="att.attrid">
                                    <li>  {{att.name}} : {{att.value}}</li>
                                </ul>
                            </td>
                        </tr>
                    </table>

                </div>
            </td>

        </tr>
    </table>-->
        <!--<br />
    <h6>Products Metadata</h6>
    <div>
        {{$store.state.products.productsMetadata }}

    </div>

    <br />
    <h6>Params</h6>
    <div>
        {{filter}}
    </div>-->


    </div>

</template>

<script>

   // import AccountCreate from '@/components/AccountCreate.vue'
    import VueJwtDecode from 'vue-jwt-decode';


    export default {
        name: 'Test',
        components: {
     //       AccountCreate
        },
        props: {
            search: {}
        },
        data() {
            return {

                filter: [],
                params: {},
                decoded:   VueJwtDecode.decode(  this.$store.state.authentication.user.jwtToken) 
            }
        },

        methods: {

            submitUser: function (user) {

                //console.log("-----------------------------");
                //console.log(user);

                this.$store.dispatch('authentication/createUser', user);


            },
            filter_products: function () {


                //console.log("--------Filter product-------------");

                this.params.filter = this.filter;

                //console.log(this.params);

               this.$store.dispatch('products/getProducts', this.params);

            },


        },
        computed: {


        },
        mounted() {

            this.$store.dispatch('products/getProductsMetadata');
            this.$store.dispatch('products/getProducts', this.field);

        },
        created: function () {

        },
        destroyed: function () {

        }

    };
</script>
