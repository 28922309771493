<template>
    <div class="profile-menu">
        <ul class="menu-list m-0">
            <li>

                <router-link class="item-entry" :to="`/${$i18n.locale}/profile/details`">                  
                    <span class="menu-text">
                        {{$t('profile')}}
                    </span>
                    <i class="icon-right" aria-hidden="true"></i>               
                </router-link>
            </li>
            <li>

                <router-link class="item-entry" :to="`/${$i18n.locale}/profile/requests`">
                    <span class="menu-text">
                        {{$t('requests')}}
                    </span>
                    <i class="icon-right" aria-hidden="true"></i>

                </router-link>
            </li>
            <!--<li>
                <a :href="`/${$i18n.locale}/profile/whishlist`" class="item-entry" @click="clickedOnItem">
                    <span class="menu-text">
                        {{$t('whishlist')}}
                    </span>
                    <i class="icon-right" aria-hidden="true"></i>
                </a>
            </li>-->
            <li>
                <router-link class="item-entry" :to="`/${$i18n.locale}/profile/historic`">                   
                    <span class="menu-text">
                        {{$t('historic')}}
                    </span>
                    <i class="icon-right" aria-hidden="true"></i>                    
                </router-link>
            </li>
            <li>
                <router-link class="item-entry" :to="`/${$i18n.locale}/profile/warnings`">              
                    <span class="menu-text">
                        {{$t('warnings')}}
                    </span>
                    <div class="alert-count-indicator ml-2" v-if="$store.state.profile.alerts.nresults > 0" >
                        <div class="text"> {{$store.state.profile.num_alerts_unread}}  </div>
                    </div>
                    <i class="icon-right" aria-hidden="true"></i>                  
                </router-link>
            </li>
            <!--<li>
                <a :href="`/${$i18n.locale}/profile/my-addresses`" class="item-entry" @click="clickedOnItem">
                    <span class="menu-text">
                        {{$t('my_addresses')}}
                    </span>
                    <i class="icon-right" aria-hidden="true"></i>
                </a>
            </li>
            <li>
                <a :href="`/${$i18n.locale}/profile/permissions`" class="item-entry" @click="clickedOnItem">
                    <span class="menu-text">
                        {{$t('permissions')}}
                    </span>
                    <i class="icon-right" aria-hidden="true" @click="clickedOnItem"></i>
                </a>
            </li>-->
            <li>
                <a href="#" class="item-entry" style="border-bottom: none;" @click="clickedOnLogout">
                    <span class="menu-text">
                        {{$t('logout')}}
                    </span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {

        },
        data() {
            return {
            }
        },


        

        methods: {
            clickedOnItem() {
                this.$emit('on-item-click');
            },
            clickedOnLogout() {
                this.$store.dispatch('authentication/logout').then(
                    () => {
                        this.$router.push('/')
                    }
                );
            }
        }
    }
</script>