<template>
    <div class="purchase-step-1 purchase-page">
        <b-container fluid class="p-0 content">
            <b-row no-gutters>
                <b-col md="6" sm="12">
                    <b-container v-if="!Address" fluid class="verify-coverage p-4 verify-coverage-mobile d-none">
                        <b-row no-gutters class="mb-3">
                            <b-col cols="auto" class="col-icon pr-4">
                                <div class="icon-wrapper-30 primary">
                                    <i class="icon-info"></i>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="text-semibold-14">{{$t('purchase_step_1_page.message_choose_address')}}</div>
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col>
                                <a class="button-alternative small" href="#" @click="GoToCoverage"    >
                                    <div class="icon-wrapper-24 icon">
                                        <i class="icon-coverage" aria-hidden="true"></i>
                                    </div>
                                    <span>{{$t('purchase_step_1_page.button_text_choose_address')}}</span>
                                </a>

                            </b-col>
                        </b-row>
                    </b-container>
                    <b-container v-else fluid class="verify-coverage p-4 verify-coverage-mobile d-none">
                        <b-row no-gutters class="mb-4">
                            <b-col>
                                <div class="text-bold-20">{{$t('purchase_step_1_page.title_address_section')}}</div>
                                <div class="text-regular-12">{{$t('purchase_step_1_page.description_address_section')}}</div>
                            </b-col>

                        </b-row>
                        <b-row no-gutters>
                            <b-col>
                                <div class="address-container">
                                    <b-container fluid class="p-0">
                                        <b-row no-gutters>
                                            <b-col cols="auto" class="px-2">
                                                <div class="icon-wrapper-24">
                                                    <i class="icon-coverage" aria-hidden="true"></i>
                                                </div>
                                            </b-col>
                                            <b-col>
                                                <div class="address-item">{{Address.street}} {{ Address.door}}</div>
                                                <div class="address-item">{{Address.cp4}}-{{ Address.cp3}}</div>
                                                <div class="address-item">{{Address.locality}} </div>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                    <!-- Processing  -->
                    <div class="offer-card-details" style="height: 100%">
                        <b-overlay v-if="$store.state.orders.getProductDetailProcessing" :show="$store.state.orders.getProductDetailProcessing"
                                   spinner-variant="primary" class="m-5"></b-overlay>
                        <b-container fluid class="p-0" v-if="!$store.state.orders.getProductDetailProcessing">
                            <b-row no-gutters class="mb-4">
                                <b-col>
                                    <div class="text-bold-20">{{$t('purchase_step_1_page.title_product_section')}}</div>
                                    <div class="text-regular-12">{{$t('purchase_step_1_page.description_product_section')}}</div>
                                </b-col>
                            </b-row>
                            <product-card-extended></product-card-extended>
                        </b-container>
                    </div>
                    <!-- End Processing  -->
                </b-col>
                <b-col md="6" sm="12">
                    <div class="actions">
                        <b-container fluid class="p-0">
                            <ValidationObserver v-slot="{ invalid }">
                                <b-row no-gutters>
                                    <b-col>
                                        <b-container  v-if="Address" fluid class="verify-coverage p-4 verify-coverage-desktop">
                                            <b-row no-gutters class="mb-3">
                                                <b-col>
                                                    <div class="text-bold-20">{{$t('purchase_step_1_page.title_address_section')}}</div>
                                                    <div class="text-regular-12">{{$t('purchase_step_1_page.description_address_section')}}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters>
                                                <b-col>
                                                    <div class="address-container">
                                                        <b-container fluid class="p-0">
                                                            <b-row no-gutters>
                                                                <b-col cols="auto" class="px-2">
                                                                    <div class="icon-wrapper-24">
                                                                        <i class="icon-coverage" aria-hidden="true"></i>
                                                                    </div>
                                                                </b-col>
                                                                <b-col>
                                                                    <div class="address-item">{{Address.street}}  </div>
                                                                    <div class="address-item">nº {{Address.door}}   {{Address.floor}} {{$t('floor')}} {{Address.fraction}} </div>
                                                                    <div class="address-item">{{Address.cp4}}-{{Address.cp3}} {{Address.locality}}  </div>
                                                                </b-col>
                                                            </b-row>
                                                        </b-container>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                        <b-container v-else  fluid class="verify-coverage p-4 verify-coverage-desktop">
                                            <b-row no-gutters class="mb-3">
                                                <b-col cols="auto" class="col-icon pr-4">
                                                    <div class="icon-wrapper-30 primary">
                                                        <i class="icon-info"></i>
                                                    </div>
                                                </b-col>
                                                <b-col>
                                                    <div class="text-semibold-14">{{$t('purchase_step_1_page.message_choose_address')}}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters>
                                                <b-col>


                                                    <a class="button-alternative small" href="#" @click="GoToCoverage">

                                                        <div class="icon-wrapper-24 icon">
                                                            <i class="icon-coverage" aria-hidden="true"></i>
                                                        </div>
                                                        <span>{{$t('purchase_step_1_page.button_text_choose_address')}}</span>

                                                    </a>
                                                    <!--</router-link>-->
                                                </b-col>
                                            </b-row>
                                        </b-container>

                                    </b-col>
                                </b-row>
                                <!--  -->
                                <div class="border-top-gray-200 hide-in-desktop"></div>
                                <!--TODO include on next version, do not remove  -->
                                <!--<b-row no-gutters>
                                <b-col>
                                    <a href="#" class="default-link">
                                        <span>
                                            {{$t('purchase_step_1_page.general_sale_conditions')}}
                                        </span>
                                        <i class="icon-right" aria-hidden="true"></i>
                                    </a>
                                </b-col>
                            </b-row>-->
                                <b-row no-gutters class="checkbox-row">
                                    <b-col>
                                        <privacy-checkboxes v-if="PrivacyItems.length > 0" :items="PrivacyItems"></privacy-checkboxes>
                                        <b-overlay v-if="PrivacyItemsProcessing || $store.state.orders.getProductDetailProcessing" :show="PrivacyItemsProcessing || $store.state.orders.getProductDetailProcessing" spinner-variant="primary" class="py-5"></b-overlay>
                                    </b-col>

                                </b-row>

                                <b-row no-gutters class="p-4">
                                    <b-col>
                                   
                                        <button :disabled="!Address || invalid || PrivacyItems.length == 0" class="button-secondary small floor-light" @click="GoToStep">
                                            <span> {{$t('subscribe_offer')}}</span>
                                            <img class="ml-1" src="../../assets/images/go.svg" />
                                        </button>
                                    </b-col>
                                    <!--TODO uncomment next version-->
                                    <!--<b-col cols="auto" class="pl-3 hide-in-desktop">
                                    <button class="button-chat">
                                        <div class="icon-wrapper-24 icon">
                                            <i class="icon-chat"></i>
                                        </div>
                                    </button>
                                </b-col>-->
                                </b-row>
                            </ValidationObserver>
                        </b-container>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <!--{{$store.state.ui.redirectTo}}-->

        <!--<b-modal id="modal-contact-request" hide-footer no-close-on-backdrop size="xl">
            <template #modal-header>
            </template>
            <b-container fluid>
                <b-row no-gutters>
                    <b-col>
                        <request-contact-form @on-cancel-click="goToStore"
                                              @on-submit="onContactSubmit"
                                              context="order2">
                        </request-contact-form>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>-->

        <modal-simple data_id="modal_contact_request" :data_no_close_on_backdrop="true" :data_no_close_button="true">
            <b-container fluid>
                <b-row no-gutters>
                    <b-col>
                        <request-contact-form @on-cancel-click="goToStore"
                                              @on-submit="onContactSubmit"
                                              @on-error="onError"

                                              context="order1">
                        </request-contact-form>
                    </b-col>
                </b-row>
            </b-container>
        </modal-simple>

            <modal-simple data_id="modal_not_avaiable" :data_no_close_on_backdrop="true" :data_no_close_button="true">
                <b-container fluid class="p-0">
                    <b-row no-gutters class="mb-1">
                        <b-col>
                            <div class="text-bold-20"> {{error_text}}   </div>
                        </b-col>
                    </b-row>
                    <b-row no-gutters class="mb-4">
                        <b-col>
                            <div class="text-regular-12">{{$t('purchase_step_2_page.modal_not_avaiable.description')}}</div>
                        </b-col>
                    </b-row>
                    <b-row no-gutters class="mb-4">
                        <button class="button-alternative small floor-light" @click="ShowContactRequest">
                            <span>{{$t('purchase_step_2_page.modal_not_avaiable.button_text_request_contact')}}</span>
                        </button>
                    </b-row>
                    <b-row no-gutters>
                        <button class="button-secondary small floor-light" @click="goToStore">
                            <span>{{$t('purchase_step_2_page.modal_not_avaiable.button_text_go')}}</span>
                        </button>
                    </b-row>
                </b-container>
            </modal-simple>

            <!--{{Availability}}-->
    </div>
</template>
<script>

    import OrderCreate from '../../models/order_create';
    import PrivacyCheckboxes from '@/components/PrivacyCheckboxes.vue';

    import ModalSimple from '@/components/ModalSimple.vue'
 


    import AlertMessage from '../../models/alert-message';

    export default {
        name: 'purchase-step-1',
        props: {
            //Address: Object,
            //Product: Object
        },
        data() {
            return {
                id: this.$route.params.id,
                orderCreate: new OrderCreate(),
                CoverageTested: false,
                showRequestContact: false,
                agreeWithTerms: false,
              
            }
        },

        components: {
            ProductCardExtended: () => import('@/components/order/ProductCardExtended.vue'),
            PrivacyCheckboxes,
            ModalSimple,
            RequestContactForm: () => import('@/components/RequestContactForm.vue')
             
        },
        methods: {
            //  showContactRequest

            goToStore() {
                this.$router.push({ name: "store", params: { lang: this.$i18n.locale } });
            },


            ShowContactRequest() {
                this.$bvModal.hide('modal_not_avaiable');

                this.$bvModal.show('modal_contact_request');

              

             //   this.showRequestContactForm = true;
            },

            requestContact() {

                //  this.$emit('showContactRequest');
                this.$store.dispatch('ui/showContactRequest', true);

            },

            GetUrlFor(base) {

                return "/" + this.$i18n.locale + "/" + base;
            },

            GoToStep() {

                 

                this.$router.push({ name: "step2", params: { lang: this.$i18n.locale, id: this.id }, query: {} });



            

            },

            GoToCoverage() {

                this.$router.push({ name: "coverage", params: { lang: this.$i18n.locale }, query: { context: "product", productid: this.id } });
            },


            ValidateCoverage() {

                //console.log("ValidateCoverage");


                this.$store.dispatch('orders/getProductAvailability', {
                    productId: this.id,
                    addressid: this.AddressId,
                    sessionid: this.currentUser.sessionId
                }).then(
                    (response) => {


                        //console.log("getProductAvailability");
                        //console.log(response);


                        if (response.result &&   Number(response.result.resultcode) == 0) {


                         //   this.CreateOrderDraft();

                        } else if (response.message == "product_not_avaiable") { 

                        //    console.log("Not avaiable");
                            this.$bvModal.show("modal_not_avaiable");

                        }

                    }
                ).catch( () => {

                 //   console.log("Error");
                    this.$bvModal.show("modal_not_avaiable"); 

                });



            },

          


                 onError() {


             //   console.log(" On error ");
               // "O seu pedido foi enviado com sucesso."
                  //   this.$store.dispatch('alert/error', new AlertMessage(this.$t("error_generic"), this.$t("request_error_text")));
                     this.goToStore();
            },

            onContactSubmit() {
                this.$store.dispatch('alert/success', new AlertMessage(this.$t("request_success_title"), this.$t("request_success_text"))); 
              //  this.$router.push('/');
                this.goToStore();
            },



        },
        computed: {

            //Processing() {

            //    return this.$store.state.orders.processing;
            //},
            AddressId() {

                //if (this.$store.state.products.address && this.$store.state.orders.address.uaid  ) {
                //    return this.$store.state.orders.address.uaid;
                //} else {

                //    return "";
                //}

                if (this.$store.state.orders.address && this.$store.state.orders.address.uaid) {
                    return this.$store.state.orders.address.uaid;
                } else {

                    return "";
                }


            },
            Address() {
                return this.$store.state.orders.address;
            },
            ProductAttributes() {


                if (this.$store.state.orders.product) {
                    return this.$store.state.orders.product.groups;
                } else {

                    return {};
                }


            },
            IsAutenticated() {

                return this.$store.state.authentication.user != null;
            },

            currentUser() {

                //  return  this.$store.state.authentication.user;

                if (this.IsAutenticated) {

                    return JSON.parse(JSON.stringify(this.$store.state.authentication.user));
                } else {

                    return null;
                }
            },
            PrivacyItems() {
                if (this.$store.state.privacy.privacyItemsOrder.data && this.$store.state.privacy.privacyItemsOrder.data.PrivacyItem) {
                    return this.$store.state.privacy.privacyItemsOrder.data.PrivacyItem;
                }
                return [];
            },
            PrivacyItemsProcessing() {
                return this.$store.state.privacy.privacyItemsAccount.processing;
            },
            Availability() {
                return this.$store.state.orders.availability;
            },

        },
        watch: {
            IsAutenticated: function (newValue, oldValue) {

                console.log(  oldValue + " > " + newValue);

                if (newValue) {
                    this.$router.push({ name: "step2", params: { lang: this.$i18n.locale, id: this.id }, query: {} });
                }

            },
            
            Address: function (newValue, oldValue) {

           console.log(  oldValue + " > " + newValue);

                if (this.currentUser &&
                    this.currentUser.sessionId &&
                    this.AddressId    ) {
                    this.ValidateCoverage();
                }
             

            },


        },

        beforeMount() {
            this.$store.dispatch('privacy/getPrivacyItemsOrder', this.$i18n.locale);

        },
        mounted() {
            this.$store.dispatch('ui/setPurchaseActiveStep', 1, { root: true });

            //if (this.Address) {

            //    this.ValidateCoverage();
            //}

            this.$store.dispatch('order/resetState',  { root: true });


        },
        destroyed: function () {
            this.$store.dispatch('ui/setPurchaseActiveStep', null, { root: true });
        }
    }
</script>
