<template>
    <div :class="['input-multiple-block', has_clean ? 'has_clean': '', data_label == '' ? '' : 'has-label', hasErrors ? 'has-errors' : '']">
        <div class="text-semibold-12 mb-2 ml-2">{{data_label}}</div>
        <div class="radio" v-for="(option ,index) in data_options" :key="index">
            <span v-if="options_key_value">
                <input type="checkbox" :id="name + index" :name="name" :value="option[data_item_value]" v-model="local_value[index]" @change="selected()" />
                <label :for="name + index">{{option[data_item_text]}}</label>
            </span>
            <span v-else>
                <input type="checkbox" :id="name + index" :name="name" :value="option" v-model="local_value[index]" @change="selected()" />
                <label :for="name + index">{{option}}</label>
            </span>

        </div>
        <div v-if="hasErrors" class="error-message pl-2 mb-2">{{data_error}}</div>
        <button v-if="has_clean" @click="clean" class="button-simple-text clean-button">{{$t('clean')}}</button>
    </div>
</template>
<script>
    export default {
        props: {
            data_options: Array,
            data_name: String,
            data_error: String,
            data_label: {
                type: String,
                default: ""
            },
            value: {
                type: Array,
                default: function () {
                    return []
                }
            },
            options_key_value: {
                type: Boolean,
                default: false
            },
            data_item_value: {
                type: String,
                default: ""
            },
            data_item_text: {
                type: String,
                default: ""
            },
            data_has_clean: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                name: this.data_name,
                local_value: this.getModelForOption(),
                has_clean: this.data_has_clean,
            }
        },
        computed: {
            hasErrors: function () {
                return this.data_error != null && this.data_error.length > 0;
            }
        },
        methods: {
            selected() {

                var result = [];

                for (var i = 0; i < this.local_value.length; i++) {
                    if (this.local_value[i]) {
                        //even when the option_key_value its true;
                        var valueToPush = this.data_options[i];

                        result.push(valueToPush)
                    }
                }
                this.$emit('input', result);

            },
            clean() {
                for (var i = 0; i < this.local_value.length; i++) {
                    this.$set(this.local_value, i, false);
                }
                this.selected();
            },
            getOptionName(option) {
                if (this.data_item_text) {
                    return option[this.data_item_text];
                }
            },
            getModelForOption() {
                var result = [];
                for (var i = 0; i < this.data_options.length; i++) {

                  var valToCompare = this.data_options[i];

                    if (this.options_key_value) {
                        valToCompare = this.data_options[i][this.data_item_value];
                    }

                    if (this.value.filter(x => x == valToCompare).length > 0) {
                        result.push(true);
                    } else {
                        result.push(false);
                    }
                }
                return result;
            }
        },
        watch: {
            value() {
                this.local_value = this.getModelForOption();
            }

        },

    }
</script>