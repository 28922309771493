export default class ListParams {
   
    constructor(id,userid,sessionid,page) {
        this.id = id,
        this.userid = userid,
        this.sessionid = sessionid,
        this.page = page;
        this.filter = "";
    }

}
