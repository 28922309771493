<template>
    <div>
        <b-row no-gutters class="hide-in-desktop border-top-gray-200">
            <b-col cols="12">
                <b-card no-body class="collapse-filter">
                    <b-card-header header-tag="header" class="header-collapse p-0" role="tab">
                        <button block v-b-toggle.detailsOffer>
                            <span>{{$t('offer_details')}}</span>
                            <div class="icon-wrapper-24 icon">
                                <i class="icon-down-18" aria-hidden="true"></i>
                            </div>
                        </button>
                    </b-card-header>
                    <div>
                        <b-collapse id="detailsOffer" accordion="my-accordion" role="tabpanel">
                            <b-card-body class="p-0">
                                <b-container fluid class="p-0">
                                    <b-row no-gutters>
                                        <b-col>
                                            <div class="offer-card-details" style="height: 100%">
                                                <b-container fluid class="p-0">
                                                    <b-row no-gutters class="mb-1">
                                                        <b-col cols="8">
                                                            <div class="title mb-2">{{$store.getters['orders/ProductName']}}</div>
                                                            <div class="details">{{GetFieldByName('Description', 'definition')}}</div>
                                                        </b-col>
                                                        <b-col cols="4" class="column-logo" align-self="center">
                                                            <broker-image v-if="LogoFileId" :id="LogoFileId" height="70px"></broker-image>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row no-gutters>
                                                        <b-col cols="6" align-self="center">
                                                            <div class="pricing"> <span>{{$store.getters['orders/ProductPrice']}}</span><span>{{GetFieldByName('Recurrence', 'definition')}}</span></div>
                                                        </b-col>
                                                    </b-row>
                                                </b-container>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-card-body>
                        </b-collapse>
                    </div>
                </b-card>
            </b-col>
        </b-row>
        <!--End Mobile-->
        <!--Desktop-->
        <b-row no-gutters class="border-bottom-gray-200 hide-in-mobile">
            <b-col>
                <div class="offer-card-details" style="height: 100%">
                    <b-container fluid class="p-0">
                        <b-row no-gutters class="mb-1">
                            <b-col cols="8">
                                <div class="title mb-2">{{$store.getters['orders/ProductName']}}</div>
                                <div class="details">{{GetFieldByName('Description', 'definition')}}</div>
                            </b-col>
                            <b-col cols="4" class="column-logo" align-self="center">
                                <broker-image v-if="LogoFileId" :id="LogoFileId" height="70px"></broker-image>
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col cols="6" align-self="center">
                                <div class="pricing"> <span>{{$store.getters['orders/ProductPrice']}}</span><span>{{GetFieldByName('Recurrence', 'definition')}}</span></div>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
            </b-col>
        </b-row>
        <!--End Desktop-->
    </div>
</template>
<script>
    import BrokerImage from '@/components/BrokerImage.vue';


    export default {
        props: {
          
        },
        components: {
            BrokerImage
        },
        data() {
            return {
           
            }
        },
        methods: {
            GetFieldByName(name, groupName) {
                if (this.Product && this.Product.groups) {
                    var attributes = this.Product.groups.find(e => e.name.toLowerCase() === groupName.toLowerCase()).attributes.find(e1 => e1.name.toLowerCase() == name.toLowerCase()).value;
                    return attributes;
                }
                return "";

            },

        },
        computed: {
            Product() {
                return this.$store.state.orders.product;
            },
            LogoFileId() {
                if (this.Product) {
                    return this.Product.cmop_fileid;
                }
                return "";
            }
        },
        watch: {
           
        }
    }
</script>