import { brokerService } from '../services';
var geojsonEmpty = {
    "type": "FeatureCollection",
    "features": []
}

import axios from 'axios';


export const coverage = {
    namespaced: true,
    state: {
        operation: { processing: false, success: false, message: "" },
        geojsonData: geojsonEmpty,
        pointCoverageInfo: 0,
        lastGetCoveragePromise: null,
        shouldSetLocation: true,
        addresses: [],
        cancelTokens:[],
        markerCoordinates: [],
        getCoverageError: false,
        getAddressError: false,
    },

    actions: {
        getPointCoverageInfo({ commit }, param) {

            commit('getPointCoverageInfoRequest');

            return new  Promise( (resolve,reject) => {
                brokerService.getPointCoverageInfo(param)
                    .then(({ data }) => {
                        commit('getPointCoverageInfoSuccess', data);
                        resolve(data);
                    })
                    .catch(error => {
                        commit('getPointCoverageInfoFailure', error);
                        reject(error)

                    });
            });
        },
        getCoverage({ commit, state }, param) {
            commit('getCoverageRequest');

            const promise = new  Promise( (resolve,reject) => {
                brokerService.getCoverage(param.boundaries, param.zoom, param.cancelToken)
                    .then(({ data }) => {
                        if (promise == state.lastGetCoveragePromise) {
                            commit('setLastGetCoveragePromise', null);
                            commit('getCoverageSuccess', data);
                            resolve(data);
                        } 
                    })
                    .catch(error => {
                        if (!axios.isCancel(error)) {
                            commit('getCoverageFailure', error);
                            reject(error)
                        }
                    });
            });
            commit('setLastGetCoveragePromise', promise);

            return promise;
        },
        getAddress({ commit }, param) {

            commit('getAddressRequest');

          //  console.log("getAddressRequest");

            return new  Promise( (resolve,reject) => {
                brokerService.getAddress(param)
                    .then(({ data }) => {
 

                        commit('getAddressSuccess', data);
                        resolve(data);

                    })
                    .catch(error  => {

                        commit('getAddressFailure', error);
                        reject(error)
                    });
            });
        },
        setShouldSetLocation({ commit }, param) {
            commit('setShouldSetLocation', param);
        },
        setMarkerCoordinates({ commit }, param) {
            commit('setMarkerCoordinates', param);
        }
    },
    getters: {

    },
    mutations: {
        getPointCoverageInfoRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
        },
        getPointCoverageInfoSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.pointCoverageInfo = data;
        },
        getPointCoverageInfoFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.data };
            state.pointCoverageInfo = 0;
        },

        getCoverageRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.geojsonData = geojsonEmpty;
        },
        getCoverageSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.geojsonData = JSON.parse(JSON.stringify(data));
            state.getCoverageError = false;
        },
        getCoverageFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.data };
            state.geojsonData = geojsonEmpty;
            state.getCoverageError = true;
        },

        getAddressRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.addresses = [];
        },
        getAddressSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.addresses = data.data;
            state.getAddressError = false;
            //  console.log("getAddressRequest commit");
            //console.log(data.data);

        },
        getAddressFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.data };
            state.addresses = [];
            state.getAddressError = true;
        },


        setLastGetCoveragePromise(state, data) {
            state.lastGetCoveragePromise = data;
        },
        setShouldSetLocation(state, data) {
            state.shouldSetLocation = data;
        },
        setMarkerCoordinates(state, data) {
            state.markerCoordinates = data;
        }
    }
}
