import { apiService } from '../services';
//import { brokerService } from '../services';
import i18n from '../i18n'


export const backoffice = {
    namespaced: true,
    state: {
       operation : { processing: true, success: false, message: "", res: "" },
        users: [],
        usersPages: 0
    },
    actions: {         
        getUsers({ commit }, search) {
            commit('getUsersRequest');
            return new Promise(resolve => {
                apiService.getUsers(search)
                    .then(({ data }) => {

                        //console.log("--User response --");
                        //console.log(data);

                        commit('getUsersSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        commit('getUsersFailure', response);
                    });
            });

        },
        updateRole({ commit, dispatch }, params) {

            commit('updateRoleRequest');

            return new Promise(resolve => {
                apiService.updateRole(params.userid,params.role)
                    .then(({ data }) => {

                     
                        commit('updateRoleSuccess', data);

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.role_updated') }, { root: true });

                        resolve(data);

                    })
                    .catch(({ response }) => {

                        dispatch('alert/error', { title: "", description: i18n.t('error.update_role') }, { root: true });
                        commit('updateRoleFailure', response);
                    });
            });

        },





    },
    getters: { 
    },
    mutations: {


        getUsersRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.users = [];
            state.usersPages = 0;
        },
        getUsersSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            state.users = data.result;
            state.usersPages = data.pages;

        },
        getUsersFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.users = [];
            state.usersPages = 0;
        },

        /**
         * 
         * 
         */

         updateRoleRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
         
        },
        updateRoleSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: data };
           

        },
        updateRoleFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            
        }


    }
}
