<template>
    <div class="profile-requests">
        <b-container class="px-0 pt-4">
            <b-row no-gutters class="mb-2">
                <b-col class="hide-in-desktop"></b-col>
                <b-col cols="auto"><div class="text-bold-20">{{$t('profile_page.historic.title')}}</div></b-col>
                <b-col></b-col>
            </b-row>
            <b-row no-gutters class="mb-4">
                <b-col class="hide-in-desktop"></b-col>
                <b-col cols="auto"><div class="description description-profile">{{$t('profile_page.historic.description')}}</div></b-col>
                <b-col></b-col>
            </b-row>
        
            <b-row no-gutters>
                <b-col>
                    <div class="profile-block">
                        <div class="header bg-secondary">
                            <b-container class="py-2 px-4">
                                <b-row>
                                    <b-col>
                                        <div class="title text-semibold-14 mt-1">
                                            {{$t('profile_page.historic.title_block')}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                        <div class="body">
                            <div v-if="!Processing">
                                <div v-if="hasEvents">
                                    <b-container class="p-0">
                                        <b-row v-for="h in  History.events" :key="h.event_id" no-gutters class="border-bottom-gray-200">
                                            <b-col>
                                                <b-container class="p-4">
                                                    <b-row no-gutters>
                                                        <b-col>
                                                            <b-container class="p-0">
                                                                <b-row no-gutters>
                                                                    <b-col>
                                                                        <div class="text-semibold-12">
                                                                            <p v-html="$formatRelative(FixDate(h.event_date))"></p>
                                                                        </div>
                                                                        <div class="text-regular-14">
                                                                            {{h.event_name}}
                                                                        </div>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-container>
                                                        </b-col>
                                                    </b-row>
                                                </b-container>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                    <b-container class="p-4">
                                        <b-row>
                                            <b-col></b-col>
                                            <b-col cols="auto">
                                                <pager-default :data_page_count="TotalPages" :data_page="list_params.page" @on-page-change="pageChange"></pager-default>
                                            </b-col>
                                            <b-col></b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                                <b-container v-else class="p-4">
                                    <b-row no-gutters>
                                        <b-col class="hide-in-mobile"></b-col>
                                        <b-col md="6" sm="12">


                                            <span v-if="this.$store.state.profile.get_account_history_error == false">
                                                <no-content :text="$t('profile_page.requests.no_content')"></no-content>
                                            </span>
                                            <span v-if="this.$store.state.profile.get_account_history_error == true">
                                                <no-content :text="$t('error.error_get_list')"></no-content>
                                            </span>

                                        </b-col>
                                        <b-col class="hide-in-mobile"></b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <div v-else>
                                <b-overlay :show="Processing" spinner-variant="primary" style="height: 200px;"></b-overlay>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>

    

    </div>
</template>
<script>
    //import DropdownDefault from '@/components/DropdownDefault.vue'
    import NoContent from '@/components/NoContent.vue';
    import Config from '../../helpers/config';
    import PagerDefault from '@/components/PagerDefault.vue';
    import ListParams from '../../models/list_params';


    export default {
        components: {
            //DropdownDefault
            NoContent,
            PagerDefault
        },
        data() {
            return {
             //  currentpage: 1,
                list_params: new ListParams("",this.$store.state.authentication.user.id, this.$store.state.authentication.user.sessionId, 1),
            };
        },
        methods: {


            FixDate(date) {

                return date.replace(/-/g, '/');

            },

            selectedFilter(val) {
                this.filterSelected = val.id
            },
            pageChange(event) {

                //var context = this;

            //    this.currentpage = event;

                this.list_params.page = event;
                
                this.$store.dispatch('profile/getAccountHistory',
                    //{
                    //    id: this.User.id,
                    //    session: this.$store.state.authentication.user.sessionId,
                    //    page: event
                    //},
                    this.list_params,

                    { root: true }
                ).then(
                    () => {

                      //  context.currentpage = event;


                    }




                );



            },

        },
        computed: {
            History() {


                return this.$store.state.profile.accounthistory
            },
            Processing() {
                return this.$store.state.profile.operation.processing;
            },
            hasEvents() {
                //if (this.History != null && (this.History.orders != null && this.History.events != 'undefined') && this.History.events.length > 0) {
                //    return true;
                //}
                return this.History.events && this.History.events.length > 0;
            },
            User() {
                return this.$store.state.authentication.user;
            },
            TotalPages() {

                return Math.ceil(Number(this.History.nresults) / Number(Config.NUMBER_ITEMS_PAGE));
            }

        },
        mounted() {
            var breadcrumbitems = [
                { text: this.$i18n.t('profile_page.my_account'), url: '/' + this.$i18n.locale + '/profile', urlMobile: '/' + this.$i18n.locale + '/profile/profile-menu' },
                { text: this.$i18n.t('profile_page.historic.title'), url: '' }
            ]
            this.$store.dispatch('ui/setBreadcrumbItems', breadcrumbitems, { root: true });

            this.$store.dispatch('profile/getAccountHistory',
                //{ id: this.User.id, session: this.$store.state.authentication.user.sessionId, page: this.currentpage },
                this.list_params,
                { root: true }
            );
        },
        destroyed: function () {
            this.$store.dispatch('ui/setBreadcrumbItems', [], { root: true });
        }
    };
</script>














