<template>
    <div class="purchase-step-3 purchase-page">
        <b-container v-if="!showRequestContactForm" fluid class="p-0 content">
            <!--Details Mobile-->
            <div class="hide-in-desktop">
                <product-card-small></product-card-small>
            </div>

            <!-- End Details Mobile-->
            <!--Addrress-->
            <b-row no-gutters>
                <b-col md="7" sm="12" class="column-left border-right-gray-200 p-4">
                    <div class="text-bold-20 mb-3">{{$t('purchase_step_2_page.column_left_title')}}</div>
                    <div class="description mb-4">{{$t('purchase_step_2_page.column_left_description')}}</div>
                    <!--<address-compact class="mb-4"
                :postal_code="$store.state.orders.address.cp4 + '-' + $store.state.orders.address.cp3"
                :street="$store.state.orders.address.street"
                :door="$store.state.orders.address.door"
                :floor="$store.state.orders.address.floor"
                :fraction="$store.state.orders.address.fraction"></address-compact>-->

                    <address-compact class="mb-4"
                                     :postal_code="PostalCode"
                                     :street="Street"
                                     :door="Door"
                                     :floor="Floor"
                                     :fraction="Fraction">
                    </address-compact>


                    <b-container fluid class="p-0">
                        <b-row no-gutters>
                            <b-col cols="auto">
                                <div class="icon-wrapper-40 primary">
                                    <i class="icon-date" aria-hidden="true"></i>
                                </div>
                            </b-col>
                            <b-col class="pl-3">
                                <b-overlay v-if="$store.state.orders.getProductAvailabilityProcessing"
                                           :show="$store.state.orders.getProductAvailabilityProcessing"
                                           spinner-small
                                           style="height: 40px;"
                                           spinner-variant="primary">
                                </b-overlay>
                                <div v-if="!$store.state.orders.getProductAvailabilityProcessing" class="description">
                                    <span v-if="$store.state.orders.availability &&  $store.state.orders.availability.delivery">
                                        {{$t('purchase_step_2_page.time_left_message', {0:getFormatDeliveryDays(), 1:24})}}
                                    </span>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>

                <!--End Addrress-->
                <!--Details Destop-->

                <b-col md="5" sm="12">
                    <b-container fluid class="p-0 ">
                        <b-row no-gutters class="border-bottom-gray-200 hide-in-mobile">
                            <b-col class="pt-0 p-4">
                                <div class="text-bold-20">{{$t('purchase_step_2_page.column_right_title')}}</div>
                                <div class="description">{{$t('purchase_step_2_page.column_right_description')}}</div>
                            </b-col>
                        </b-row>


                        <div class="hide-in-mobile">
                            <product-card-small></product-card-small>
                        </div>



                        <b-row no-gutters class="p-4 row-buttons">
                            <b-col>
                                <button-component :text="$t('next')"
                                                  :disabled="!Availability.addrproductid  || !OrderId  "
                                                  data_class="button-secondary small floor-light"
                                                  :processing="$store.state.orders.createOrderDraftProcessing"
                                                  :has-go="true"
                                                  @on-click="goToNextStep"
                                                  type="button">
                                </button-component>

                            </b-col>
                            <b-col cols="auto" class="pl-3 hide-in-desktop">
                                <button class="button-chat">
                                    <div class="icon-wrapper-24 icon">
                                        <i class="icon-chat"></i>
                                    </div>
                                </button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-else class="p-0 content">
            <div class="hide-in-desktop">
                <b-row no-gutters>
                    <b-col class="p-4 pl-md-4 p-md-0">
                        <div class="text-bold-20">{{$t('purchase_step_2_page.request_contact.column_right_title')}}</div>
                        <div class="text-regular-12 mb-4">{{$t('purchase_step_2_page.request_contact.column_right_description')}}</div>
                        <address-compact :postal_code='$store.state.orders.address.cp4'
                                         :street="$store.state.orders.address.street"
                                         :door="$store.state.orders.address.door"></address-compact>
                    </b-col>
                </b-row>
            </div>
            <b-row no-gutters class="mb-4">
                <b-col md="7" sm="12" class="column-left border-right-gray-200">
                    <b-container fluid class="px-4">

                        <div class="text-bold-20">{{$t('purchase_step_2_page.request_contact.column_left_title')}}</div>
                        <div class="text-regular-12 mb-4">{{$t('purchase_step_2_page.request_contact.column_left_description')}}</div>
                        <request-contact-form @on-cancel-click="goToStore"
                                              @on-submit="goToHome"
                                              context="order3">
                        </request-contact-form>
                    </b-container>
                </b-col>
                <b-col md="5" sm="12" class="hide-in-mobile">
                    <b-row no-gutters>
                        <b-col class="p-4 pl-md-4 p-md-0">
                            <div class="text-bold-20">{{$t('purchase_step_2_page.request_contact.column_right_title')}}</div>
                            <div class="text-regular-12 mb-4">{{$t('purchase_step_2_page.request_contact.column_right_description')}}</div>
                            <address-compact :postal_code='$store.state.orders.address.cp4'
                                             :street="$store.state.orders.address.street"
                                             :door="$store.state.orders.address.door"></address-compact>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>

        <!--<modal-simple data_id="modal_not_avaiable" :data_no_close_on_backdrop="true" :data_no_close_button="true">
        <b-container fluid class="p-0">
            <b-row no-gutters class="mb-1">
                <b-col>
                    <div class="text-bold-20"> {{error_text}}   </div>
                </b-col>
            </b-row>
            <b-row no-gutters class="mb-4">
                <b-col>
                    <div class="text-regular-12">{{$t('purchase_step_2_page.modal_not_avaiable.description')}}</div>
                </b-col>
            </b-row>
            <b-row no-gutters class="mb-4">
                <button class="button-alternative small floor-light" @click="ShowContactRequest">
                    <span>{{$t('purchase_step_2_page.modal_not_avaiable.button_text_request_contact')}}</span>
                </button>
            </b-row>
            <b-row no-gutters>
                <button class="button-secondary small floor-light" @click="goToStore">
                    <span>{{$t('purchase_step_2_page.modal_not_avaiable.button_text_go')}}</span>
                </button>
            </b-row>
        </b-container>
    </modal-simple>-->
        <!--<b-modal id="modal-contact-request"  hide-footer no-close-on-backdrop size="xl">
        <template #modal-header>
        </template>
        <b-container fluid>
            <b-row no-gutters>
                <b-col>
                    <request-contact-form @on-cancel-click="goToStore"
                                          @on-submit="onContactSubmit"
                                          context="order2">
                    </request-contact-form>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>-->
        <!--{{Availability}}-->
        <!--{{$store.state.profile.orderdetail}}-->

     

        <!--{{OrderId}}
        <br />
        {{ $store.state.orders.availability}}
        <br />
        {{$store.state.orders}}-->

    </div>
</template>
<script>


    import ProductCardSmall from '@/components/order/ProductCardSmall.vue'
  //  import ModalSimple from '@/components/ModalSimple.vue'
    import ButtonComponent from '@/components/ButtonComponent.vue'
    import Config from '../../helpers/config';

    import DetailsParams from '../../models/details_params';

    // import OrderSale from '../../models/order_sale';

    export default {
        name: 'purchase-step-2',
        props: {

        },

        data() {
            return {
                id: this.$route.params.id,
                showRequestContactForm: false,
                error_text: ""
            }
        },
        components: {
            ProductCardSmall,
        /*    ModalSimple,*/
            ButtonComponent,
         /*   RequestContactForm: () => import('@/components/RequestContactForm.vue'),*/
            AddressCompact: () => import('@/components/order/AddressCompact.vue')
        },
        methods: {
            onContactSubmit() {



                this.showRequestContactForm = false;
                this.goToStore();

            },
            goToStore() {
                this.$router.push({ name: "store", params: { lang: this.$i18n.locale } });
            },
            goToNextStep() {
               

                           this.$router.push(
                            {
                                name: "step3",
                                params:
                                {
                                    lang: this.$i18n.locale,
                                    id: this.id
                                },
                                query: {
                                    orderid: this.OrderId,
                                    addrproductid: this.Availability.addrproductid
                                }
                            });


            },
            ShowContactRequest() {
                this.$bvModal.hide('modal_not_avaiable');
                this.showRequestContactForm = true;
            },
           
      
            getFormatDeliveryDays() {
                var result = "";
                if (this.$store.state.orders.availability.delivery) {
                    var delivery = this.$store.state.orders.availability.delivery;
                    var format = null;
                    var number = null;
                    if (delivery.substr(delivery.length - 2) == Config.TIME_FORMAT_BUSINESS_DAYS) {
                        format = Config.TIME_FORMAT_BUSINESS_DAYS;
                        number = delivery.substr(0, delivery.length - 2)
                    }
                    else if (delivery.substr(delivery.length - 1) == Config.TIME_FORMAT_DAYS) {
                        format = Config.TIME_FORMAT_DAYS;
                        number = delivery.substr(0, delivery.length - 1)
                    }
                    if (format != null && number != null) {
                        result = number + " " + this.$i18n.t('time_format.' + format);
                    }
                }

                return result;
            } ,


            
            ValidateCoverage() {

              //  console.log("ValidateCoverage");

 
          


                this.$store.dispatch('orders/getProductAvailability', {
                    productId: this.id,
                    addressid: this.AddressId,
                    sessionid: this.currentUser.sessionId
                }).then(
                    () => {

                     //   console.log("getProductAvailability --");
                    //    console.log(response);

                         

                     //   if (response.result && Number(response.result.resultcode) == 0) {


                      //  if (response.result && Number(response.result.resultcode) == 0 ){

                     //   if (this.$store.state.orders.availability && this.$store.state.orders.availability.addrproductid  ) {

                       // console.log(this.$store.state.orders.availability);

                        if (   (JSON.stringify(this.$store.state.orders.availability) != JSON.stringify({}))  ) {

                           // console.log("Produt avaiable");


                            if (JSON.stringify(this.OrderId) === JSON.stringify({})) {                      

                             //   console.log("CreateOrderDraft");

                                this.CreateOrderDraft();
                            }
                            //else {
                            //    console.log("Order already created");
                            //}


                        }

                        //else //if (response.message == "product_not_avaiable")
                        //{
                        

                        //    console.log("Not avaiable");
                        //    //this.error_text = this.$t('purchase_step_2_page.could_not_validate');
                        //    //this.$bvModal.show("modal_not_avaiable");

                        //}




                    }
                ).catch(() => {

                    console.log("Error");
                    //this.error_text = this.$t('purchase_step_2_page.could_not_validate');
                    //this.$bvModal.show("modal_not_avaiable");

                
          
                });



            },


            CreateOrderDraft() {

              
              

                this.$store.dispatch('orders/createOrderDraft',
                    {
                        sessionid: this.currentUser.sessionId,
                        accountid: this.$store.state.authentication.user.id,
                        addressid: this.AddressId,
                        addrproductid: this.Availability.addrproductid
                    }
                ).then(
                    () => {


                      //  this.GoToStep();
                      //  this.goToNextStep();

                    }
                );


            },




        },
        computed: {

            OrderId() {


                if (this.$route.query && this.$route.query.orderid) {

                    return this.$route.query.orderid;
                } else    {

                    return this.$store.state.orders.orderid;
                }

            },                    

            PostalCode() {

                if (this.$store.state.orders.address) {
                    return this.$store.state.orders.address.cp4 + '-' + this.$store.state.orders.address.cp3;
                }
                else return this.$store.state.profile.orderdetail.zipcode;

            },
            Street() {
                if (this.$store.state.orders.address) {
                    return this.$store.state.orders.address.street
                          }
                else return this.$store.state.profile.orderdetail.street;
            },
            Door() {
                if (this.$store.state.orders.address) {
                    return this.$store.state.orders.address.door 
                    }
                else return  this.$store.state.profile.orderdetail.door;
            },
            Floor() {
                if (this.$store.state.orders.address) {
                    return this.$store.state.orders.address.floor
                            }
                else return this.$store.state.profile.orderdetail.floor;
            },
            Fraction() {
                if (this.$store.state.orders.address) {
                    return this.$store.state.orders.address.fraction
                }
                else return this.$store.state.profile.orderdetail.fraction;
            },
            AddressId() {
              

                if (this.$route.query &&  this.$route.query.addressid && this.$route.query.addressid   != -1 ) {

                    return this.$route.query.addressid ;

                }
                else if (this.$store.state.products  != null && this.$store.state.products.address != null) {

                    return this.$store.state.orders.address.uaid;
                }
                else {

                    return "";
                }

            },

            Availability() {
                return this.$store.state.orders.availability;
            },

            Address() {
                return this.$store.state.orders.address;
            },

            IsAutenticated() {

                return this.$store.state.authentication.user != null;
            },
            currentUser() {

                if (this.IsAutenticated) {
                    return JSON.parse(JSON.stringify(this.$store.state.authentication.user));
                } else {
                    return null;
                }


            },
            ProductAttributes() {

                if (this.$store.state.orders.product) {
                    return this.$store.state.orders.product.groups;
                } else {

                    return {};
                }
            },


        },
        mounted() {

            this.$store.dispatch('ui/setPurchaseActiveStep', 2, { root: true });


            //if (JSON.stringify(this.Availability) === JSON.stringify({})) {


                if (this.$route.query && this.$route.query.orderid) {

                   // console.log("Resume");

                    var detailParam = new DetailsParams(
                        this.$route.query.orderid,
                        this.$store.state.authentication.user.id,
                        this.$store.state.authentication.user.sessionId
                    );


                    this.$store.dispatch('profile/getRequestDetails',
                        detailParam,
                        { root: true }
                    ).then(
                        () => {

                            this.ValidateCoverage();

                        }
                    );



                } else {

                 //   console.log("New Step");

                    this.$store.dispatch('orders/loadAddress', { root: true })
                        .then(
                            () => {

                                this.ValidateCoverage();
                            }
                        );

                }
         //   }  
            

        },
        destroyed: function () {
            this.$store.dispatch('ui/setPurchaseActiveStep', null, { root: true });
        }
    }
</script>