<template>
    <div class="faqs">

        <b-container>

            <b-row>
                <b-container>
                    <b-row no-gutters class="mt-2 mb-4">
                        <b-col cols="auto"><div class="big-text">{{$t('faqs')}}</div></b-col>
                    </b-row>
                </b-container>
            </b-row>


            <b-row class="mb-4 mt-3" style="justify-content:flex-end;">
                <b-col cols="4">
                    <router-link :to="`/${$i18n.locale}/backoffice/faqs/New`">
                        <button type="button" class="button-alternative">
                            <span>{{$t('backoffice_faqs.new_faq')}}</span>
                        </button>
                    </router-link>
                </b-col>
                <b-col></b-col>
                
                
                <b-col cols="6" style="display:flex; justify-content:flex-end;">
                    <span class="filter-text">{{$t('filter_language')}}</span>
                    <div class="d-inline-block right" style="width: 140px;">
                        <dropdown-default class="pt-1"
                                          :data_options="langs"
                                          data_parent_dropdown="dropdownBackofficeFaqsListLang"
                                          :data_selected="localeFilter"
                                          @input="changeLocale"
                                          :data_settings="{
                                                 minimumResultsForSearch: -1 ,
                                                 theme: 'filter',
                                                placeholder: 'idioma',
                                                dropdownParent: '#dropdownBackofficeFaqsListLang'
                                                 }">
                        </dropdown-default>
                    </div>
                </b-col>
            </b-row>


            <b-row v-if="faqsList.length == 0">
                <b-col style="text-align:center;">
                    {{$t('no_content_found')}}
                </b-col>
            </b-row>

            <b-row no-gutters class="mb-4" v-if="faqsList.length > 0">
                <b-container>
                    <b-row>
                        <table class="faqs-table">
                            <tr class="table-title">
                                <!--<th>Id</th>-->
                                <th>{{$t('backoffice_faqs.question')}}</th>
                                <th style="padding: 10px 15px;">{{$t('backoffice_news.language')}}</th>
                                <th></th>
                            </tr>
                            <tr v-for="(item,index) in faqsList" :key="index">
                                <!--<td>
                                    {{item.id}}
                                </td>-->
                                <td>
                                    <!--<router-link :to="`/${$i18n.locale}/backoffice/faqs/details/${item.id}`">-->
                                    {{item.question}}
                                    <!--</router-link>-->
                                </td>
                                <td class="table-cell">{{item.locale}}</td>
                                <td>
                                    <b-row>
                                        <b-col cols="auto">
                                            <router-link :to="`/${$i18n.locale}/backoffice/faqs/Edit/${item.id}`">
                                                <!--<button class="button-transparent">-->
                                                <span>{{$t('edit')}}</span>
                                                <!--</button>-->
                                            </router-link>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="auto">
                                            <router-link :to="item" class="item-entry red">
                                                <!--<button class="button-transparent">-->
                                                <span v-on:click="onDelete(`${item.id}`)">{{$t('delete')}}</span>
                                                <!--</button>-->
                                            </router-link>
                                        </b-col>
                                    </b-row>
                                </td>
                            </tr>
                        </table>

                    </b-row>
                </b-container>
            </b-row>


            <b-container class="p-4">
                <b-row no-gutter>
                    <b-col></b-col>
                    <b-col cols="auto">
                        <pager-default :data_page="currentPage" :data_page_count="totalPages" @on-page-change="changePage"></pager-default>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </b-container>

        </b-container>
</div>
</template>

<script>

    import PagerDefault from '@/components/PagerDefault.vue';
    import DropdownDefault from '@/components/DropdownDefault.vue';

    export default {
        name: 'FaqsList',
        components: {
            PagerDefault,
            DropdownDefault
        },
        data() {
            return {
                currentPage: 1,
                localeFilter: '',
                langs: this.pushingLangs(),
            };
        },
        methods: {
            onDelete(id) {
                if (confirm(this.$i18n.t('delete_message'))) {

                    this.$store.dispatch('faqs/deleteFaqs',  id ).then(
                        () => {

                            //console.log(response);
                            //location.reload();
                            this.$store.dispatch('faqs/getFaqsList', { lang: this.localeFilter, page: this.currentPage});
                        },
                        error => {
                            this.message = (error.response && error.response.data) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
             changePage(page) {

               this.$store.dispatch('faqs/getFaqsList', { lang : this.localeFilter , page:page  }  );

            },
             filterList() {
               //  console.log("locale filter " + this.localeFilter);

                 this.$store.dispatch('faqs/getFaqsList', { lang: this.localeFilter, page: 1 });
                 
            },
               pushingLangs() {
                   var list = []
                   
                   this.$i18n.availableLocales.forEach((value) => {
                        list.push({ id: value, name: value, text: value });
                   });

                   
                return list;
            },
            changeLocale(val) {
                this.localeFilter = val.text;
                this.filterList();
            }

        },
        created() {

            this.$store.dispatch('faqs/getFaqsList', { lang: "", page: 1 });
            
        },
        computed: {
             faqsList() {
                return this.$store.state.faqs.faqsList;
            },
            totalPages() {
                return this.$store.state.faqs.totalPages;
            },

        },
        mounted() {
        }
    };
</script>
