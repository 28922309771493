<template>
    <div>
        <h2>  List</h2>
        <b-container >
            <table   align="center" class="table table-striped" style="text-align: center;">
                <thead>
                    <tr>
                        <td>Nome</td>
                        <td>Email</td>
                        <td>Role</td>
                        <td>Verificado</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="u in Users" :key="u.id" style="text-align: center;">
                        <td>    {{ u.fullname}}   </td>
                        <td>    {{ u.email}} </td>
                        <td>    {{ u.role}} </td>
                        <td> {{u.isVerified}}  </td>
                        <td>
                            <router-link :to="`/${$i18n.locale}/backoffice/users/edit/${u.id}`">
                                Edit
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            </b-container>

            <!--<b-row  style="text-align: center;">
            <b-col> Nome  </b-col>
            <b-col>   Email </b-col>
            <b-col>  Role </b-col>
            <b-col>
               Verificado
            </b-col>
        </b-row>
        <b-row v-for="u in Users" :key="u.id" no-gutters class="flex-grow-1" style="text-align: center;">
            <b-col>    {{ u.fullname}}</b-col>
            <b-col>    {{ u.email}} </b-col>
            <b-col>    {{ u.role}} </b-col>
            <b-col> {{u.isverified}}  </b-col>
            <b-col>
                <router-link :to="`/${$i18n.locale}/backoffice/users/edit/${u.id}`">
                    Edit
                </router-link>
            </b-col>
        </b-row>-->
     
    <b-container class="p-4">
        <b-row no-gutter>
            <b-col></b-col>
            <b-col cols="auto">
                <pager-default :data_page="1" :data_page_count="Pages" @on-page-change="pageChange"></pager-default>
            </b-col>
            <b-col></b-col>
        </b-row>
    </b-container>

        
          
    </div>
</template>

<script>

import PagerDefault from '@/components/PagerDefault.vue'
import UserFilter from '@/models/user_filter';

    export default {
        name: 'List',
        data() {
            return {
               filter : new UserFilter(),
            };
        },
           computed: {

            Users() {

              return this.$store.state.backoffice.users;
                     
               },
                Pages() {

              return  Number( this.$store.state.backoffice.usersPages);
                     
            },
         
        },
        methods: {

                pageChange: function (event) {


              //  console.log(event);

                this.filter.page = event;

             //   console.log("pageChange");

               this.$store.dispatch('backoffice/getUsers',this.filter);

            },

        },
        created() {
        },
        components: { 

            PagerDefault
        },
         mounted() {
             this.$store.dispatch('backoffice/getUsers', this.filter).then(
                 () => {


                 }
             );
         }
    };
</script>