<template>
    <button :class="data_class"
            @click="click"
            :disabled="processing || disabled"
            :type="type">
        <div v-if="!processing">
            <div v-if="loca_has_icon" class="icon-wrapper-24 icon">
                <i :class="icon" aria-hidden="true"></i>
            </div>
            <span :class="[hasGo ? 'pr-4' : '']" style="position: relative;">
                {{text}}
                <img v-if="hasGo" class="ml-1" style="height: 24px; position: absolute; top: -1px;" src="../assets/images/go.svg" />
            </span>
        </div>
        <span v-else>
            <b-overlay style="height: 24px;" :show="true" :spinner-small="true" blur="0" bg-color="none"></b-overlay>
        </span>
    </button>
</template>
<script>
    export default {
        props: {
            text: {
                type: String,
                default: ""
            },
            icon: {
                type: String,
                default: ""
            },
            processing: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: "button"
            },
            disabled: {
                type: Boolean,
                default: false
            },
            data_class: {
                type: String,
                default: ""
            },
            hasGo: {
                type: Boolean,
                default: false
            }

        },
        data: function () {
            return {
                loca_has_icon: this.icon != ""
            }
        },
        methods: {
            click() {
                this.$emit("on-click");
            }
        }
    }
</script>