<template>
    <b-container class="p-0 privacy-group-checkboxe">
        <b-overlay :show="processing" spinner-variant="primary" style="height: 100%;" class="loader"></b-overlay>
        <b-row no-gutters>
            <b-col>
                <b-form-checkbox v-model="mainCheckbox"
                                 @change="clickMainCheckbox"
                                 :disabled="disabled"
                                 :class="['default-checkbox', PartialSelected ? 'partial' : '', processing ? 'cursor-wait' : '']">
                    <div class="description">{{item.header}} </div>
                </b-form-checkbox>
                <b-container fluid style="padding-left: 40px;">
                    <b-row v-for="(option, index) in options" :key="index" no-gutters :class="['py-3', (index + 1) != options.length ? 'border-bottom-gray-200' : '']">
                        <b-col><div class="text-regular-14">{{option.name}}</div></b-col>
                        <b-col cols="auto">
                            <b-form-checkbox v-model="option.value"
                                             :class="['switch-small', processing ? 'cursor-wait' : '']"
                                             name="check-button"
                                             :disabled="disabled"
                                             switch
                                             @change="itemChange()"></b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-container>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <a :href="item.url" class="link-primary" style="padding-left: 40px;" target="_blank">{{item.url}}</a>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    export default {
        props: {
            item: Object,
            value: String,
            processing: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                mainCheckbox: false,
                options: []
            }
        },
        methods: {
            clickMainCheckbox() {
                var self = this;
                this.$nextTick()
                    .then(function () {
                        if (self.PartialSelected) {
                            self.mainCheckbox = false;
                            self.setOptionsValue(false);
                        }
                        else {
                            if (self.mainCheckbox) {
                                self.setOptionsValue(true);

                            }
                            else {
                                self.setOptionsValue(false);
                            }
                        }
                        self.emitValue();
                    })

            },
            onChange() {
                this.$emit("change");
            },
            setOptionsValue(val) {
                for (var i = 0; i < this.options.length; i++) {
                    this.options[i].value = val;
                }
            },
            itemChange() {
                var self = this;

                this.$nextTick()
                    .then(function () {

                        if (self.PartialSelected) {
                            self.mainCheckbox = false;

                        }
                        else {
                            if (self.AllSelected) {
                                self.mainCheckbox = true;
                            }
                            else {
                                self.mainCheckbox = false;
                            }
                        }
                        self.emitValue();


                    })

            },
            emitValue() {
                var result = "";
                for (var i = 0; i < this.options.length; i++) {
                    var val = this.options[i].value == true ? '1' : '0';
                    result += this.options[i].name + "=" + val + ";";
                }
                this.$emit("input", result);
            }
        },
        computed: {
            PartialSelected() {
                //this.emitValue();

                if (this.options.filter(x => x.value == true).length == this.options.length) {
                    return false;
                }
                else if (this.options.filter(x => x.value == false).length == this.options.length) {
                    return false;
                }
                else {
                    return true;
                }

            },
            AllSelected() {
                if (this.options.filter(x => x.value == false).length == this.options.length) {
                    return false;
                }
                else {
                    return true
                }
            }
        },
        beforeMount() {
            if (this.item && this.item.options) {
                var valueOptionsDirt = this.value.split(";")
                var valueOptions = [];
                for (var x = 0; x < valueOptionsDirt.length; x++) {
                    var subitemArray = valueOptionsDirt[x].split("=");
                    valueOptions.push({ name: subitemArray[0], value: subitemArray[1] == '1' });
                }

                var arrayOptions = this.item.options.split(";")
                for (var i = 0; i < arrayOptions.length; i++) {
                    var itemVal = valueOptions.find(x => x.name == arrayOptions[i]);
                    if (itemVal) {
                        this.options.push({ name: arrayOptions[i], value: itemVal.value })

                    } else {
                        this.options.push({ name: arrayOptions[i], value: false })
                    }
                }

                if (this.options.filter(x => x.value == true).length > 0) {
                    this.mainCheckbox = true;
                }
            }

        }
    }
</script>