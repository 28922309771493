<template>
    <span>
        <span class="hide-in-mobile">
            <paginate v-model="page"
                      :page-count="data_page_count"
                      :page-range="5"
                      :first-last-button="false"
                      :margin-pages="0"
                      :click-handler="clickedPage"
                      :hide-prev-next="true"
                      :next-class="data_page_count <= 5 ? 'd-none' : 'next'"
                      :prev-class="data_page_count <= 5 ? 'd-none' : 'prev'"
                      prev-text="<div class='icon-wrapper-16'><i class='icon-left'></i></div>"
                      next-text="<div class='icon-wrapper-16'><i class='icon-right'></i></div>"
                      :container-class="'pager-default'"
                      :page-class="'page'">
            </paginate>
        </span>
        <span class="hide-in-desktop">
            <paginate v-model="page"
                      :page-count="data_page_count"
                      :page-range="3"
                      :first-last-button="false"
                      :margin-pages="0"
                      :click-handler="clickedPage"
                      :hide-prev-next="true"
                      :next-class="data_page_count <= 3 ? 'd-none' : 'next'"
                      :prev-class="data_page_count <= 3 ? 'd-none' : 'prev'"
                      prev-text="<div class='icon-wrapper-16'><i class='icon-left'></i></div>"
                      next-text="<div class='icon-wrapper-16'><i class='icon-right'></i></div>"
                      :container-class="'pager-default'"
                      :page-class="'page'">
            </paginate>
        </span>
    </span>
</template>
<script>
    import Paginate from 'vuejs-paginate'

    export default {
        components: {
            Paginate
        },
        props: {
            data_has_scroll: {
                type: Boolean,
                default: true
            },
            data_scroll_element: {
                type: String,
                default: "#app"
            },
            data_page: {
                type: Number,
                default: 1
            },
            data_page_count: Number
        },
        data() {
            return {
                page: this.data_page,
                scrollOptions: {
                    container: "body",
                    duration: 500,
                    easing: "ease",
                    offset: -80,
                    force: true,
                    cancelable: true,
                    onStart: false,
                    onDone: false,
                    onCancel: false,
                    x: false,
                    y: true
                }
                //page_count: this.data_page_count
            }
        },
        methods: {
            clickedPage(page) {
                if (this.data_has_scroll) {
                    this.$scrollTo(this.data_scroll_element, 500, this.scrollOptions)
                }
                this.$emit('on-page-change', page);
            }
        },
        watch: {
            data_page: function (val) {
                this.page = val;

            },
           
        },



    }
</script>
