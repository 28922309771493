export default {
    PRICE_BROKER_FIELD: 3,
    NUMBER_ITEMS_PAGE: 10,
    NUMBER_ITEMS_PAGE_STORE: 12,
    IMAGE_FOLDER: "img",
    GENDER: [
        { id: 'M', name: 'M', text: 'masculino' },
        { id: 'F', name: 'F', text: 'feminino' },
        { id: 'O', name: 'O', text: 'outro' },
    ],
    //AVAILABILITY: [
    //    { value: '9-12', text: '9h-12h' },
    //    { value: '12-14', text: '12h-14h' },
    //    { value: '14-19', text: '14h-19h' },
    //    { value: '19-22', text: '19h-22h' }
    //],

    AVAILABILITY: [
        { value: '10-12', text: '10h-12h' },
        { value: '12-14', text: '12h-14h' },
        { value: '15-17', text: '15h-17h' },
        { value: '17-19', text: '17h-19h' }
    ],

    BOUNDS_PORTUGAL: [
        [43.592444, -9.795430],
        [36.887471, -6.086894]
        //[41.447385, -8.409704],
        //[37.795752, -7.482088]
    ],
    TIME_FORMAT_BUSINESS_DAYS: "bd",
    TIME_FORMAT_DAYS: "d",
    DOCUMENT_TYPES: [

        { id: 'CC',              text: 'CC',},
        { id: 'BI',              text: 'BI', },
        { id: 'Passaporte', text: 'Passaporte' },
 
    ]
    
}