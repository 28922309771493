<template>
    <div class="bottom-navigation">
        <div class="blur"></div>
        <b-container fluid class="fill p-0">
            <b-row no-gutters>
                <b-col align-self="center" align="center">
                    <b-button v-b-toggle.sidebar-1 block class="button-nav align-middle menu" squared>
                        <div class="inner">
                            <div class="icon">
                                <div class="icon-wrapper-24">
                                    <i class="icon-menu" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </b-button>
                </b-col>
                <b-col align-self="center" align="center">
                    <b-button @click="showLogin" block class="button-nav align-middle" squared>
                        <div class="inner">
                            <div class="icon">
                                <div class="icon-wrapper-24">
                                    <i class="icon-user" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </b-button>
                </b-col>
                <b-col align-self="center" align="center">
                    <!--<b-button href="#" block class="button-nav align-middle" squared>
                    <div class="inner">
                        <div class="icon">
                            <div class="icon-wrapper-24">
                                <i class="icon-procurar" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </b-button>-->
                </b-col>

                <b-col align-self="center" align="center">
                    <!--<b-button href="#" block class="button-nav align-middle" squared>
                    <div class="inner">
                        <div class="icon">
                            <div class="icon-wrapper-24">
                                <i class="icon-favorite" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </b-button>-->
                </b-col>
                <b-col align-self="center" align="center">
                    <!--<b-button href="#" block class="button-nav align-middle chat" squared>
                    <div class="inner">
                        <div class="circle">
                            <div class="icon">
                                <div class="icon-wrapper-24">
                                    <i class="icon-chat" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-button>-->
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    export default {
        name: 'bottom-navigation',
        props: {

        },
        methods: {
            showLogin() {
                if (!this.IsAutenticated) {
                    this.$store.commit('ui/setShowLogin', { show: true, redirectTo: null }, { root: true })
                } else {
                    this.$router.push('profile-menu');
                }
            }
        },
        computed: {
            IsAutenticated() {
                return this.$store.getters['authentication/isAuthenticated'];
            }
        }
    }
</script>
