<template>
    <div class="privacy-checkboxes">
        <b-container fluid class="p-0">
            <b-row :class="[index != items.length - 1 ? 'mb-3' : '']" no-gutters v-for="(item,index) in items" :key="index">
                <b-col>
                    <div v-if="!item.options">
                        <validation-provider :rules="getRules(item)" v-slot="{ errors}">
                          

                            <div v-if="readonly">
                                <b-form-checkbox v-model="options[index].value"
                                                 class="default-checkbox" 
                                                 @input="itemChange()"
                                                 @change="onChange"
                                                 :disabled="itemReadOnly(item)">
                                    <div class="description">{{item.header}}</div>
                                </b-form-checkbox>

                            </div>
                            <div v-else>
                                <b-form-checkbox v-model="options[index].value"
                                                 class="default-checkbox" 
                                                 @input="itemChange()"
                                                 @change="onChange"
                                                 >
                                    <div class="description">{{item.header}}</div>
                                </b-form-checkbox>
                            </div>

                            <div v-if="$route.query && $route.query.debug">
                                {{item}} - {{itemReadOnly(item)}} - {{item.mandatory.toLocaleLowerCase()}}
                            </div>

                            <a :href="item.url" target="_blank" class="link-primary" style="padding-left: 40px;">{{item.url}}</a>
                            <div class="error-message" style="padding-left: 38px;">{{errors[0]}}</div>
                        </validation-provider>
                    </div>
                    <div v-else>
                        <validation-provider :rules="getRulesForMultiple(item)" v-slot="{ errors}">
                            <privacy-group-checkboxe 
                                                     v-model="options[index].value" 
                                                     :item="item"
                                                     @input="itemChange()" 
                                                     @change="onChange"
                                                     :processing="loading"
                                                     :disabled="loading"
                                                     ></privacy-group-checkboxe>
                            <div class="error-message" style="padding-left: 38px;">{{errors[0]}}</div>
                        </validation-provider>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div v-if="$route.query && $route.query.debug">
            {{items}}
            <p>{{readonly}}</p>
        </div>

        </div>
</template>
<script>
    import PrivacyGroupCheckboxe from '@/components/PrivacyGroupCheckboxe.vue';

    export default {
        components: {
            PrivacyGroupCheckboxe
        },
        props: {
            refresh_token: {
                type: String,
                default: "",
              
            },
            readonly: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: () => []
            },
            value: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                data: null,
                options: [],
            }
        },
        methods: {
            getRules(item) {
                if (item.mandatory.toLowerCase() == "yes") {
                    return "isTrue";
                }
                return "";
            },
            getRulesForMultiple(item) {
                if (item.mandatory.toLowerCase() == "yes") {
                    return "privacymultiplerequired";
                }
                return "";
            },
            itemChange() {
                var result = [];
                for (var i = 0; i < this.options.length; i++) {
                    if (this.options[i].multiple) {
                        result.push({ "id": this.options[i].id, "value": this.options[i].value })
                    }
                    else {
                        result.push({ "id": this.options[i].id, "value": this.options[i].value == true ? '1' : '0' })
                    }
                }
                this.$emit("input", result);
            },
            onChange() {
                this.$emit("change"); 
            },
            setUpValues() {
                this.$set(this, "options", new Array());

                if (this.items) {
                    for (var i = 0; i < this.items.length; i++) {
                        var itemVal = this.value.find(x => x.id == this.items[i].itemId);
                        //If has no multiple options
                        if (!this.items[i].options) {
                            if (itemVal) {
                                this.options.push({ id: this.items[i].itemId, value: itemVal.value == '1', multiple: false });
                            }
                            else {
                                //if has no value apply default
                                this.options.push({ id: this.items[i].itemId, value: this.items[i].default == '1', multiple: false });
                            }
                        }
                        //If has multiple options
                        else {
                            if (itemVal) {
                                this.options.push({ id: this.items[i].itemId, value: itemVal.value, multiple: true });
                            }
                            else {
                                //if has no value apply default
                                var optionsDefaultVal = "";
                                var arrayOptions = this.items[i].options.split(";")
                                for (var j = 0; j < arrayOptions.length; j++) {

                                    optionsDefaultVal += arrayOptions[j] + "=" + this.items[i].default + ";";
                                }
                                this.options.push({ id: this.items[i].itemId, value: optionsDefaultVal, multiple: true });

                            }
                        }
                    }
                }
            },
            itemReadOnly(item) {


                return (item.mandatory.toLocaleLowerCase() == 'yes');
            }

        },
        computed: {
            

        },
        watch: {
            refresh_token: function () {
                this.$set(this, "options", new Array())
                this.setUpValues();
            }
        },
        mounted() {

        },
        beforeMount() {
            this.setUpValues();
        },

    }


</script>
