<template>
    <div>
        <ValidationObserver v-slot="{ invalid }">
            <b-form name="form" @submit.prevent="SaveForm" class="h-100">
                <b-container class="p-4 d-flex flex-column h-100">

                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-text type="text"
                                            :label="$t('firstname')"
                                            v-model="AccountEdit.name"
                                            :data_error="errors[0]">
                                    
                                </input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-text type="text" :label="$t('lastname')" v-model="AccountEdit.surname" :data_error="errors[0]"></input-text>
                            </validation-provider>

                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider  rules="required|phone" v-slot="{ errors }">
                                <input-text type="text" :label="$t('phone')" v-model="AccountEdit.contact" :data_error="errors[0]"></input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>


                    <b-row class="mb-3">
                        <b-col>

                            <dropdown-default data_parent_dropdown="dropdownBackofficeNewsListLang14"
                                              :data_options="Gender"
                                              :data_label="$t('genre.title')"
                                              @input="AccountEdit.genre = $event.name"
                                              :data_selected="AccountEdit.genre"
                                              :data_settings="{
                                                   minimumResultsForSearch: -1 ,
                                                   theme: 'simple'   ,
                                                   dropdownParent: '#dropdownBackofficeNewsListLang14' }">
                            </dropdown-default>

                        </b-col>
                    </b-row>



                    <b-row class="flex-grow-1">
                        <b-col align-self="end" style="text-align: center">
                            <button class="button-secondary floor-light" style="max-width: 344px;" :disabled="invalid">
                                <span>{{$t('save')}}</span>
                            </button>
                        </b-col>
                    </b-row>

                </b-container>

            </b-form>
        </ValidationObserver>
        <!--{{AccountEdit}}-->
    </div>
</template>
<script>
    import InputText from '@/components/InputText.vue'
  //  import Datepicker from '@/components/Datepicker.vue'
    import DropdownDefault from '@/components/DropdownDefault.vue'
    import config from '@/helpers/config'

    //import AlertMessage from '@/models/alert-message.js'

    export default {
        components: {
            InputText,
         //   Datepicker, 
            DropdownDefault
        },
        data() {
            return {
                AccountEdit :  Object.assign({},this.$store.state.profile.account), // clone the Account
                Gender: config.GENDER               
            }
        },
        methods: {

            SaveForm() {

               

                  // todo: temporary fix for broker wrong field birth_date
                this.AccountEdit["birthdate"] = this.AccountEdit.birth_date;

                var account_new = {
                    account: this.AccountEdit,
                    accountable: this.$store.state.profile.accountable
                };

             


                this.$store.dispatch('profile/saveUserDetails',
                    {
                        user:account_new ,
                        userid:  this.$store.state.authentication.user.id,
                        session: this.$store.state.authentication.user.sessionId
                    }).then(

                        () => {
                            this.$emit('saved');
                            this.$store.dispatch('profile/getUserDetails',
                                {
                                    id: this.$store.state.authentication.user.id,
                                    session: this.$store.state.authentication.user.sessionId
                                });
                        }
                    )                 
            }
        },
        computed: {
          
        },
        mounted() {

        }

    };
</script>
