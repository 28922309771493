<template>
    <div class="h-100">
 

        <ValidationObserver v-slot="{ invalid }">
            <b-form name="form" @submit.prevent="handleReset" class="h-100">

                <b-container class="p-4 d-flex flex-column h-100">
                    <b-row class="mb-0">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-text v-model="code" label="código" :data_error="errors[0]"></input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <button type="button" @click="resendClick" class="link-primary">{{$t('resend_code')}}</button>
                            <span style="display: inline-block;">
                                <b-overlay :show="RequestCodeForChangePasswordProcessing" spinner-variant="primary" no-center spinner-small style="height: 18px;"></b-overlay>
                            </span>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required|passwordcomplexity|confirmed:confirmation" v-slot="{ errors }">
                                <input-text v-model="user.Password" type="password" :label="$t('new_password')" :data_error="errors ? errors[0]: '' " />
                                <strength-password v-show="user.Password.length > 0" :value="user.Password" @input="user.Password = $event"></strength-password>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }" vid="confirmation">
                                <input-text v-model="user.PasswordConfirm" type="password" :label="$t('confirm_password')" :data_error="errors ? errors[0]: '' " />
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="flex-grow-1">
                        <b-col align-self="end" style="text-align: center" class="hide-in-mobile">
                            <button type="button" @click="clickedOnCancel" class="button-alternative">
                                <span>{{$t('cancel')}}</span>
                            </button>
                        </b-col>
                        <b-col align-self="end" style="text-align: center">
                            <button type="submit" class="button-secondary floor-light" :disabled="invalid">
                                <span>{{$t('save')}}</span>
                            </button>
                        </b-col>

                    </b-row>
                </b-container>


            </b-form>
        </ValidationObserver>

    </div>
</template>



<script>

    import InputText from '@/components/InputText.vue'
    import UserPassword from '@/models/user_password';
    import StrengthPassword from '@/components/StrengthPassword.vue'


    export default {
        name: 'Profile',
        data() {
            return {
                user: new UserPassword(),
                code: ""
            };
        },
        components: {
            InputText,
            StrengthPassword
        },
        methods: {
            resendClick() {
                this.$emit('on-resend-click');
            },
            clickedOnCancel() {
                this.$emit('on-cancel-click');
            },
            handleReset() {


                this.$store.dispatch('authentication/updatePassword',
                    {
                        "Id": this.user.Id,
                        "Password": this.user.Password,
                        "Token": this.code,
                        "ClientSessionId": this.currentUser.sessionId
                    }
                ).then(

                    () => {
                        this.$emit('saved');
                    }

                );


            },

        },
        computed: {

            currentUser() {

                return this.$store.state.authentication.user;
            },
            RequestCodeForChangePasswordProcessing() {


             //   return false;

                if (this.$store.state.authentication &&
                    this.$store.state.authentication.requestCodeForChangePassword &&
                    this.$store.state.authentication.requestCodeForChangePassword.processing) {

                    return this.$store.state.authentication.requestCodeForChangePassword.processing;

                }

                return false;

            },

        },
        mounted() {




            this.user.Id = this.currentUser.id;


        }

    };
</script>
