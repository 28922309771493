export const SUPPORTED_LOCALES = [{
    code: 'pt',
    base: '',
    flag: 'pt',
    name: 'Português',
    translations: '../locales/pt.json'
}, {
        code: 'en',
        base: '',
        flag: 'us',
        name: 'English',
        translations: '../locales/en.json'
}]