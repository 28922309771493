<template>
    <div class="dropdown-default-outer">
        <div :class="['dropdown-default',
                       hasLabel ? 'has-label' : '',
                       IsDirty ? 'is-dirty' : '',
                       hasErrors ? 'has-errors' : '',
                       data_input_not_ready ? 'not-ready' : '',
                       data_input_ready ? 'is-ready' : '']">
            <Select2 ref="dropdown"
                     v-model="myValue"
                     :disabled="disabled"
                     :options="data_options"
                     :settings="settings"
                     @change="myChangeEvent($event)" />
            <div :id="data_parent_dropdown" style="position:relative"></div>
            <span @click="clickOnLabel" v-if="hasLabel" class="floating-label">{{data_label}}</span>
        </div>
        <div v-if="hasErrors" class="error-message pl-2 mb-2">{{data_error}}</div>
    </div>
</template>
<script>
    import Select2 from 'v-select2-component';
    import $ from 'jquery';

    export default {
        props: {
            data_parent_dropdown: String,
            data_options: Array,
            data_selected: String,
            data_error: String,
            data_settings: Object,
            data_label: String,
            data_input_ready: {
                type: Boolean,
                default: false
            },
            data_input_not_ready: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
        },
        components: { Select2 },
        data() {
            return {
                myValue: this.data_selected,
                //     myOptions: this.data_options,
                settings: this.data_settings,
                hasLabel: this.data_label != null && this.data_label != "",
                select2: null,
                menuOpen: false
            }
        },
        methods: {
            myChangeEvent(val) {

                // console.log(this.myOptions.find(  e => e.id  == val   ));

                this.$emit('input', this.data_options.find(e => e.id == val));
            },
            clickOnLabel() {
                if (this.menuOpen) {
                    this.menuOpen = false;
                    this.select2.select2('close');
                } else {
                    this.menuOpen = true;
                    this.select2.select2('open');
                }
            }
        },
        watch: {
            data_selected: function (newValue) {

                this.myValue = newValue;
            }
        },
        computed: {
            IsDirty() {
                if (this.myValue != null && this.myValue != "") {
                    return true;
                }
                return false;
            },
            hasErrors: function () {
                return this.data_error != null && this.data_error.length > 0;
            }
        },
        mounted() {
            this.select2 = $(this.$refs.dropdown.$el).find('select');
        }
    }
</script>
