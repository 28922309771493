<template>
    <div>


        <b-row>
            <b-col>Nome</b-col>
            <b-col>{{User.fullname}}</b-col>
        </b-row>
        <b-row>
            <b-col>Email</b-col>
            <b-col>{{User.email}}</b-col>
        </b-row>
        <b-row>
            <b-col>Telefone</b-col>
            <b-col>{{User.mobilephone}}</b-col>
        </b-row>
        <b-row>
            <b-col> Role </b-col>




            <b-col>


                <div v-if="role == 'Admin' || role == 'admin'  " class="p-2">

                    User   <input type="radio" @click="role = 'user'" :checked="false" /> 

                    Admin   <input type="radio" @click="role = 'admin'" :checked="true" />


                </div>
                <div v-else class="p-2">

                    User      <input type="radio" @click="role = 'user'" :checked="true" /> 

                    Admin     <input type="radio" @click="role = 'admin'" :checked="false" />


                </div>

            </b-col>



        </b-row>

        <b-row>
            <b-col class="text-center">
                <button class="btn button-alternative" @click="$router.go(-1)" :disabled="Processing">
                    Cancelar
                </button>
            </b-col>

            <b-col class="text-center">
                <button class="btn button-secondary" @click="SaveRole" :disabled="Processing">
                    Gravar
                </button>
            </b-col>

        </b-row>


        <!--
            <div>{{id}}</div>
        <div>
            {{User.role}}
        </div>
            -->

        <!--<div>
        {{role}}
    </div>

    <div>
        {{User}}
    </div>

    <div>
     is Admin   {{ isAdmin }}
    </div>-->


    </div>
</template>
<script>

    

    export default {
        name: 'Edit',
           components: {        
               
        },

        data() {
            return {
                id: this.$route.params.id,
                role: {},
                isAdmin: false,
                  options: [
          { value: 'admin', text: 'Admin' },
          { value: 'user', text: 'User' }, 
        ]
            };
        },
        computed: {
            User() {
                return this.$store.state.backoffice.users.find(e => e.id == this.id);
            },
            Processing() {
                  return this.$store.state.backoffice.operation.processing;
            }
        },
        watch: {
           User: function () {
               // this.role = this.user.role;
               // this.$forceUpdate(); 
                console.log("USer log");
            }

        },
        methods: {

            Change() {

              //  console.log("Change");
            },
            SaveRole() {

               // console.log("Change");

                this.$store.dispatch('backoffice/updateRole',
                    {
                        userid: this.id,
                        role: this.role
                    }
                ).then(

                    () => {

                        this.$router.go(-1);
                    }

                );

                    //.then(

                    //    () => {
                    //         //  this.$emit('saved');
                    //    }


                    //);



            },


        },
        created() {
        }, 
        mounted() {

       //  this.$set(this, "role", this.$store.state.backoffice.users.find(e => e.id == this.$route.params.id).role);

                this.role = this.$store.state.backoffice.users.find(e => e.id == this.$route.params.id).role;  

        }
    };
</script>