export function noAuthHeader() {
   
    return {          
       
        'Content-Type': 'application/json; charset=UTF-8',
            "Accept": "*/*",
             //'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Sec-Fetch-Mode': 'no-cors', 
        //Access-Control-Allow-Origin
     

        };

    
}