<template>
    <div class="news">

        <b-container>

            <!--<b-row no-gutters class="flex-grow-1" style="text-align: center;">
        <b-col>
            <router-link :to="`/${$i18n.locale}/backoffice`" class="item-entry">
                <b-row no-gutters class="flex-grow-1 mt-2">
                    <b-col align-self="center">
                        <button class="button-transparent">
                            <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('back')}}</span>
                        </button>
                    </b-col>
                </b-row>
            </router-link>
        </b-col>
    </b-row>-->
            <b-row>
                <b-container>
                    <b-row no-gutters class="mt-2 mb-4">
                        <b-col cols="auto"><div class="big-text">{{$t('news')}}</div></b-col>
                    </b-row>
                </b-container>
            </b-row>


            <b-row class="mb-4 mt-3" style="justify-content:flex-end;">
                <b-col cols="4">
                    <router-link :to="`/${$i18n.locale}/backoffice/news/New`">
                        <button type="button" class="button-alternative">
                            <span>{{$t('backoffice_news.new_news')}}</span>
                        </button>
                    </router-link>
                </b-col>
                <b-col></b-col>
                <b-col cols="6" style="display:flex; justify-content:flex-end;">
                    <span class="filter-text">{{$t('filter_language')}}</span>
                    <div class="d-inline-block right" style="width: 140px;">
                        <dropdown-default class="pt-1"
                                          :data_options="langs"
                                          data_parent_dropdown="dropdownBackofficeNewsListLang"
                                          :data_selected="localeFilter"
                                          @input="changeLocale"
                                          :data_settings="{
                                                 minimumResultsForSearch: -1 ,
                                                 theme: 'filter',
                                                placeholder: 'idioma',
                                                dropdownParent: '#dropdownBackofficeNewsListLang'
                                                 }">
                        </dropdown-default>
                    </div>
                </b-col>
            </b-row>


            <b-row v-if="newsLists.length == 0">
                <b-col style="text-align:center;">
                    {{$t('no_content_found')}}
                </b-col>
            </b-row>

            <b-row no-gutters class="mb-4" v-if="newsLists.length > 0">
                <b-container>
                    <b-row>
                        <table class="news-table">
                            <tr class="table-title">
                                <!--<th>Id</th>-->
                                <th>{{$t('backoffice_news.title')}}</th>
                                <th>{{$t('backoffice_news.date')}}</th>
                                <th style="padding: 10px 15px;">{{$t('backoffice_news.language')}}</th>
                                <th></th>
                            </tr>
                            <tr v-for="(item,index) in newsLists" :key="index">
                                <!--<td>
                            {{item.id}}
                        </td>-->
                                <td>
                                    <!--<router-link :to="`/${$i18n.locale}/backoffice/news/details/${item.id}`">-->
                                    {{item.title}}
                                    <!--</router-link>-->
                                </td>
                                <td class="table-cell"><span v-html="$formatDate(item.date)"></span></td>
                                <td class="table-cell">{{item.locale}}</td>
                                <td>
                                    <b-row>
                                        <b-col cols="auto">
                                            <router-link :to="`/${$i18n.locale}/backoffice/news/Edit/${item.id}`">
                                                <!--<button class="button-transparent">-->
                                                    <span>{{$t('edit')}}</span>
                                                <!--</button>-->
                                            </router-link>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="auto">
                                            <router-link :to="item" class="item-entry red">
                                                <!--<button class="button-transparent">-->
                                                    <span v-on:click="onDelete(`${item.id}`)">{{$t('delete')}}</span>
                                                <!--</button>-->
                                            </router-link>
                                        </b-col>
                                    </b-row>
                                </td>
                            </tr>
                        </table>

                    </b-row>
                </b-container>
            </b-row>


            <b-container class="p-4">
                <b-row no-gutter>
                    <b-col></b-col>
                    <b-col cols="auto">
                        <pager-default :data_page="currentPage" :data_page_count="totalPages" @on-page-change="changePage"></pager-default>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </b-container>

        </b-container>
                <!--<router-view></router-view>-->
</div>
</template>

<script>

    import PagerDefault from '@/components/PagerDefault.vue';
    import DropdownDefault from '@/components/DropdownDefault.vue';

    export default {
        name: 'NewsList',
        components: {
            PagerDefault,
            DropdownDefault},
        data() {
            return {
                currentPage: 1,
               // totalPages: 1,
                localeFilter: '',
                //newsLists: [],
                langs: this.pushingLangs(),
            };
        },
        methods: {
            onDelete(id) {
                if (confirm(this.$i18n.t('delete_message'))) {

                    this.$store.dispatch('news/deleteNews',  id ).then(
                        response => {

                            console.log(response);
                            //location.reload();
                            this.$store.dispatch('news/getNewsList', { lang: this.localeFilter, page: this.currentPage, hide: false });
                        },
                        error => {
                            this.message = (error.response && error.response.data) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
             changePage(page) {
                
               //  this.$store.dispatch('news/getNewsListByPage', { numPage: page });

               this.$store.dispatch('news/getNewsList', { lang : this.localeFilter , page:page, hide:false  }  );


                 // if (this.localeFilter == "") {
                 //   this.$store.dispatch('news/getNewsListByPage', { numPage: page });
                 // } else {
                 //     this.$store.dispatch('news/getNewsByPage', { locale: this.$i18n.locale, numPage: page });
                 //}
                 

            },
             filterList() {
                 console.log("locale filter " + this.localeFilter);

                 this.$store.dispatch('news/getNewsList', { lang: this.localeFilter, page: 1, hide: false });



            //     .then(
            //        response => {

            //            this.newsLists = [];
            //        this.newsLists = response;
            //        this.totalPages = Math.floor(this.$store.state.page.numPages / 12) + 1;
            //        console.log("Total pages apos refresh " + this.totalPages);

            //    },
            //    error => {
            //        this.message = (error.response && error.response.data) ||
            //            error.message ||
            //            error.toString();
            //    }
            //);


            },
               pushingLangs() {
                   var list = []
                   
                   this.$i18n.availableLocales.forEach((value) => {
                        list.push({ id: value, name: value, text: value });
                   });

                   
                return list;
            },
            changeLocale(val) {
                this.localeFilter = val.text;
                this.filterList();
            }

        },
        created() {

         //   this.$store.dispatch('news/getNewsList', { lang: this.$i18n.locale, page: 1 });

              this.$store.dispatch('news/getNewsList', { lang : "", page:1, hide:false  }  );

            
        },
        computed: {
             newsLists() {
                return this.$store.state.news.newsList;
            },
            totalPages() {
                return this.$store.state.news.totalPages;
            },

        },
        mounted() {

          

        }
    };
</script>
