import { apiService } from '../services';
import i18n from '../i18n'

export const news = {
    namespaced: true,
    state: {
        operation: {},
        newsList: {},
      //newsFilteredList: {},
        newsInfo: null,
        totalNews: 0,
        totalPages: 0
    },
    actions: {

        getNewsId({ commit }, id) {

            commit('getNewsIdRequest');
       

            return new Promise(resolve => {
                apiService.getNewsId( id)
                    .then(({ data }) => {

                   

                        commit('getNewsIdSucess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {
                         

                        commit('getNewsIdFailure', response);
                    });
            });

        },

        getNewsList({ commit }, request) {

            //console.log(String(locale));
            commit('getNewsListRequest');

            return new Promise(resolve => {
                apiService.getNewsList(request.lang, request.page, request.hide  )
                    .then(({ data }) => {

                     
                        commit('getNewsListSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {                    

                        console.log(response);

                        commit('getNewsListFailure');
                    });
            });

        },

       

        addNews({ commit, dispatch }, news) {

            //console.log("reached save news");
            //console.log(news);
            commit('mutationNewsRequest');

            //console.log("something");
            return new Promise(resolve => {
                apiService.addNews(news)
                    .then(({ data }) => {

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_saved') }, { root: true });

                        commit('mutationNewsSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        //console.log("Save news response error");
                        //console.log(response);

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });


                        commit('mutationNewsFailure', response.data.errors);




                    });
            });

        },

        saveFile({ commit, dispatch }, newsData) {

          

            commit('mutationNewsRequest');

            return new Promise(resolve => {
                apiService.SaveFile( newsData.file)
                    .then(({ data }) => {

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_saved')  }, { root: true });

                        commit('mutationNewsSuccess', data);
                        resolve(data);
                    })
                    .catch(({ response }) => {

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });

                        if (response.data && response.data.errors) {
                            commit('mutationNewsFileFailure', response.data.errors);
                        } else {

                            commit('mutationNewsFileFailure', response.data);
                        }
                     


                });
            });
        },

        updateNews({ commit, dispatch }, newsData) {

            //console.log("reached update news");
            //console.log(newsData);
            commit('mutationNewsRequest');

            return new Promise(resolve => {
                apiService.updateNews(newsData)
                    .then(({ data }) => {

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_saved')  }, { root: true });

                        commit('mutationNewsSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                     

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });

                        commit('mutationNewsFailure', response.data.errors);
                    });
            });

        },

        deleteNews({ commit, dispatch }, id) {

            //console.log("reached delete news");
            //console.log(newsData);
            commit('mutationNewsRequest');

            return new Promise(resolve => {
                apiService.deleteNews(id)
                    .then(({ data }) => {

                        //console.log("Deleted news with sucess");
                        //console.log(data);

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_deleted') }, { root: true });

                        commit('mutationNewsSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        //console.log("Delete news response error");
                        //console.log(response);

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });

                        commit('mutationNewsFailure', response.data.errors);
                    });
            });

        }


    },
    mutations: {

        /**
         *  get news by id
         */

        getNewsIdRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.newsInfo = null;
        },
        getNewsIdSucess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            state.newsInfo = data;
        },
        getNewsIdFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.newsInfo = null;
        },


        /**
         *    get news list
         */
         
        getNewsListRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.newsList = [];
            state.numNews = 0;
            state.totalNews = 0;
            state.totalPages = 0;
        },
        getNewsListSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            state.newsList = data.result;       
            state.totalNews = data.total;
            state.totalPages = data.pages;
        },
        getNewsListFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.newsList = [];
            state.totalNews = 0;
            state.totalPages = 0;
        },


        /**
         *    get news Filtered list
         */

        //getNewsFilteredListRequest(state) {
        //    state.operation = { processing: true, success: false, message: "", res: "" };
        //    state.newsFilteredList = {};
        //},
        //getNewsFilteredListSuccess: function (state, data) {
        //    state.operation = { processing: false, success: true, message: "", res: "" };
        //    state.newsFilteredList = data;
        //    state.numNews = data.length;

        //    console.log("num News " + state.numNews);
        //},
        //getNewsFilteredListFailure: function (state, data) {
        //    state.operation = { processing: false, success: false, message: "Ocorreu um erro", res: data };
        //    state.newsFilteredList = {};
        //},

        /**
         *  save news, edit news, delete news, save file, 
         */

        mutationNewsRequest(state) {
            state.operation = { processing: true, success: false, message: "", result: false };
        },
        mutationNewsSuccess(state, res) {
            state.operation = { processing: false, success: true, message: res, result: true };

        },
        mutationNewsFailure(state, res) {
            state.operation = { processing: false, success: false, message: res, result: false };
        },

    }
}
