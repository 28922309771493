<template>
    <div>
        <vue-editor :value="value"   @input="textChange" :editor-toolbar="customToolbar"  ></vue-editor>

    </div>
</template>
<script>

     import { VueEditor } from "vue2-editor";

    export default {
      name: 'HtmlEditor',
        components: {
            
            VueEditor
        },

        props: {

            value: String
        },
        data() {
            return {
                customToolbar: [

                    [{ size: ["small", false, "large", "huge"] }],

                    ["bold", "italic", "underline", "strike"],

                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" }
                    ],

                    [{ list: "ordered" }, { list: "bullet" }],

                    ["link"],

                    ["clean"]

                ]
            }
        },
        computed: {
           
        },
        methods: {
            textChange(value){

                    
               this.$emit('input', value);

             }
     
        },
        watch: {
       
        },

    }
</script>