<template>
    <div class="pages no-text-transform">

        <b-container>

            <b-row no-gutters class="flex-grow-1" style="text-align: center;">
                <b-col>
                    <router-link :to="`/${$i18n.locale}/backoffice/pages`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col align-self="center">
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('back')}}</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                </b-col>
            </b-row>

            <b-row no-gutters>
                <b-container>
                    <b-row no-gutters class="mt-4">
                        <b-col></b-col>
                        <b-col cols="auto"><div class="big-text">{{Page.title}}</div></b-col>

                        <b-col></b-col>
                    </b-row>
                    <b-row no-gutters class="mb-4">
                        <b-col></b-col>
                        <b-col cols="auto"><div class="description no-text-transform" style="max-width:188px; text-align:center">{{Page.description}}</div></b-col>
                        <b-col></b-col>
                    </b-row>
                </b-container>
            </b-row>

            <b-row class="mb-5">
                <b-container>
                    <b-row no-gutters>
                        <b-col>
                            <div class="description" style="text-align:left;">
                                <span v-html="Page.html_content"></span>
                            </div>

                        </b-col>
                    </b-row>
                </b-container>
            </b-row>

            <!--{{$route.params.pageDetId  }}-->

        </b-container>

    </div>
</template>

<script>

    export default {
        name: 'Page',

        data() {
            return {};
        },
        created() {
        },
        components: {
        },
        computed: {
            Page() {
                return this.$store.state.page.page;
            },
        },
        mounted() {
            this.$store.dispatch('page/getPageById', { id: this.$route.params.pageDetId, locale: this.$i18n.locale });

        }
    };
</script>
