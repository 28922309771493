<template>
    <div class="strength-password">
        <slot></slot>
        <div class="bar-indicator">
            <span v-bind:class="[colorClass]">
            </span>
        </div>
        <p class="text-semibold-14 title" v-bind:class="[colorClass]">{{  $t(`account_create.password_validation.strength_value.` +  String(strengthValue)  ) }}</p>
        <p class="description text-regular-14">{{$t('account_create.password_validation.description')}}</p>
        <div class="conditions">
            <div class="condition-item text-regular-14"> <b-icon icon="x" v-show="!minChars"></b-icon> <b-icon icon="check2" v-show="minChars"></b-icon> {{ $t('account_create.password_validation.eight_letters') }}</div>
            <div class="condition-item text-regular-14"> <b-icon icon="x" v-show="!oneNumber"></b-icon> <b-icon icon="check2" v-show="oneNumber"></b-icon> {{ $t('account_create.password_validation.one_number') }}</div>
            <div class="condition-item text-regular-14"> <b-icon icon="x" v-show="!oneUpper"></b-icon> <b-icon icon="check2" v-show="oneUpper"></b-icon> {{ $t('account_create.password_validation.one_upper-char') }}</div>
            <div class="condition-item text-regular-14"> <b-icon icon="x" v-show="!oneSmall"></b-icon> <b-icon icon="check2" v-show="oneSmall"></b-icon> {{ $t('account_create.password_validation.one_lower-char') }}</div>
        </div>
    </div>

</template>

<script>

    export default {
        name: 'StrengthPassword',
        components: {},
        props: ['value'],
        data() {
            return {
                strengthValue: 1, // Index of array "bad" [0], "could-be-better" [1], "good" [2], " the best" [3], The strength sum is [1, 4]
                colorClass: "bad",
                minChars: false,
                oneNumber: false,
                oneUpper: false,
                oneSmall: false,
                valid_password: false
            }
        },
        watch: {
            value: function() {
                this.checkPassword(this.value)
                this.updateColor()
            }
        },
        methods: {

            checkPassword: function(param)
            {
                this.strengthValue = 0
                // check password size
                this.minChars = param.length >= 8 ? true: false;
                this.strengthValue += this.minChars ? 1 : 0

                // check exit 1 number
                this.oneNumber = /\d/.test(param);
                this.strengthValue += this.oneNumber ? 1 : 0

                // check exit 1 Upper char
                this.oneUpper = /[A-Z]/.test(param);
                this.strengthValue += this.oneUpper ? 1 : 0


                // check exite 1 Small char
                this.oneSmall = /[a-z]/.test(param);
                this.strengthValue += this.oneSmall ? 1 : 0

                // Index of array "bad" [0], "could-be-better" [1], "good" [2], " the best" [3], The strength sum is [1, 4]
                // just need start the var with 1 because in the html we present the (strengthValue - 1)
                this.strengthValue = this.strengthValue === 0 ? 1 : this.strengthValue

                // valid password
                this.valid_password = this.minChars && this.oneNumber && this.oneUpper && this.oneSmall;
            },

            // this method choose what scss class will be use to paint the force bar and subtitle color
            updateColor: function()
            {
                switch(this.strengthValue)
                {   
                    case 2:
                        this.colorClass = "could-be-better"
                        break;
                    case 3:
                        this.colorClass = "good"
                        break;
                    case 4:
                        this.colorClass = "the-best"
                        break;
                    default:
                        this.colorClass = "bad"
                }
            }
        }
    }

</script>