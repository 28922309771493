<template>
    <div class="no-content" style="border: 1px solid #ffffff; border-radius: 14px;">
        <b-container class="p-4">
            <!--<b-row class="mb-2">
                <b-col></b-col>
                <b-col cols="auto">
                    <div class="icon-wrapper-40 primary">
                        <i class="icon-info"></i>
                    </div>
                </b-col>
                <b-col></b-col>
            </b-row>-->
            <b-row>
                <b-col></b-col>
                <b-col cols="auto">
                    <span class="text-regular-16 primary">{{text}}</span>
                </b-col>
                <b-col></b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    export default {
        props: {
            text: String
        },
        data() {
            return {
                closed: false
            }
        },
        methods: {
            closeBar() {

                this.closed = true;
                this.$emit('action-bar-closed');
            }
        }
    }
</script>