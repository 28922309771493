<template>
    <div class="news">

        <b-container>

            <b-row no-gutters class="mb-3">
                <b-col cols="auto"><div class="big-text">Nova Noticia</div></b-col>
            </b-row>

            <b-row no-gutters class="flex-grow-1">
                <b-col>
                    <router-link :to="`/${$i18n.locale}/backoffice/news`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col>
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('cancel')}}</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                </b-col>
            </b-row>


            <ValidationObserver>
                <b-form @submit.prevent="saveNewsInfo()" autocomplete="off">
                    <b-container class="mt-4">

                        <b-row class="mb-3">
                            <b-col>
                                <datepicker v-model="date" :data_value="date" :data_label="$t('backoffice_news.date')" data_locale="pt"></datepicker>
                            </b-col>
                            <b-col></b-col>
                            <b-col>
                                <b-container class="p-0 pl-5">
                                    <b-row no-gutters>
                                        <b-col cols="auto" class="pr-2">
                                            <div class="py-3">
                                                {{$t('backoffice_page.language')}}:
                                            </div>
                                        </b-col>
                                        <b-col class="pt-1">
                                            <dropdown-default data_parent_dropdown="dropdownLanguage"
                                                              :data_options="langs"
                                                              :data_selected="locale"
                                                              @input="changeLocale"
                                                              :data_settings="{
                                                 minimumResultsForSearch: -1 ,
                                                 theme: 'language',
                                                 dropdownParent: '#dropdownLanguage'}">
                                            </dropdown-default>
                                        </b-col>
                                    </b-row>
                                </b-container>

                            </b-col>

                        </b-row>

                        <b-row class="mb-3">
                            <b-col>
                                <input-text v-model="news.title" :label="$t('backoffice_news.title')"></input-text>
                            </b-col>
                        </b-row>

                        <b-row class="mb-3">
                            <b-col cols="12">
                                <div class="text-semibold-14 mb-3">{{$t('backoffice_news.content')}}</div>
                            </b-col>
                            <b-col class="mb-3"  >
                             
                                <html-editor v-model="news.html_content"></html-editor>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <div class="text-semibold-14 mb-3">{{$t('backoffice_news.image')}}</div>
                                <input type="file" class="form-control" @change="onFileChange" style="padding:3px 5px;" />
                            </b-col>
                        </b-row>


                        <b-row class="mt-4 mb-3">
                            <b-col></b-col>
                            <b-col>
                                <router-link :to="`/${$i18n.locale}/backoffice/news`">
                                    <button type="button" class="button-alternative floor-light">
                                        <span>{{$t('cancel')}}</span>
                                    </button>
                                </router-link>
                            </b-col>
                            <b-col>
                                <button type="button" @click="saveNews" class="button-secondary floor-light">
                                    <span>{{$t('save')}}</span>
                                </button>
                            </b-col>

                        </b-row>

                    </b-container>
                </b-form>
            </ValidationObserver>

        </b-container>
    </div>
</template>

<script>
    import News from '@/models/news.js';
    import InputText from '@/components/InputText.vue'
    import DropdownDefault from '@/components/DropdownDefault.vue';
    import Datepicker from '@/components/Datepicker.vue'
    import Config from '@/helpers/config';
      import HtmlEditor from '@/components/HtmlEditor.vue'


    export default {
        name: 'NewsEdit',
        components: {
            InputText,
            DropdownDefault,
            Datepicker,
            HtmlEditor
        },
        data() {
            return {
                news: new News(),
                file: null,
                date: null,
                locale: this.$i18n.locale,
                imagepath: Config.IMAGE_PATH,
                langs: this.pushingLangs()
            };
        },
        methods: {
            onFileChange(e) {
                 this.file = e.target.files[0];
            },

            saveNews() {

                //let editor = this.$refs.editor1;
                //var content = editor.getContent();

             //   this.news.html_content = content;
                this.news.locale = this.locale;
                this.news.date = this.date;

             

                if (typeof this.file !== "undefined" && this.file !== null) {
                    this.saveFile();
                } else {

                    //this.$store.dispatch('news/addNews', this.news).then(
                    //    response => {

                    //        console.log("correctly saved without file");
                    //        console.log(response);
                    //        //window.location.href = '../news/';
                    //        this.$router.go(-1);

                    //    },
                    //    error => {
                    //        this.message = (error.response && error.response.data) ||
                    //            error.message ||
                    //            error.toString();
                    //    }
                    //);

                    this.saveNewsOnly();

                }

            },

            saveNewsOnly() {


                this.$store.dispatch('news/addNews', this.news).then(
                    () => {

                        //console.log("correctly saved without file");
                        //console.log(response);
                        //window.location.href = '../news/';
                        this.$router.go(-1);

                    },
                    error => {
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    }
                );



            },

            saveFile() {

               // console.log("************** SAVE FILE *******************")

                let formData = new FormData();
                formData.append('file', this.file);

                var filename = "";

                this.$store.dispatch('news/saveFile', { news: this.news, file: formData }).then(
                    response => {

                        //console.log("correctly saved file");
                        //console.log(response);

                        filename = response;
                        this.news.image = filename;


                        //console.log("************* EDIT VUE **************");
                        //console.log("news");
                        //console.log(this.news);

                        this.saveNewsOnly();

                        // this.$store.dispatch('news/saveNews', this.news).then(
                        //response => {

                        //    console.log("correctly saved with file");
                        //    console.log(response);
                        //         window.location.href = '../news/';

                        //         //      this.$router.go(-1);

                        //},
                        //error => {
                        //    this.message = (error.response && error.response.data) ||
                        //        error.message ||
                        //        error.toString();
                        //}
                        //);

                    },
                    error => {
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    }
                );

            },

            //    return filename;
            

            //saveFile() {

            //    console.log("************** SAVE FILE *******************")

            //    let formData = new FormData();
            //    formData.append('file', this.file);

            //    var filename = "";

            //    this.$store.dispatch('news/saveFile', { news: this.news, file: formData }).then(
            //        response => {

            //            console.log("correctly saved file");
            //            console.log(response);

            //            filename = response;
            //            this.news.image = filename;
                

            //            console.log("************* EDIT VUE **************");
            //            console.log("news");
            //            console.log(this.news);

            //             this.$store.dispatch('news/saveNews', this.news).then(
            //            response => {

            //                console.log("correctly saved with file");
            //                console.log(response);
            //                     window.location.href = '../news/';

            //                     //      this.$router.go(-1);

            //            },
            //            error => {
            //                this.message = (error.response && error.response.data) ||
            //                    error.message ||
            //                    error.toString();
            //            }
            //        );
            //        },
            //        error => {
            //            this.message = (error.response && error.response.data) ||
            //                error.message ||
            //                error.toString();
            //        }
            //    );
            //    return filename;
            //},


            pushingLangs() {
                var list = []
                this.$i18n.availableLocales.forEach((value) => {
                    list.push({ id: value, name: value, text: value });
                });

                return list;
            },
            changeLocale(val) {
                this.locale = val.text;
            }
        },
        created() {
        },
      


    };
</script>