

export const ui = {
    namespaced: true,
    state: {
        roomForNavbar: true,
        displayNavbar: true,
        displayFooter: true,
        scrollStatus: true,
        showLogin: false,
        showCreateAccount: false,
        showContactRequest: false,
        isSimpleLayout: false,
        isOrderPage: false,
        isHomePage: false,
        newsletterTopDecoratorColor: "#eef1f6",
        baseUrl: process.env.VUE_APP_SERVER_ADDRESS,
        redirectTo: null,
        query: null,
        breadcrumbItems: [],
        purchaseActiveStep: null
    },
    actions: {

        showCreateAccount({ commit }, val) {

            commit('setShowCreateAccount', val);

        },

        showLogin({ commit }, val) {

            //console.log("Vuex");
            //console.log(val);

            commit('setShowLogin', val);
        },

        showContactRequest({ commit }, val) {

            commit('setShowContactRequest', val);

            //console.log("showContactRequest store: " + val);

        },
        setSimpleLayout({ commit }, val) {

            commit('setSimpleLayout', val);
        },
        setIsOrderPage({ commit }, val) {
            //  simpleLayoutBackText: "back_to_store",
            commit('setIsOrderPage', val);
        },
        setIsHomePage({ commit }, val) {
            commit('setIsHomePage', val);
        },
        setBreadcrumbItems({ commit }, val) {
            commit('setBreadcrumbItems', val);
        },
        setPurchaseActiveStep({ commit }, val) {
            commit('setPurchaseActiveStep', val);
        },
    },
    getters: {
        getRoomForNavbar: state => {
            return state.roomForNavbar
        },
        getDisplayNavbar: state => {
            return state.displayNavbar
        },
        getDisplayFooter: state => {
            return state.displayFooter
        },
        getScrollStatus: state => {
            return state.scrollStatus
        },
        getShowLogin: state => {
            return state.showLogin
        },
        getShowCreateAccount: state => {
            return state.showCreateAccount
        },
        getNewsletterTopDecoratorColor: state => {
            return state.newsletterTopDecoratorColor
        },
        getPurchasePage: state => {
            return state.isSimpleLayout
        },
        getUrlImage: (state) => (id) => {
            return state.baseUrl + 'brokerproxy/getimage/' + id;
        },
        getGoBackText: (state) => {

            if (state.isOrderPage) {

                return "back_to_store";
            } else {

                return "back";
            }


        },


    },
    mutations: {
        setRoomForNavbar: (state, value) => {
            state.roomForNavbar = value
        },
        setDisplayNavbar: (state, value) => {
            state.displayNavbar = value
        },
        setDisplayFooter: (state, value) => {
            state.displayFooter = value
        },
        setScrollStatus: (state, value) => {
            state.scrollStatus = value
        },
        setShowLogin: (state, value) => {

            state.showLogin = value.show;

            //console.log(value);
 

            if (value.redirectTo) {

                state.redirectTo = value.redirectTo;
            }

            if ( value.query  ) {

                state.query = value.query;

            }


            // if (state.displayNavbar ) {

            //    state.displayNavbar = value.displayNavbar;
            //}
            if ('displayNavbar' in value) {

                state.displayNavbar = value.displayNavbar;
            }

        },

        setShowCreateAccount: (state, value) => {
            state.showCreateAccount = value;
        },
        setNewsletterTopDecoratorColor: (state, value) => {
            state.newsletterTopDecoratorColor = value;
        },
        setSimpleLayout: (state, value) => {
            state.isSimpleLayout = value;
        },
        setIsOrderPage: (state, value) => {
            state.isOrderPage = value;
        },
        setIsHomePage: (state, value) => {
            state.isHomePage = value;
        },
        setShowContactRequest: (state, value) => {
            state.showContactRequest = value;
        },
        setBreadcrumbItems: (state, value) => {
            state.breadcrumbItems = value;
        },
        setPurchaseActiveStep: (state, value) => {
            state.purchaseActiveStep = value
        }
    }
}
