export default class PageContent {


    constructor() {

        this.key = "";
        this.url_segment = "";
        this.title = "";
        this.html_content = "";
        this.order = "";
        this.visible_in_menu = false;
        this.orderfooter = "";
        this.visible_in_footer = false;
        this.locale = "pt";

    }



}
