var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"h-100",attrs:{"name":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.SaveForm($event)}}},[_c('b-container',{staticClass:"p-4 d-flex flex-column h-100"},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-text',{attrs:{"type":"text","label":_vm.$t('fullname'),"data_error":errors[0]},model:{value:(_vm.AccountEdit.full_name),callback:function ($$v) {_vm.$set(_vm.AccountEdit, "full_name", $$v)},expression:"AccountEdit.full_name"}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-text',{attrs:{"type":"text","label":_vm.$t('tax_number'),"data_error":errors[0]},model:{value:(_vm.AccountEdit.nif),callback:function ($$v) {_vm.$set(_vm.AccountEdit, "nif", $$v)},expression:"AccountEdit.nif"}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-text',{attrs:{"type":"text","label":_vm.$t('address_of_contact'),"data_error":errors[0]},model:{value:(_vm.AccountEdit.address),callback:function ($$v) {_vm.$set(_vm.AccountEdit, "address", $$v)},expression:"AccountEdit.address"}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-text',{attrs:{"type":"text","label":_vm.$t('phone_of_contact'),"data_error":errors[0]},model:{value:(_vm.AccountEdit.contact),callback:function ($$v) {_vm.$set(_vm.AccountEdit, "contact", $$v)},expression:"AccountEdit.contact"}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"}},[_c('dropdown-default',{attrs:{"data_parent_dropdown":"dropdownBackofficeNewsListLang14","data_options":_vm.DocTypes,"data_label":_vm.$t('doctype'),"data_selected":_vm.$t('AccountEdit.doctype'),"data_settings":{
                                               minimumResultsForSearch: -1 ,
                                               theme: 'simple'   ,
                                               dropdownParent: '#dropdownBackofficeNewsListLang14' }},on:{"input":_vm.updateDocType}})],1)],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                               var errors = ref.errors;
return [_c('input-text',{attrs:{"type":"text","label":_vm.$t('docnumber'),"data_error":errors[0]},model:{value:(_vm.AccountEdit.docnumber),callback:function ($$v) {_vm.$set(_vm.AccountEdit, "docnumber", $$v)},expression:"AccountEdit.docnumber"}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                               var errors = ref.errors;
return [_c('input-multiple-block',{attrs:{"data_name":"availability","data_label":_vm.$t('availability_for_contact'),"data_options":_vm.availability,"options_key_value":true,"data_item_value":"value","data_item_text":"text","value":_vm.selectedAvailabity,"data_error":errors[0],"data_has_clean":false},on:{"input":_vm.changeAvailability}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"flex-grow-1"},[_c('b-col',{staticStyle:{"text-align":"center"},attrs:{"align-self":"end"}},[_c('button',{staticClass:"button-secondary floor-light",staticStyle:{"max-width":"344px"},attrs:{"disabled":invalid}},[_c('span',[_vm._v(_vm._s(_vm.$t('save')))])])])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }