<template>
    <div class="input-text">
        <div ref="inputText"
             :class="['input-text-input', focused ? 'focused' : '', isDirty ? 'is-dirty' : '', hasErrors ? 'has-errors' : '', data_readonly ? 'readonly' : '']">
            <input ref="input"
                   v-model="local_value"
                   @focus="onFocus"
                   @blur="onBlur"
                   @change="onChange($event.target.value)"
                   @input="onInput($event.target.value)"
                   class="form-control"
                   :type="type"
                   :readonly="data_readonly"/>
            <label>{{label}}</label>
            <!--<div v-if="hasErrors" class="icon-wrapper-24 icon">
                <i class="icon-aviso-erro"></i>
            </div>-->
        </div>
        <div class="error-message">{{data_error}}</div>
    </div>
</template>
<script>
    export default {
        props: {
            label: String,
            type: String,
            value: [Number, String],
            data_error: String,
            data_readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                focused: false,
                local_value: this.value
             //   isDirty: this.local_value != null && this.local_value.length > 0,           
             //   hasErrors: this.data_error != null && this.data_error.length > 0,               
            }
        },
        methods: {
            onBlur() {
                this.focused = false;
            },
            onFocus() {
                this.focused = true;
            },
            onChange(value) {
                               
                    this.$emit('change',value)
            },
            onInput(value) {
              
                this.$emit('input', value);
            }
        },
        computed: {

            isDirty: function () {
                if (this.type == "date") {
                    return true;
                }
                return (this.local_value && this.local_value !== "") || (this.local_value && !isNaN(this.local_value));
            },

            hasErrors: function () {
                return this.data_error != null && this.data_error.length > 0;
            }
        },
        watch: {
            value: function (val) {
               this.local_value = val;             
            },
            //data_error: function (val) {
            //    this.hasErrors = val != null && val.length > 0;
            //}
        },
        mounted() {
            var vm = this;
            vm.$root.$on('input-text-data-change', function (data, target) {
                if (target == vm.$refs.inputText) {
                    vm.value = data;
                }
            });

            if (this.type == "date") {
                this.isDirty = true;
            }

        }
    }
</script>