import { apiService } from '../services';
import VueJwtDecode from 'vue-jwt-decode';

import i18n from '../i18n'

const user = JSON.parse(localStorage.getItem('user'));


const initialState = user ?
    {
        status: { loggedIn: true },
        user,
        loginIsLoading: false,
        usercreate: { processing: false, success: false, message: "", result: false },
        userexits: { processing: false, success: false, message: "", userexists: false },
        emailvalidate: { processing: false, success: false, message: "", tokenvalid: false },
        requestCodeForChangePassword: { processing: false, success: false, message: "" },
        forgotPassword: { processing: false, resendProcessing: false, success: false, message: "" },
        requestCodeForDataDownload: { processing: false, success: false, message: "" },
        requestDataDownload: { processing: false, success: false, message: "" },
        requestCodeForDeleteAccount: { processing: false, success: false, message: "" },
        deleteAccount: { processing: false, success: false, message: "" },
        processingAccount: false,
        redirectTo: null
    }
    : {
        status: {},
        user: null,
        loginIsLoading: false,
        usercreate: { processing: false, success: false, message: "", result: false },
        userexits: { processing: false, success: false, message: "", userexists: false },
        emailvalidate: { processing: false, success: false, message: "", tokenvalid: false },
        forgotPassword: { processing: false, success: false, message: "" },
        requestCodeForDataDownload: { processing: false, success: false, message: "" },
        requestDataDownload: { processing: false, success: false, message: "" },
        requestCodeForDeleteAccount: { processing: false, success: false, message: "" },
        deleteAccount: { processing: false, success: false, message: "" },
        processingAccount: false,
        redirectTo: null
    };


export const authentication = {
    namespaced: true,
    state: initialState,
    getters: {
        getStateProcessingCreateAccount: state => {
            if (state.usercreate.processing || state.userexits.processing || state.emailvalidate.processing) {
                return true;
            }
            return false;
        },
        getUserName: state => {

            if (state.user) {

                return state.user.firstName + " " + state.user.lastName; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return {};
            }

        },
        getUserMobilePhone: state => {

            if (state.user) {

                return state.user.mobilePhone; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return {};
            }

        },
        getUserBirthdate: state => {

            if (state.user) {

                return state.user.birthdate; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return {};
            }

        },
        getUser: state => {
            if (state.user) {

                return state.user;
            }

        },
        isAuthenticated: state => {


            if (state.user == null) {

                return false;
            }

            /*
            var decoded = VueJwtDecode.decode(user.jwtToken);


            if (decoded.exp < Date.now() / 1000) {

                localStorage.removeItem('user');
               // commit('checkAuthFalse');

                return false;
            }
            */

            return true;

        },
        //userName: state => {
        //    return { fullname: state.user.firstname + " " + state.user.lastname }
        //}
    },
    actions: {
        login({ commit, dispatch }, user) {
            commit('loginRequest', user);
            return new Promise(resolve => {
                apiService.login(user)
                    .then(({ data }) => {
                        if (data.jwtToken) {
                            // store user details and jwt token in local storage to keep user logged in between page refreshes

                            localStorage.setItem('user', JSON.stringify(data));
                            commit('loginSuccess', data); 
                            //dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.login_success') }, { root: true });

                            dispatch('ui/showLogin', { show: false, redirectTo: null }, { root: true });

                        }
                        resolve(data);
                    }).catch(({ response }) => {
                        commit('loginFailure');
                        if (response) {
                            dispatch('alert/error', { title: '', description: i18n.t("error." + response.data.message) }, { root: true });
                        }
                        else {
                            dispatch('alert/error', { title: '', description: i18n.t("error.connection_login") }, { root: true });
                        }
                    });
            });
        },
        logout({ commit }) {
            apiService.logout();
            localStorage.removeItem('user');
            commit('logout');
        },

        check_auth({ commit }) {
          //  console.log("Check Auth User");
            let user = JSON.parse(localStorage.getItem('user'));
            if (!user) {
                commit('checkAuthFalse');
                return false;
            }
          //  console.log(user);
            var decoded = VueJwtDecode.decode(user.jwtToken);
            //console.log(decoded);
            if (decoded.exp < Date.now() / 1000) {
                localStorage.removeItem('user');
                commit('checkAuthFalse');
                return false;
            }
            return true;
        },
        accountExists({ commit, dispatch }, email) {

            commit('userExistsRequest');

            return new Promise(resolve => {
                apiService.accountExists(email)
                    .then(({ data }) => {
                        commit('userExistsSuccess', data);
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        commit('userExistsFailure', response.data.errors);
                        dispatch('alert/error', { title: '', description: i18n.t("error.generic") }, { root: true });
                    });
            });
        },

        accountCreate({ commit, dispatch }, user) {

            commit('createUserRequest');

            return new Promise(resolve => {
                apiService.accountCreate(user)
                    .then(({ data }) => {

                        commit('createUserSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        commit('createUserFailure', response.data.errors);
                        if (response.data.message == 'email_already_exists') {
                            dispatch('alert/error', { title: i18n.t("error.email_already_exists_title"), description: i18n.t("error." + response.data.message) }, { root: true });
                        }
                        else {
                            dispatch('alert/error', { title: '', description: i18n.t('error.generic') }, { root: true });
                        }

                    });
            });

        },

        accountVerifyEmail({ commit, dispatch }, token) {

            commit('accountVerifyEmailRequest');

          //  console.log("checking account exists");

            return new Promise(resolve => {
                apiService.accountVerifyEmail(token)
                    .then(({ data }) => {
                        commit('accountVerifyEmailSuccess', data);

                       // dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.email_valid') }, { root: true });

                        if (data.jwtToken) {
                            // store user details and jwt token in local storage to keep user logged in between page refreshes
                            localStorage.setItem('user', JSON.stringify(data));
                            commit('loginSuccess', data);
                          //  dispatch('orders/updateUserInOrder', data, { root: true });
                            //dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.login_success') }, { root: true });
                            dispatch('ui/showCreateAccount', false, { root: true });
                        }

                        resolve(data);

                    })
                    .catch(({ response }) => {


                        // todo: remove show notification from here this is state not ui
                        commit('accountVerifyEmailFailure', response.data.errors);
                        dispatch('alert/error', {
                            title:'',
                            description: i18n.t('error.generic')
                        },
                            { root: true }
                        );

                    });
            });


        },

        accountForgotPassword({ commit, dispatch }, params) {

            if (!params.resend) {
                commit('accountForgotPasswordRequest');
            } else {
                commit('accountForgotPasswordResendRequest');
            }

          //  console.log("checking account exists");

            return new Promise(resolve => {
                apiService.forgotPassword(params.email)
                    .then(({ data }) => {
                        commit('accountForgotPasswordSuccess', data);
                        dispatch('alert/success', { title: '', description: i18n.t('success.forgot_password_email') }, { root: true });
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        commit('accountForgotPasswordFailure', response.data.errors);
                        if (response.data.message == 'email_not_found') {
                            dispatch('alert/error', { title: '', description: i18n.t("error." + response.data.message) }, { root: true });
                        }
                        else {
                            dispatch('alert/error', { title: '', description: i18n.t('error.generic') }, { root: true });
                        }
                    });
            });


        },

        accountResetPasswordConfirm({ commit, dispatch }, data) {

            commit('resetPasswordConfirmRequest');

            //console.log("checking account exists");

            return new Promise(resolve => {
                apiService.resetPasswordConfirm(data)
                    .then(({ data }) => {
                        if (data.jwtToken) {
                            // store user details and jwt token in local storage to keep user logged in between page refreshes
                            localStorage.setItem('user', JSON.stringify(data));
                            commit('resetPasswordConfirmSuccess', data);
                            commit('loginSuccess', data);

                            // todo: remove notification from here
                            //dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.login_success') }, { root: true });
                        }

                        resolve(data);

                    })
                    .catch(({ response }) => {

                        commit('resetPasswordConfirmFailure', response.data.errors);
                        dispatch('alert/error', { title: i18n.t("error.an_error_occurred"), description: i18n.t("error." + response.data.message) }, { root: true });

                    });
            });
        },

        updatePassword({ commit, dispatch }, user) {

            return new Promise(resolve => {
                apiService.updatePassword(user)
                    .then(({ data }) => {
                        if (data.jwtToken) {
                            commit('updatePasswordSuccess', data);
                            localStorage.setItem('user', JSON.stringify(data));


                           // dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.change_password') }, { root: true });
                        }
                        resolve(data);
                    })
                    .catch(({ response }) => {
                      //  console.log(response.data.message);
                        commit('updatePasswordFailure', response.data.message);
                        dispatch('alert/error', { title: '', description: i18n.t('error.generic') }, { root: true });
                    });

            });


        },

        requestCodeForChangePassword({ commit, dispatch }, user) {
            commit('requestCodeForChangePasswordRequest');

            return new Promise(resolve => {
                apiService.requestCodeForChangePassword(user)
                    .then(({ data }) => {
                        if (data.jwtToken) {
                            commit('requestCodeForChangePasswordSuccess', data);

                            localStorage.setItem('user', JSON.stringify(data));

                            dispatch('alert/success', { title: '', description: i18n.t('success.code_sent') }, { root: true });
                        }
                        else {
                            commit('requestCodeForChangePasswordFailure', data.message);
                            dispatch('alert/error', { title: '', description: i18n.t("error.request_code_change_password.generic_error") }, { root: true });
                        }
                        resolve(data);

                    })
                    .catch(({ response }) => {
                        commit('requestCodeForChangePasswordFailure', response.data.message);
                        dispatch('alert/error', { title: '', description: i18n.t('error.generic') }, { root: true });
                    });
            });
        },

        requestCodeForDataDownload({ commit, dispatch }, user) {
            commit('requestCodeForDataDownloadRequest');

            return new Promise(resolve => {
                apiService.requestCodeForDataDownload(user)
                    .then(({ data }) => {
                        if (data.jwtToken) {
                            commit('requestCodeForDataDownloadSuccess', data);

                            localStorage.setItem('user', JSON.stringify(data));

                            dispatch('alert/success', { title: '', description: i18n.t('success.code_sent') }, { root: true });
                        }
                        else {
                            commit('requestCodeForDataDownloadFailure', data.message);
                            dispatch('alert/error', { title: i18n.t('error.request_code_download_data.generic_error'), description: i18n.t("error.not_autenticated") }, { root: true });

                        }
                        resolve(data);

                    })
                    .catch(({ response }) => {
                     //   console.log(response.data.message);
                        commit('requestCodeForDataDownloadFailure', response.data.message);
                        dispatch('alert/error', { title: '', description: i18n.t('error.generic') }, { root: true });
                    });
            });
        },

        requestDataDownload({ commit, dispatch }, model) {
            commit('requestDataDownloadRequest');

            return new Promise(resolve => {
                apiService.requestDataDownload(model)
                    .then(({ data }) => {
                        if (data.jwtToken) {
                            commit('requestDataDownloadSuccess', data);

                            localStorage.setItem('user', JSON.stringify(data));

                            //dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.request_download_data') }, { root: true });
                        }
                        else {
                            commit('requestDataDownloadFailure', data.message);
                            dispatch('alert/error', { title: '', description: i18n.t("error.not_autenticated") }, { root: true });

                        }

                        resolve(data);

                    })
                    .catch(({ response }) => {

                      //  console.log(response.data.message);

                        commit('requestDataDownloadFailure', response.data.message);

                        dispatch('alert/error', { title: '', description: i18n.t('error.generic') }, { root: true });
                    });
            });
        },

        requestCodeForDeleteAccount({ commit, dispatch }, user) {
            commit('requestCodeForDeleteAccountRequest');

            return new Promise(resolve => {
                apiService.requestCodeForDeleteAccount(user)
                    .then(({ data }) => {
                        if (data.jwtToken) {
                            commit('requestCodeForDeleteAccountSuccess', data);

                            localStorage.setItem('user', JSON.stringify(data));

                            dispatch('alert/success', { title: '', description: i18n.t('success.code_sent') }, { root: true });
                        }
                        else {
                            commit('requestCodeForDeleteAccountFailure', data.message);
                            dispatch('alert/error', { title: '', description: i18n.t("error.not_autenticated") }, { root: true });

                        }
                        resolve(data);

                    })
                    .catch(({ response }) => {
                      //  console.log(response.data.message);
                        commit('requestCodeForDeleteAccountFailure', response.data.message);
                        dispatch('alert/error', { title: '', description: i18n.t('error.generic') }, { root: true });
                    });
            });
        },
        deleteAccount({ commit, dispatch }, model) {
            commit('deleteAccountRequest');
            return new Promise(resolve => {
                apiService.deleteAccount(model)
                    .then(({ data }) => {
                        if (data.jwtToken) {
                            commit('deleteAccountSuccess', data);
                            localStorage.setItem('user', JSON.stringify(data));
                            dispatch('alert/success', { title: '', description: i18n.t("success.account_deleted") }, { root: true });
                        }
                        else {
                            commit('deleteAccountFailure', data.message);
                            dispatch('alert/error', { title: '', description: i18n.t("error.not_autenticated") }, { root: true });
                        }
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        commit('deleteAccountFailure', response.data.message);
                        dispatch('alert/error', { title: '', description: i18n.t('error.generic') }, { root: true });
                    });
            });
        },
    },
    mutations: {
        accountVerifyEmailRequest(state) {
            state.emailvalidate = { processing: true, success: false, message: "", tokenvalid: false };
            state.processingAccount = true;
        },
        accountVerifyEmailSuccess(state, res) {
            state.emailvalidate = { processing: false, success: true, message: res, tokenvalid: true };
            state.processingAccount = false;
        },
        accountVerifyEmailFailure(state, res) {
            state.emailvalidate = { processing: false, success: false, message: res, tokenvalid: false };
            state.processingAccount = false;
        },
        userExistsRequest(state) {
            state.processingAccount = true;
            state.userexists = { processing: true, success: false, message: "", userexists: false };
        },
        userExistsSuccess(state, res) {
            state.processingAccount = false;
            state.userexists = { processing: false, success: true, message: "", userexists: res };
        },
        userExistsFailure(state, res) {
            state.processingAccount = false;
            state.userexists = { processing: false, success: false, message: res, userexists: false };
        },



        createUserRequest(state) {
            state.usercreate = { processing: true, success: false, message: "", result: false };
            state.processingAccount = true;
        },
        createUserSuccess(state, res) {

            //console.log(res);
            state.usercreate = { processing: false, success: true, message: res, result: true };
            state.processingAccount = false;

        },
        createUserFailure(state, res) {
            state.usercreate = { processing: false, success: false, message: res, result: false };
            state.processingAccount = false;
        },


        accountForgotPasswordRequest(state) {
            state.forgotPassword = { processing: true, resendProcessing: false, success: false, message: "" };
        },
        accountForgotPasswordResendRequest(state) {
            state.forgotPassword = { processing: false, resendProcessing: true, success: false, message: "" };
        },
        accountForgotPasswordSuccess(state, res) {
            state.forgotPassword = { processing: false, resendProcessing: false, success: true, message: res };
        },
        accountForgotPasswordFailure(state, res) {
            state.forgotPassword = { processing: false, resendProcessing: false, success: false, message: res };
        },



        loginRequest(state, user) {
            state.status.loggingIn = true;// = { loggingIn: true };
            state.user = user;
            state.loginIsLoading = true;
        },
        loginSuccess(state, user) {
            // state.status = { loggedIn: true };
            state.status.loggedIn = true;
            state.status.loggingIn = false;
            state.user = user;
            state.loginIsLoading = false;

            // dispatch('orders/updateUserInOrder', user);           

        },
        loginFailure(state) {
            // state.status = {};
            state.status.loggedIn = false;
            state.status.loggingIn = false;
            state.user = null;
            state.loginIsLoading = false;
        },




        logout(state) {
            //state.status = {};
            state.status.loggedIn = false;
            state.status.loggingIn = false;

            state.user = null;
        },
        checkAuthSucess(state) {
            state.status.loggedIn = true;

        },
        checkAuthFalse(state) {
            //state.status = {};
            state.status.loggedIn = false;
            state.user = null;
        },
        /*
         * Update Password
         */
        updatePasswordRequest(state) {
            state.forgotPassword = { processing: true, success: false, message: "" };
        },
        updatePasswordSuccess(state, res) {
            state.user = res;
            state.forgotPassword = { processing: false, success: true, message: res };
        },
        updatePasswordFailure(state, res) {
            state.forgotPassword = { processing: false, success: false, message: res };
        },

        /*
         * Reset Password
         */
        resetPasswordConfirmRequest(state) {
            state.forgotPassword = { processing: true, success: false, message: "" };
        },
        resetPasswordConfirmSuccess(state, res) {
            state.forgotPassword = { processing: false, success: true, message: res };
        },
        resetPasswordConfirmFailure(state, res) {
            state.forgotPassword = { processing: false, success: false, message: res };
        },

        requestCodeForDataDownloadRequest(state) {
            state.requestCodeForDataDownload = { processing: true, success: false, message: "" };
        },
        requestCodeForDataDownloadSuccess(state, res) {
            state.requestCodeForDataDownload = { processing: false, success: true, message: res };
        },
        requestCodeForDataDownloadFailure(state, res) {
            state.requestCodeForDataDownload = { processing: false, success: false, message: res };
        },


        requestDataDownloadRequest(state) {
            state.requestDataDownload = { processing: true, success: false, message: "" };
        },
        requestDataDownloadSuccess(state, res) {
            state.requestDataDownload = { processing: false, success: true, message: res };
        },
        requestDataDownloadFailure(state, res) {
            state.requestDataDownload = { processing: false, success: false, message: res };
        },


        requestCodeForDeleteAccountRequest(state) {
            state.requestCodeForDeleteAccount = { processing: true, success: false, message: "" };
        },
        requestCodeForDeleteAccountSuccess(state, res) {
            state.requestCodeForDeleteAccount = { processing: false, success: true, message: res };
        },
        requestCodeForDeleteAccountFailure(state, res) {
            state.requestCodeForDeleteAccount = { processing: false, success: false, message: res };
        },


        deleteAccountRequest(state) {
            state.deleteAccount = { processing: true, success: false, message: "" };
        },
        deleteAccountSuccess(state, res) {
            state.deleteAccount = { processing: false, success: true, message: res };
        },
        deleteAccountFailure(state, res) {
            state.deleteAccount = { processing: false, success: false, message: res };
        },


        requestCodeForChangePasswordRequest(state) {
            state.requestCodeForChangePassword = { processing: true, success: false, message: "" };
        },
        requestCodeForChangePasswordSuccess(state, res) {
            state.requestCodeForChangePassword = { processing: false, success: true, message: res };
        },
        requestCodeForChangePasswordFailure(state, res) {
            state.requestCodeForChangePassword = { processing: false, success: false, message: res };
        },

    }
}
