//import config from 'config';

import { authHeader } from '../helpers';
import { noAuthHeader } from '../helpers';

import Config from '../helpers/config';

import axios from 'axios';
import { Date } from 'core-js';

//import Vue from 'vue';
//import Router from 'vue-router';


const API_URL_BASE_PORTAL = process.env.VUE_APP_SERVER_ADDRESS || 'http://localhost:5000/';

const API_URL_BASE2 = API_URL_BASE_PORTAL + 'brokerproxy/rest/wss/';




const API_URL_OFFER = API_URL_BASE2 + "productmetadata";
const API_URL_SEARCH_PRODUCT = API_URL_BASE2 + "search/product";
const API_URL_OFFER_DETAILS = API_URL_BASE2 + "product";



//const API_URL_CREATE_ORDER_DRAFT = API_URL_BASE_PORTAL + "order/create";




const API_URL_IMAGE_BROKER = API_URL_BASE_PORTAL + "brokerproxy/getimage";
const API_URL_DOWNLOAD_FILE_BROKER = API_URL_BASE_PORTAL + "brokerproxy/downloadFile";


//const API_URL_FILE = API_URL_BASE + "getfile/";
//const API_URL_ALLFILES = API_URL_BASE + "listallfiles/";
//const API_URL_DOCUMENTS = API_URL_BASE + "documents/";
//const API_URL_STREET = API_URL_BASE + "search/street";

const API_URL_ACCOUNT = API_URL_BASE2 + "account";




export const brokerService = {

    getProductsMetadata,
    getProducts,
    getProductDetail,

    createOrderDraft,
    confirmOrder,
    cancelOrder,

    createContactRequest,
    confirmContactRequest,


    getUserDetails,
    saveUserDetails,
    getOrders,
    getRequestDetails,
    getOrderHistory,
    getAccountHistory,

    getAlerts,
    getAlertDetails,


    getPageUrlCode,
    getCoverage,
    getPointCoverageInfo,

    getImage,
    downloadFile,

    getAddress,
    getProductAvailability,

    getPrivacyItems

};

async function makeRequest(config) {

    return axios(config).catch(error => {

      

        if (axios.isCancel(error)) {
            throw error;
        }


        if (error.response) {
            // client received an error response (5xx, 4xx)

            if (error.response.data.message == "client_session_id_expired") {

                localStorage.removeItem('user');

                location.reload();
            }
            if (error.response.data.type ) {
                return Promise.reject({ message: error.response.data.title, "type": error.response.data.type });
            }

            return Promise.reject({ message: error.response.data.title, "type": "error" });

        } else if (error.request) {
            // client never received a response, or request never left


            return Promise.reject({ message: "error_connect"  , "type": "error" });

        } else {
            // anything else
            return Promise.reject({ message: "generic_error", "type": "error" });
        }

         
        //if (error.data.type == "broker") {
        //    return Promise.reject("error-broker");
        //} else {

        //    return Promise.reject("error");
        //}


       // return Promise.reject(error.data);

     

    });

}

function getProductsMetadata() {

    var config = {
        method: 'get',
        mode: 'no-cors',
        redirect: 'follow',
        url: API_URL_OFFER + "/OfertaTelco?lang=PT&onlyfilterable=Yes&" + getTrackId(),
        headers: noAuthHeader(),
    };

    return makeRequest(config);

}


function getProducts(search) {

    var search_parameters = generateSearchUrlForProduct(search);

    var url = "";

    if (search_parameters) {

        url = API_URL_SEARCH_PRODUCT + "?" + search_parameters + "&" + getPageUrlCodeForStore(search.page) + "&" + getTrackId();
    } else {

        url = API_URL_SEARCH_PRODUCT + "?" + getPageUrlCodeForStore(search.page) + "&" + getTrackId();
    }



    var config = {
        method: 'get',
        redirect: 'follow',
        mode: 'no-cors',
        url: url,
        headers: noAuthHeader(),
    };

    return makeRequest(config);
}


function getProductDetail(productId) {
    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_OFFER_DETAILS + "/" + productId + "/details?" + getTrackId(),
        headers: noAuthHeader(),
    };

    //  return axios.get('/data/product_details.json');

    return makeRequest(config);
}

function generateSearchUrlForProduct(search) {


    var search_criteria = "";

    if (search && search.criteria.length > 0) {

        search_criteria = search_criteria.concat("criteria=");

        search.criteria.forEach(element => {

            if (element.type == "List" && element.value) {


                search_criteria = search_criteria.concat(element.name, "=", element.value, ",");


            } else if (element.type == "Numeric") {

                search_criteria = search_criteria.concat(element.name, ">", element.value[0], ",");
                search_criteria = search_criteria.concat(element.name, "<", element.value[1], ",");
            }

        });
        search_criteria = search_criteria.substring(0, search_criteria.length - 1);
    }

    if (search && search.sort.length > 0) {

        if (search_criteria.length > 0) {
            search_criteria = search_criteria.concat("&");
        }
        search_criteria = search_criteria.concat("sort=");

        search.sort.forEach(element => {

            search_criteria = search_criteria.concat(element.name, "%20", element.order, ",");

        });


    }

    //if (search && search.lat && search.lng ) {


    //    if (search_criteria.length > 0) {
    //        search_criteria = search_criteria.concat("&");
    //    }

    // //   search_criteria = search_criteria.concat("location=" + search.lat + "," + search.lng);


    //}

    if (search && search.addressid) {


        if (search_criteria.length > 0) {
            search_criteria = search_criteria.concat("&");
        }

        search_criteria = search_criteria.concat("addressid=" + search.addressid);


    }




    return search_criteria;
}

/*
 * Orders 
 */

function createOrderDraft(order, accountid, sessionid) {


    var config = {
        method: 'post',
        url: API_URL_ACCOUNT + "/" + accountid + "/neworder?" + getSessionParam(sessionid) + "&" + getTrackId(),
        headers: authHeader(),
        data: order,
        mode: 'no-cors',
        redirect: 'follow'
    };

    return makeRequest(config);

}


function createContactRequest(body, accountid, sessionid) {

    //var body = {
    //    order: {
    //        type: params.type,
    //        comments: params.comments
    //    }
    //};

    var config = {
        method: 'post',
        url: API_URL_ACCOUNT + "/" + accountid + "/neworder?" + getTrackId() + "&" + getSessionParam(sessionid),
        headers: authHeader(),
        data: body,
        mode: 'no-cors',
        redirect: 'follow'
    };

    return makeRequest(config);

}


function confirmContactRequest(params, orderid, accountid, sessionid) {



    var config = {
        method: 'put',
        url: API_URL_ACCOUNT + "/" + accountid + "/" + orderid + "/orderconfirmation" + "?action=confirm&" + getTrackId() + "&clientsessionid=" + sessionid,
        headers: authHeader(),
        data: params,
        mode: 'no-cors',
        redirect: 'follow'
    };

    return makeRequest(config);

}



function confirmOrder(params, orderid, accountid, sessionid, action) {


    //      url: API_URL_ACCOUNT + "/" + accountid +  "/neworder?" + getSessionParam(sessionid) +  "&"  +   getTrackId(),

    if (!action) {

        action = "confirm";
    }

    var config = {
        method: 'put',
        url: API_URL_ACCOUNT + "/" + accountid + "/" + orderid + "/orderconfirmation" + "?action=" + action + "&" + getSessionParam(sessionid) + "&" + getTrackId(),
        headers: authHeader(),
        data: params,
        mode: 'no-cors',
        redirect: 'follow'
    };

    return makeRequest(config);

}




function cancelOrder(params, orderid, accountid, sessionid) {


    //      url: API_URL_ACCOUNT + "/" + accountid +  "/neworder?" + getSessionParam(sessionid) +  "&"  +   getTrackId(),

    var config = {
        method: 'put',
        url: API_URL_ACCOUNT + "/" + accountid + "/" + orderid + "/orderconfirmation" + "?action=discard&" + getSessionParam(sessionid) + "&" + getTrackId(),
        headers: authHeader(),
        data: params,
        mode: 'no-cors',
        redirect: 'follow'
    };

    return makeRequest(config);

} 



/*
 * Profile
*/

function getUserDetails(userid, sessionid) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_ACCOUNT + "/" + userid + "/details?" + getTrackId() + "&clientsessionid=" + sessionid,
        headers: authHeader(),
    };

    //  return axios.get('/data/product_details.json');

    return makeRequest(config);
}

function saveUserDetails(user, userid, sessionid) {

    var config = {
        method: 'put',
        mode: 'no-cors',
        url: API_URL_ACCOUNT + "/" + userid + "/update?" + getTrackId() + "&clientsessionid=" + sessionid,
        headers: authHeader(),
        redirect: 'follow',
        data: user,
    };

    return makeRequest(config);
}


function getOrders(params) {

    //  var start = getStartForPagging(  params.page  )   ; 

    var filter = "";

    if (params.filter) {

        filter = "&criteria=type%3D" + params.filter;

    }


    //console.log(params);

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_ACCOUNT + "/" + params.userid + "/orders?" + getPageUrlCode(params.page) + "&" + getTrackId() + "&clientsessionid=" + params.sessionid + filter + "&sort=order_date desc",
        headers: authHeader(),
    };

    //  return axios.get('/data/product_details.json');

    return makeRequest(config);
}

function getRequestDetails(params) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_ACCOUNT + "/" + params.userid + "/order/" + params.id + "/details?" + getTrackId() + "&clientsessionid=" + params.sessionid,
        headers: authHeader(),
    };
    //  return axios.get('/data/product_details.json');
    return makeRequest(config);
}


function getOrderHistory(params) {

    //  userid, orderid, sessionid

    //   console.log("get Order History");

    // console.log(params);

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_ACCOUNT + "/" + params.userid + "/order/" + params.id + "/history?type=all&" + getPageUrlCode(params.page) + "&" + getTrackId() + "&clientsessionid=" + params.sessionid,
        headers: authHeader(),
    };
    //  return axios.get('/data/product_details.json');
    return makeRequest(config);
}

function getAccountHistory(params) {

    // userid, sessionid, page

    // var start = getStartForPagging(  params.page  )   ; 

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_ACCOUNT + "/" + params.userid + "/history?type=All&" + getPageUrlCode(params.page) + "&" + getTrackId() + "&clientsessionid=" + params.sessionid + "&sort=event_date%20desc",
        headers: authHeader(),
    };
    return makeRequest(config);
}


function getAlerts(params) {

    var start = getStartForPagging(params.page);


    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_ACCOUNT + "/" + params.userid + "/alerts?type=" + params.filter + "&startat=" + start + "&maxresult=" + Config.NUMBER_ITEMS_PAGE.toString() + "&" + getTrackId() + "&clientsessionid=" + params.sessionid + "&sort=send_date%20desc",
        headers: authHeader(),
    };

    //  return axios.get('/data/product_details.json');

    return makeRequest(config);
}
function getAlertDetails(params) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_ACCOUNT + "/" + params.userid + "/alert/" + params.id + "?" + getTrackId() + "&clientsessionid=" + params.sessionid,
        headers: authHeader(),
    };
    //  return axios.get('/data/product_details.json');
    return makeRequest(config);
}


/*
Image
*/

function getImage(image) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_IMAGE_BROKER + "/" + image,
        headers: authHeader(),
    };

    return makeRequest(config);
}
function downloadFile(file, downloadName) {

    if (downloadName) {

        window.location.href = API_URL_DOWNLOAD_FILE_BROKER + "/" + file + "/" + downloadName;
    } else {
        window.location.href = API_URL_DOWNLOAD_FILE_BROKER + "/" + file ;
    }
}

/*
 * Helpers
 */

function getStartForPagging(page) {

    return Number(page - 1) * Number(Config.NUMBER_ITEMS_PAGE);

}

function getTrackId() {

  //  console.log(Date.now().toString());

   // return "";
   return "trackid=" + Date.now().toString() + '_' + Math.random().toString(36).substr(2, 9);
}

function getSessionParam(value) {

    return "clientsessionid=" + value;
}

function getPageUrlCode(page) {

    if (!page) {

        page = 1;
    }


    var start = Number(Config.NUMBER_ITEMS_PAGE) * (Number(page) - 1);

    return "startat=" + start.toString() + "&maxresult=" + Config.NUMBER_ITEMS_PAGE.toString();

}

function getPageUrlCodeForStore(page) {

    if (!page) {

        page = 1;
    }

    var start = Number(Config.NUMBER_ITEMS_PAGE_STORE) * (Number(page) - 1);

    return "startat=" + start.toString() + "&maxresult=" + Config.NUMBER_ITEMS_PAGE_STORE.toString();
}



function getCoverage(boundaries, zoom, cancelToken) {

        //source.cancel('Operation canceled by the user.');

     

    if ((boundaries != null) &&
        (typeof (boundaries._northEast) != "undefined") && (typeof (boundaries._southWest) != "undefined")) {

        var config = {
            method: 'get',
            mode: 'no-cors',
            url: API_URL_BASE_PORTAL + "brokerproxy/coverage-map?northEastLatitude=" + boundaries._northEast.lat +
                "&northEastLongitude=" + boundaries._northEast.lng +
                "&southWestLatitude=" + boundaries._southWest.lat + "&southWestLongitude=" +
                boundaries._southWest.lng + "&zoom=" + zoom,
            headers: authHeader(),
            cancelToken: cancelToken
        };
        //source.cancel('Operation canceled by the user.');
        return makeRequest(config);
    }
}

function getPointCoverageInfo(point) {

    //console.log("request point coverage info: ", point);

    if ((point != null) &&
        (typeof (point.lat) != "undefined") && (typeof (point.lng) != "undefined")) {

        var config = {
            method: 'get',
            mode: 'no-cors',
            url: API_URL_BASE_PORTAL + "brokerproxy/get-point-coverage-info?lat=" + point.lat +
                "&lng=" + point.lng ,
            headers: authHeader()
        };
        //source.cancel('Operation canceled by the user.');
        return makeRequest(config);
    }

}


function getAddress(loc) {

    //console.log(loc);

    //var lat = 37.181381622692065;
    //var lng = -7.450275421142579;


    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_BASE2 + "search/homes?latitude=" + loc.lat + "&longitude=" + loc.lng + "&" + getTrackId(),
        headers: authHeader(),
    };

    return makeRequest(config);
}


function getProductAvailability(value) {

    var sesssionid = "";

    if (value.sessionid) {

        sesssionid = "&clientsessionid=" + value.sessionid  ;
        //"&clientsessionid=" + value.sessionid 
    }

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_BASE2 + "product/" + value.productId + "/availability?addressid=" + value.addressid + sesssionid + "&" + getTrackId(),
        headers: authHeader(),
    };

    return makeRequest(config);
}

function getPrivacyItems(lang, scope) {

    var config = {
        method: 'get',
      //  mode: 'no-cors',
        url: API_URL_BASE2 + "privacyitems?lang=" + lang.toUpperCase() + "&scope=" + scope + "&" + getTrackId(),
        headers: noAuthHeader(),
    };

    return makeRequest(config);
}