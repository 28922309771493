<template>
    <div id="app" :class="['app-content',!isSimpleLayout ? 'make-room-for-bottom-navigation' : '', roomForNavbar ? 'make-room-for-navbar' : '', noTransitions ? 'notransition' : '', !scrollStatus ? 'no-scroll' :'']">
        <notifications group="notification" position="top right" />

        <!--TODO uncomment this in next version-->
        <!--<button :class="['button-chat-fixed hide-in-mobile', buttonChatWithGreenFloor ? 'onGreenFloor' : '']" id="buttonChatFixed">
        <i class="icon-chat"></i>
        </button>-->
        <!--<keep-alive>-->


        <navbar></navbar>
        <!--</keep-alive>-->
        <b-container fluid class="page-content p-0 d-flex flex-column">
            <b-row no-gutters>
                <b-col cols="12">
                    <router-view  :key="$route.fullPath" />
                </b-col>
            </b-row>

             

            <b-row no-gutters class="flex-grow-1">
                <b-col cols="12" align-self="end">
                    <default-footer></default-footer>
                </b-col>
            </b-row>
        </b-container>
        <bottom-navigation v-if="!isSimpleLayout"></bottom-navigation>



        <keep-alive>
            <sidebar-menu></sidebar-menu>
        </keep-alive>
        <generic-modal :data_show="showLogin"
                       @closed="hideLogin"
                       :data_title="$t('login')"
                       :data_is_loading="forgotIsLoading">
            <account-login v-if="restartLogin" @login_sucess="login_sucess"></account-login>
        </generic-modal>
        <generic-modal :data_show="showCreateAccount"
                       @closed="hideCreateAccount"
                       :data_title="modalCreateAccountTitle"
                       :data_is_loading="createAccountIsLoading">
            <account-create v-if="restartCreateAccount"
                            @login_sucess="login_sucess"
                            @is-on-login-step="modalCreateAccountTitle = $t('login')"
                            @is-on-create-account-step="modalCreateAccountTitle = $t('create_account')">
            </account-create>
        </generic-modal>


        <!--<b-button v-b-modal.modal-contact>Launch demo modal</b-button>-->
        <!--content-class="modal-fullscreen"-->
        <!--<b-modal id="modal-contact-request" v-model="showContactRequest" hide-footer centered scrollable>-->
        <!--
            <button class="button-close close-modal-address" @click="showHideRequestContact(false)">
        <div class="icon-wrapper-16 icon">
            <i class="icon-close" aria-hidden="true"></i>
        </div>
    </button>
            -->
        <!--<b-row no-gutters class="mb-4">
        <b-col>
            <div class="text-bold-20">{{$t('coverage_page.button_request_contact_text')}}</div>
        </b-col>
    </b-row>-->
        <!--<b-container fluid>
            <b-row no-gutters>
                <b-col>

                    Test

                </b-col>
            </b-row>
        </b-container>
    </b-modal>-->
        <!--
       <request-contact-form @on-cancel-click="showHideRequestContact(false)"
                          @on-submit="showHideRequestContact(false)">
    </request-contact-form>

       -->
        <!--{{showContactRequest}}-->
     
    </div>
</template>
<script>

    import BottomNavigation from '@/components/BottomNavigation.vue'
    import Navbar from '@/components/Navbar.vue'
    import DefaultFooter from '@/components/DefaultFooter.vue'

    //import SidebarMenu from '@/components/SidebarMenu.vue'
    //import GenericModal from '@/components/GenericModal.vue'
   // import AccountCreate from '@/components/AccountCreate.vue'
   // import AccountLogin from '@/components/AccountLogin.vue'

  //import RequestContactForm from '@/components/RequestContactForm.vue'


    export default {
        name: 'app',
        components: {
            BottomNavigation,
            SidebarMenu: () => import('@/components/SidebarMenu.vue'),
            Navbar,
            DefaultFooter,
            GenericModal: () => import('@/components/GenericModal.vue'),
            //AccountCreate,
            //AccountLogin
            AccountCreate: () => import('@/components/AccountCreate.vue'),
            AccountLogin: () => import('@/components/AccountLogin.vue'),
          //  RequestContactForm: () => import('@/components/RequestContactForm.vue')

        },
        computed: {
            alert() {
                return this.$store.state.alert
            },
            roomForNavbar() {
                return this.$store.state.ui.roomForNavbar;
            },
            scrollStatus() {
                return this.$store.state.ui.scrollStatus;
            },
            showLogin() {
                return this.$store.state.ui.showLogin;
            },
            isSimpleLayout() {
                return this.$store.state.ui.isSimpleLayout;
            },
            showCreateAccount() {
                return this.$store.state.ui.showCreateAccount;
            },
            createAccountIsLoading() {
                return this.$store.state.authentication.processingAccount;
            },
            showContactRequest() {
                return this.$store.state.ui.showContactRequest;
            },            


            forgotIsLoading() {
                return this.$store.state.authentication.forgotPassword.processing;
            },

            IsAutenticated() {

                return this.$store.state.authentication.user != null;
            },

            currentUser() {

                if (this.IsAutenticated) {
                    return JSON.parse(JSON.stringify(this.$store.state.authentication.user));
                } else {
                    return null;
                }
            }
        },
        data() {
            return {
                buttonChatWithGreenFloor: false,
                noTransitions: true,
                modalCreateAccountTitle: this.$i18n.t('create_account'),
                restartLogin: true,
                restartCreateAccount: true,
                toast_visible: process.env.VUE_APP_TOAST_VISIBLE, 
                
            }
        },
        methods: {

            OnSubmitContactRequest: function () {

            },

            showHideRequestContact: function (value) {

                this.$store.dispatch('ui/showContactRequest', value);

            },
            login_sucess: function () {

              //  console.log(" Login success  ");

                this.forceRestartLogin();


                if (this.$store.state.ui.redirectTo) {

                  

                    if (this.$store.state.ui.query) {

                        this.$router.push({ path: this.$store.state.ui.redirectTo, query: this.$store.state.ui.query });

                    } else {

                        this.$router.push({ path: this.$store.state.ui.redirectTo });

                    }


                }

                //if (this.$route.query.returnurl) {
                //    this.$router.push({ path: this.$route.query.returnurl });
                //}  

            },

            handleScroll: function () {

                ////For chat button
                //var buttonChat = document.getElementById('buttonChatFixed');
                //var buttonChatPosition = buttonChat.getBoundingClientRect().top + window.scrollY;
                //var buttonChatHeight = buttonChat.clientHeight;

                //var coverageSection = document.getElementById('sectionSeeCoverage');
                //if (coverageSection) {
                //    var coverageSectionPosition = coverageSection.getBoundingClientRect().top + window.scrollY;
                //    var coverageSectionHeight = coverageSection.clientHeight;

                //    var topDecoratorNewsletter = document.getElementById('topDecoratorNewsletter');
                //    var topDecoratorNewsletterPosition = topDecoratorNewsletter.getBoundingClientRect().top + window.scrollY;
                //    var topDecoratorNewsletterHeight = topDecoratorNewsletter.clientHeight;

                //    if ((buttonChatPosition + buttonChatHeight >= coverageSectionPosition && buttonChatPosition + buttonChatHeight <= coverageSectionPosition + coverageSectionHeight)
                //        || (buttonChatPosition + buttonChatHeight >= topDecoratorNewsletterPosition && buttonChatPosition + buttonChatHeight <= topDecoratorNewsletterPosition + topDecoratorNewsletterHeight)) {
                //        this.buttonChatWithGreenFloor = true;
                //    }
                //    else {
                //        this.buttonChatWithGreenFloor = false;
                //    }
                //}

            },
            hideLogin() {
                this.forceRestartLogin();
                this.$store.commit('ui/setShowLogin', { show: false, redirectTo: null }, { root: true })
            },
            hideCreateAccount() {
                this.forceRestartCreateAccount();
                this.$store.commit('ui/setShowCreateAccount', false, { root: true })
            },
            hideContactRequest() {


                this.$store.dispatch('ui/showContactRequest', false, { root: true });

            },
            forceRestartLogin() {
                // Remove my-component from the DOM
                this.restartLogin = false;

                this.$nextTick(() => {
                    // Add the component back in
                    this.restartLogin = true;
                });
            },
            forceRestartCreateAccount() {
                // Remove my-component from the DOM
                this.restartCreateAccount = false;

                this.$nextTick(() => {
                    // Add the component back in
                    this.restartCreateAccount = true;
                });
            }
        },
        watch: {
            roomForNavbar: function () {
                this.noTransitions = false;
            },
        },
        created: function () {
            window.addEventListener('scroll', this.handleScroll);
            //this.noTransition = false
            var vm = this;
            this.unsubscribe = this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'alert/success') {
                    vm.$notify({
                        group: 'notification',
                        type: 'app-success',
                        closeOnClick: true,
                        duration: 3000,
                        title: state.alert.alertMessage.title,
                        text: state.alert.alertMessage.description
                    });
                }
                if (mutation.type === 'alert/error') {
                    vm.$notify({
                        group: 'notification',
                        type: 'app-error',
                        closeOnClick: true,
                        duration: this.toast_visible,
                        title: state.alert.alertMessage.title,
                        text: state.alert.alertMessage.description
                    });
                }
                if (mutation.type === 'alert/info') {
                    vm.$notify({
                        group: 'notification',
                        type: 'app-info',
                        closeOnClick: true,
                        duration: this.toast_visible,
                        title: state.alert.alertMessage.title,
                        text: state.alert.alertMessage.description
                    });
                }
                if (mutation.type === 'alert/warn') {
                    vm.$notify({
                        group: 'notification',
                        type: 'app-warn',
                        closeOnClick: true,
                        duration: this.toast_visible,
                        title: state.alert.alertMessage.title,
                        text: state.alert.alertMessage.description
                    });
                } if (mutation.type === 'alert/brokererror') {
                    vm.$notify({
                        group: 'notification',
                        type: 'brokererror',
                        closeOnClick: true,
                        duration: this.toast_visible,
                        title: state.alert.alertMessage.title,
                        text: state.alert.alertMessage.description
                    });
                }
                
                //if (mutation.type === 'alert/info') {
                //    vm.$notify({
                //        group: 'notification',
                //        type: 'info',
                //        closeOnClick: true,
                //        duration: -1,
                //        title: state.alert.alertMessage.title,
                //        text: state.alert.alertMessage.description
                //    });
                //}

            });

            /*Logout if token expired */
                this.$http.interceptors.response.use(undefined, function (err) {
                return new Promise(function (resolve, reject) {
                    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                        console.log(resolve);
                        console.log(reject);
                        this.$store.dispatch("authentication/logout");
                    }
                    throw err;
                });
            });
        },
        destroyed: function () {
            window.removeEventListener('scroll', this.handleScroll);
            this.unsubscribe();
        },
        mounted() {
            this.$store.dispatch('page/getPagelinks', { lang: "", section: "" });

            const appHeight = () => {
                const doc = document.documentElement
                doc.style.setProperty('--app-height', `${window.innerHeight}px`)
            }
            window.addEventListener('resize', appHeight)
            appHeight()
        }        
    }
</script>
<style>
    /* local styles */

    .modal-fullscreen {
       /* max-width: 100%;
        margin: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        display: flex;
        position: fixed;
        z-index: 1000000;*/
       z-index: 9000000;
    }
</style>