export default class News {
    constructor() {

        this.date = Date.now();
        this.title = "";
        this.html_content = "";
        this.locale = 'pt';
        this.image = "";        
    }
}
