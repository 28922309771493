<template>
    <b-navbar type="light" :class="['main-navbar', !showNavbar ? 'hide-navbar' : '', noTransitions ? 'notransition' : '']">
        <!--<div class="blur"></div>-->
        <b-navbar-nav v-if="!isSimpleLayout">
            <div class="sidebar-toggle-container">
                <button v-b-toggle.sidebar-1 class="button-icon-light">
                    <div class="icon-wrapper-24">
                        <i class="icon-menu" aria-hidden="true"></i>
                    </div>
                </button>
            </div>
        </b-navbar-nav>
        <router-link class="item-entry " :to="`/${$i18n.locale}`" v-if="!isSimpleLayout">
            <b-navbar-brand class="mr-3">
                <img height="48px" class="mt-1" src="../assets/images/logo.svg" />
            </b-navbar-brand>
        </router-link>
        <breadcrumb class="mt-1" :items="breadcrumbItems"></breadcrumb>
        <router-link class="go-back" :to="`/${$i18n.locale}/store`" v-if="$store.state.ui.isOrderPage && isSimpleLayout">
            <div class="icon-wrapper-24">
                <i class="icon-arrow-left"></i>
            </div>
            <span> {{$t($store.getters['ui/getGoBackText'])}}</span>
        </router-link>
        <!--<router-link class="go-back" :to="-1" v-if="!$store.state.ui.isOrderPage && isSimpleLayout">-->
        <a href="#" class="go-back" @click="$router.go(-1)" v-if="!$store.state.ui.isOrderPage && isSimpleLayout">
            <div class="icon-wrapper-24">
                <i class="icon-arrow-left"></i>
            </div>
            <span>  {{$t($store.getters['ui/getGoBackText'])}}  </span>
        </a>
        <!--</router-link>-->
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-nav v-if="!isSimpleLayout" class="ml-auto">
            <!--TODO uncomment this in next version-->
            <!--<li class="nav-item hide-in-mobile mr-2">
        <router-link to="" class="button-simple">
            <div class="icon-wrapper-24">
                <i class="icon-procurar" aria-hidden="true"></i>
            </div>
            <span class="text">{{$t('search')}}</span>
        </router-link>
    </li>-->

            <li v-if="$store.getters['authentication/isAuthenticated']" class="nav-item hide-in-mobile mr-2">
                <button @click="$router.push(`/` + $i18n.locale + `/profile`)" class="button-simple">
                    <div class="icon-wrapper-24">
                        <div class="alert-indicator"></div>
                        <i class="icon-user" aria-hidden="true"></i>
                    </div>
                    <span class="text">{{$t('my_account')}}</span>
                </button>
            </li>
            <li v-else class="nav-item hide-in-mobile mr-2">
                <button @click="showLogin" class="button-simple">
                    <div class="icon-wrapper-24">
                        <i class="icon-user" aria-hidden="true"></i>
                    </div>
                    <span class="text">{{$t('login')}}</span>
                </button>
            </li>


            <!--TODO uncomment this in next version-->
            <!--<li class="nav-item hide-in-mobile mr-2">
        <router-link to="" class="button-simple">
            <div class="icon-wrapper-24">
                <i class="icon-favorite" aria-hidden="true"></i>
            </div>
            <span class="text">{{$t('whishlist')}}</span>
        </router-link>
    </li>-->



            <!--TODO uncomment this in next version  -->
            <!--<dropdown-default :class="[isHomePage ? '' : 'hide-in-mobile']"
                      data_parent_dropdown="dropdownLanguage"
                      :data_options="langs"
                      :data_selected="$i18n.locale"
                      @input="changeLanguage"
                      :data_settings="{
                        minimumResultsForSearch: -1 ,
                        theme: 'language',
                        dropdownParent: '#dropdownLanguage'
                      }">
            </dropdown-default>
                -->


        </b-navbar-nav>
        <b-navbar-nav v-if="isSimpleLayout" class="logo-purchase-page">
            <b-navbar-brand href="/">
                <img height="48px" class="mt-1" src="../assets/images/logo.svg" />
            </b-navbar-brand>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto" v-if="isSimpleLayout && activeStep != null">
            <b-navbar-item right>
                <purchase-step-info></purchase-step-info>
            </b-navbar-item>
        </b-navbar-nav>

    </b-navbar>
</template>
<script>
    //import DropdownDefault from '@/components/DropdownDefault.vue';

    import Breadcrumb from '@/components/Breadcrumb.vue';
    import PurchaseStepInfo from '@/components/PurchaseStepInfo.vue';

    export default {
        name: 'navbar',
        props: {
        },
        components: {
            //DropdownDefault,
            Breadcrumb,
            PurchaseStepInfo
        },
        data() {
            return {
                showNavbar: true,
                lastScrollPosition: 0,
                noTransitions: true,
                //langs2: this.pushingLangs()
            }
        },
        methods: {

            goBack() {


            },

            changeLanguage(val) {

                var locale = val.id;

                if (this.$i18n.locale !== locale) {

                    this.$i18n.locale = locale;

                    this.$router.push({ name: "home", params: { lang: locale } });
                }


            },
            //pushingLangs() {
            //    var list = []
            //    //this.$i18n.availableLocales.forEach((value) => {
            //    //    list.push({ id: value, name: value, text: value });
            //    //});

            //    this.$i18n.locales.forEach((value) => {
            //        list.push(value);
            //    });

            //    return list ;
            //},
            handleScroll: function () {
                // Get the current scroll position
                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
                // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
                if (currentScrollPosition < 0) {
                    return
                }
                if (this.scrollStatus) {
                    var isScrollAtBottom = false;
                    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                        isScrollAtBottom = true;
                    }
                    // Here we determine whether we need to show or hide the navbar
                    this.showNavbar = (currentScrollPosition < this.lastScrollPosition) || currentScrollPosition <= 0;
                    if (isScrollAtBottom) {
                        this.showNavbar = false;
                    }
                    this.$store.commit('ui/setRoomForNavbar', this.showNavbar, { root: true })
                    this.$store.commit('ui/setDisplayNavbar', this.showNavbar, { root: true })
                }

                // Set the current scroll position as the last scroll position
                this.lastScrollPosition = currentScrollPosition
                this.noTransitions = false;
            },
            showLogin() {
                this.$store.commit('ui/setShowLogin', { show: true, redirectTo: null }, { root: true })
            },

        },
        computed: {
            scrollStatus() {
                return this.$store.state.ui.scrollStatus;
            },
            isSimpleLayout() {
                return this.$store.state.ui.isSimpleLayout;
            },
            isHomePage() {
                return this.$store.state.ui.isHomePage;
            },
            breadcrumbItems() {
                return this.$store.state.ui.breadcrumbItems;
            },
            langs() {
                var list = []
                this.$i18n.availableLocales.forEach((value) => {
                    list.push({ id: value, name: value, text: value });
                });
                return list;
            },
            activeStep() {
                return this.$store.state.ui.purchaseActiveStep;
            }

        },
        created: function () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed: function () {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>
