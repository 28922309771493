<template>
    <div>
        <router-view :key="pathid"
                  ></router-view>
    </div>
</template>
<script>
    export default {
        computed: {
            pathid() {


                return this.$route.fullPath + Date.now()
            }


        }
    }
</script>