import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { products } from './products.module';
import { orders } from './orders.module';
import { coverage } from './coverage.module';
import { privacy } from './privacy.module';

import { ui } from './ui.module';
import { news } from './news.module';
import { page } from './page.module';
import { faqs } from './faqs.module';

import { profile } from './profile.module';
import { backoffice } from './backoffice.module';
  

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        authentication,      
        products,
        ui,
        orders,
        news,
        page,
        faqs,
        profile,
        backoffice,
        coverage,
        privacy
    }
});
