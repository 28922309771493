<template>
    <div class="news no-text-transform">

        <b-container>

            <b-row no-gutters class="flex-grow-1" style="text-align: center;">
                <b-col>
                    <router-link :to="`/${$i18n.locale}/backoffice/news`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col align-self="center">
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('back')}}</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                </b-col>
            </b-row>

            <b-container>
                <b-row no-gutters class="mt-4">
                    <b-col></b-col>
                    <b-col cols="auto"><div class="big-text">{{newsDetail.title}}</div></b-col>

                    <b-col></b-col>
                </b-row>
                <b-row class="mt-3 img-placer">
                    <img class="img-display" :src="displayImage(newsDetail.image)" />
                </b-row>
                <b-row class="mt-3 mb-3">
                    <p v-html="$formatDate(newsDetail.date)"></p>
                </b-row>
                <b-row no-gutters class="mb-4">
                    <b-col></b-col>
                    <b-col cols="auto">
                        <div class="description" style="text-align:left;">
                            <span v-html="newsDetail.html_content"></span>
                        </div>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </b-container>


        </b-container>
    </div>
</template>

<script>

    export default {
        name: 'NewsDetails',

        data() {
            return {};
        },
        methods: {
              displayImage(image) {
                //if there is no default image set, use dummy
                var img = "https://dummyimage.com/600x350/000/fff";
                if (typeof image !== "undefined" && image !== null && image != "") {
                    var IMAGE_URL = process.env.VUE_APP_IMAGE_PATH; 
                    img = IMAGE_URL + image;
                }

                return img;
            }
        },
        created() {
        },
        components: {
        },

        computed: {
            newsDetail() {
                return this.$store.state.news.newsInfo;
            },

        },
        mounted() {

            this.$store.state.ui.showLogin = false;

            this.$store.state

            this.$store.dispatch('news/getNewsId', { id: this.$route.params.detailId, locale: this.$i18n.locale });

        }

    };
</script>