<template>
    <div class="h-100">
        <ValidationObserver v-slot="{ invalid }">
            <b-form name="form" @submit.prevent="handleSubmit" class="h-100">
                <b-container class="py-4 px-0 d-flex flex-column h-100">
                    <b-row no-gutters class="mb-4 px-4">
                        <b-col cols="auto" class="pr-4">
                            <div class="icon-wrapper-24 primary">
                                <i class="icon-download"></i>
                            </div>
                        </b-col>
                        <b-col>
                            <div class="text-regular-14">{{$t('profile_page.details.request_data_download_form_message')}}</div>
                        </b-col>
                    </b-row>
                    <b-row class="border-bottom-gray-200 mb-4 hide-in-desktop"></b-row>
                    <b-row class="mb-0 px-4">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-text v-model="code" label="código" :data_error="errors[0]"></input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-4 px-4">
                        <b-col>
                            <button @click="resendClick" class="link-primary">{{$t('resend_code')}}</button>
                            <span style="display: inline-block;">
                                <b-overlay :show="RequestCodeForDataDownloadProcessing" spinner-variant="primary" no-center spinner-small style="height: 18px;"></b-overlay>
                            </span>
                        </b-col>
                    </b-row>
                    <b-row no-gutters class="flex-grow-1 px-4">
                        <b-col align-self="end" style="text-align: center" class="hide-in-mobile">
                            <button type="button" @click="clickedOnCancel" class="button-alternative">
                                <span>{{$t('cancel')}}</span>
                            </button>
                        </b-col>
                        <b-col cols="auto" class="pl-4 hide-in-mobile"></b-col>
                        <b-col align-self="end" style="text-align: center">

                            <button-component :text="$t('profile_page.details.request_data_download_send_button')"
                                              :disabled="invalid"
                                              data_class="button-secondary floor-light"
                                              :processing="RequestDataDownloadProcessing"
                                              type="submit">
                            </button-component>
                        </b-col>
                    </b-row>
                </b-container>
            </b-form>
        </ValidationObserver>
    </div>
</template>
<script>

    import InputText from '@/components/InputText.vue'
    import ButtonComponent from '@/components/ButtonComponent.vue'

    export default {
        data() {
            return {
                code: ""
            };
        },
        components: {
            InputText,
            ButtonComponent
        },
        methods: {
            clickedOnCancel() {
                this.$emit('on-cancel-click');
            },
            resendClick() {
                this.$emit('on-resend-click');
            },
            handleSubmit() {
                this.$store.dispatch('authentication/requestDataDownload',
                    {
                        "UserId": this.CurrentUser.id,
                        "Email": this.CurrentUser.email,
                        "ClientSessionId": this.CurrentUser.sessionId,
                        "ValidationCode": this.code
                    }
                ).then(
                    () => {
                        this.$emit('saved');
                    }
                );
            }
        },
        computed: {
            CurrentUser() {
                return this.$store.state.authentication.user;
            },
            RequestCodeForDataDownloadProcessing() {
                return this.$store.state.authentication.requestCodeForDataDownload.processing;
            },
            RequestDataDownloadProcessing() {
                return this.$store.state.authentication.requestDataDownload.processing;
            }
        }


    };
</script>