import { brokerService } from '../services';
import _ from 'lodash';

import i18n from '../i18n'

export const privacy = {
    namespaced: true,
    state: {
        privacyItemsAccount: { processing: false, success: true, data: null },
        privacyItemsOrder: { processing: false, success: true, data: null }
    },
    getters: {

    },
    actions: {
        getPrivacyItemsAccount({ commit, dispatch }, lang) {
            commit('privacyItemsAccountRequest');
            return new  Promise( (resolve,reject) => {
                brokerService.getPrivacyItems(lang, "account")
                    .then(({ data }) => {
                        data.data.PrivacyItem = _.orderBy(_.uniqBy(data.data.PrivacyItem, 'position'), ['position'], ['asc']);
                        commit('privacyItemsAccountSuccess', data.data);
                        resolve(data);
                    })
                    .catch(error => {

                 //  console.log(error);

                        commit('privacyItemsAccountFailure', error);
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.generic') }, { root: true });
                        reject(error)
                    });
            });
        },
        getPrivacyItemsOrder({ commit, dispatch }, lang) {
            commit('privacyItemsOrderRequest');
            return new  Promise( (resolve,reject) => {
                brokerService.getPrivacyItems(lang, "order")
                    .then(({ data }) => {
                        data.data.PrivacyItem = _.orderBy(_.uniqBy(data.data.PrivacyItem, 'position'), ['position'], ['asc']);
                        commit('privacyItemsOrderSuccess', data.data);
                        resolve(data);
                    })
                    .catch( error => {
                        commit('privacyItemsOrderFailure', error);

                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });

                        reject(error)
                    });
            });
        }


    },
    mutations: {
        privacyItemsAccountRequest(state) {
           state.privacyItemsAccount = { processing: true, success: false, data: null }
        },
        privacyItemsAccountSuccess(state, data) {
           state.privacyItemsAccount = { processing: false, success: true, data: data }
        },
        privacyItemsAccountFailure(state, data) {
           state.privacyItemsAccount = { processing: false, success: false, data: data }
        },
        privacyItemsOrderRequest(state) {
            state.privacyItemsOrder = { processing: true, success: false, data: null }
        },
        privacyItemsOrderSuccess(state, data) {
            state.privacyItemsOrder = { processing: false, success: true, data: data }
        },
        privacyItemsOrderFailure(state, data) {
            state.privacyItemsOrder = { processing: false, success: false, data: data }
        }
    }
}
