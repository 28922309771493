<template>
    <b-modal ref="modalSimple" :id="data_id" modal-class="modal-simple" hide-footer hide-header :no-close-on-backdrop="data_no_close_on_backdrop">
        <div class="modal-inner-content">
            <button v-if="!data_no_close_button" class="close-button-modal" @click="hideModal">
                <div class="icon-wrapper-16">
                    <i class="icon-close"></i>
                </div>
            </button>
            <slot></slot>
        </div>
    </b-modal>
</template>
<script>
    export default {
        props: {
            data_id: String,
            data_no_close_on_backdrop: {
                type: Boolean,
                default: false
            },
            data_no_close_button: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
            }
        },
        methods: {
            hideModal() {
                this.$refs['modalSimple'].hide()
            },
        }
    }
</script>