//import config from 'config';
import { authHeader } from '../helpers';
import { noAuthHeader } from '../helpers';

import axios from 'axios';

const API_URL = process.env.VUE_APP_SERVER_ADDRESS || 'http://localhost:5000/'  ; 

const API_URL_ACCOUNTS = API_URL + 'api/accounts/';

const API_URL_NEWS = API_URL + 'api/news/';

const API_URL_PAGE = API_URL + 'api/page/';

const API_URL_FAQS = API_URL + 'api/faqs/';

export const apiService = {
    login,
    logout,
    updatePassword,
    requestCodeForChangePassword,
    forgotPassword,
    getProducts,
    getOffers,
    resetPasswordConfirm,
    requestCodeForDataDownload,
    requestDataDownload,
    requestCodeForDeleteAccount,
    deleteAccount,

    accountCreate,
    accountExists,
    accountVerifyEmail,

    getPageId,
    getPages,
    addPage,
    updatePage,
    deletePage,
    getPagelinks,


    getPageUrl,
    getPageKey,
    
   // getPagesByPage,
    getPageList,
  //  getPageListByPage,
 //   getPageMenuList,
 //   getPageFooterList,
    
    getNewsId,
    getNewsList,
 

    //getNewsList,
    //getNewsListByPage,
    //getNews,
    //getNewsByPage,

    addNews,
    updateNews,
    deleteNews,

    getFaqsList,
    getFaqsId,
    addFaqs,
    updateFaqs,
    deleteFaqs,

    SaveFile,
    getUsers,
    updateRole
};

async function makeRequest(config) {

    return axios(config).catch(error => {

        throw error;

    });    

}

function login(user_login) {

    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_ACCOUNTS + 'authenticate',
        headers: noAuthHeader(),
        data: user_login
    };

    return makeRequest(config);
}



function requestCodeForChangePassword(user) {
   
    var config = {
        method: 'post',
        url: API_URL_ACCOUNTS + "requestCodeForChangePassword", 
        data: user,
        headers: authHeader(),
        mode: 'no-cors',
       // withCredentials: true,
      //  redirect: 'follow',
      //  crossdomain: true
    };

    console.log("**************");
    console.log(config);

    return makeRequest(config);
}

function forgotPassword(email) {

    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_ACCOUNTS + 'forgot-password',
        headers: noAuthHeader(),
        data: {  email : email  }
    };

    return makeRequest(config);

}


function logout() {
    // remove user from local storage to log user out

}

async function getProducts() {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL + 'products',
        headers: noAuthHeader(),
    };

    return await   makeRequest(config);

}

function getOffers() {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL + 'boker',
        headers: noAuthHeader(),
    };

    return makeRequest(config);
}

function accountCreate(user) {

    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_ACCOUNTS + 'Register',
        headers: noAuthHeader(),
        data: user
    };

    return makeRequest(config);
}

function accountExists(email) {

    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_ACCOUNTS + 'accountexists',
        headers: noAuthHeader(),
        data: JSON.stringify(email)
    };

    return makeRequest(config);

}

function accountVerifyEmail(token) {

    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_ACCOUNTS + 'VerifyEmail',
        headers: noAuthHeader(),
        data: JSON.stringify(token)
    };

    return makeRequest(config);

}

 
function updatePassword(user) {

    // clientsessionid=" + sessionid 

    var config = {
        method: 'put',
        url: API_URL_ACCOUNTS + "updatepassword",
        headers: authHeader(),
        data: user,
       // withCredentials: true,
        //mode: 'no-cors',
        redirect: 'follow',
       crossdomain: true,
        //credentials: 'include'
        mode: 'no-cors'
    };

    return makeRequest(config);

}
function requestCodeForDataDownload(user) {

    var config = {
        method: 'post',
        url: API_URL_ACCOUNTS + "resquestCodeForDataDownload",
        data: user,
        headers: authHeader(),
        mode: 'no-cors'
    };
 

    return makeRequest(config);
}





function requestDataDownload(model) {

    var config = {
        method: 'post',
        url: API_URL_ACCOUNTS + "resquestDataDownload",
        data: model,
        headers: authHeader(),
       // withCredentials: true,
        redirect: 'follow',
        crossdomain: true
    };

    return makeRequest(config);
}

function requestCodeForDeleteAccount(user) {

    var config = {
        method: 'post',
        url: API_URL_ACCOUNTS + "requestCodeForDeleteAccount",
        data: user,
        headers: authHeader(),
     //   withCredentials: true,
        redirect: 'follow',
        crossdomain: true
    };

    return makeRequest(config);
}

function deleteAccount(model) {

    var config = {
        method: 'post',
        url: API_URL_ACCOUNTS + "deleteAccount",
        data: model,
        headers: authHeader(),
       // withCredentials: true,
        redirect: 'follow',
        crossdomain: true
    };

    return makeRequest(config);
}

function resetPasswordConfirm(data) {

    // clientsessionid=" + sessionid 

    var config = {
        method: 'post',
        url: API_URL_ACCOUNTS + "reset-password",
        headers: authHeader(),
        data: data,
     //   withCredentials: false,
        //  mode: 'no-cors',
        redirect: 'follow',
        crossdomain: true
        //   credentials: 'include'
    };

    return makeRequest(config);

}


function updateRole(userid,role) {

    var config = {
        method: 'put',
        url: API_URL_ACCOUNTS + "updaterole?userid=" + userid + "&role=" + role ,
        headers: authHeader(),
      //  data: user,
     //   withCredentials: true,
        //  mode: 'no-cors',
        redirect: 'follow',
        crossdomain: true    
    };
    return makeRequest(config);
}

function getUsers(filter) {

    

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_ACCOUNTS + 'GetAll?page= ' + filter.page  ,
        headers: authHeader(),
    };

    return makeRequest(config);
}


 

 
///////////////////////////////////////
///         Pages area               ///
///////////////////////////////////////

function getPageUrl(locale, url) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_PAGE + 'url?lang=' + locale + "&url=" + url,
        headers: noAuthHeader(),
        data: JSON.stringify(locale, url)
    };

    return makeRequest(config);

}

function getPageKey(locale, key) {


    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_PAGE + 'key?lang=' +  locale + "&key=" +  key,
        headers: noAuthHeader(),
     //  data: JSON.stringify(locale, key)
    };
    return makeRequest(config);
}

function getPageId(id) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_PAGE + 'get/' + id,
        headers: authHeader(),
        data: id
    };
    return makeRequest(config);
}


function getPageList(locale) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_PAGE + 'list?lang=' + locale.lang + "&page=" + locale.page  ,
        headers: authHeader(),
        data: JSON.stringify(locale)
    };

    return makeRequest(config);
}


//function getPageListByPage(locale, numPage) {

//    var config = {
//        method: 'get',
//        mode: 'no-cors',
//        url: API_URL + locale + '/pageslist/' + numPage,
//        headers: noAuthHeader(),
//        data: JSON.stringify(locale)
//    };    
//    return makeRequest(config);
//}

function getPages(locale) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL + locale + '/pages/',
        headers: noAuthHeader(),
        data: JSON.stringify(locale)
    };
    return makeRequest(config);
}


 
function getPagelinks(request) {

    var config = {
        method: 'get',
    //   mode: 'no-cors',
       url: API_URL_PAGE + 'links?lang=' + request.lang + "&section=" + request.section,
     //   url: API_URL_PAGE + 'links?' +  "&section=" + request.section,
        headers: noAuthHeader()
      //  data: JSON.stringify(locale)
    };
    return makeRequest(config);
}
 

 

function addPage(page) {

    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_PAGE + 'add',
        headers: authHeader(),
        data: JSON.stringify(page)
    };

    return makeRequest(config);

}

function updatePage(page) {

    var config = {
        method: 'put',
        mode: 'no-cors',
        url: API_URL_PAGE + 'update',
        headers: authHeader(),
        data: JSON.stringify(page)
    }; 

    
    return makeRequest(config);

}

function deletePage(id) {

    var config = {
        method: 'delete',
        mode: 'no-cors',
        url: API_URL_PAGE + 'delete/' +  id,
        headers: authHeader(),
    };

    return makeRequest(config);

}

///////////////////////////////////////
///         News area               ///
///////////////////////////////////////




function getNewsList(locale, numPage, hideFutureNews) {
    

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_NEWS + "list?lang=" + locale + "&page=" + numPage + "&hide=" + hideFutureNews ,
        headers: authHeader(),
    };

    return makeRequest(config);

}



function getNewsId( id) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_NEWS + 'getbyid/' + id,
        headers: authHeader(),
        //data: JSON.stringify(id)
    };

    return makeRequest(config);
}

function addNews(news) {

 
    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_NEWS + 'add',
        headers: authHeader(),
        data: JSON.stringify(news)
    };

    return makeRequest(config);

}


function updateNews(news) {

    var config = {
        method: 'put',
        mode: 'no-cors',
        url: API_URL_NEWS +  "update",
        headers: authHeader(),
        data: JSON.stringify(news)
    };

    return makeRequest(config);
}




function deleteNews( id) {

    var config = {
        method: 'delete',
        mode: 'no-cors',
        url: API_URL_NEWS + 'delete/' + id,
        headers: authHeader(),
    };
       
    return makeRequest(config);

}


function SaveFile( formData) {

     


    var header = {

        "Accept": "*/*",     
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type',
        'Sec-Fetch-Mode': 'no-cors',
        'Content-Type': 'multipart/form-data'
    };

    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_NEWS + 'uploadfile',
        headers: header,
        data: formData
    };

    return makeRequest(config);
}


///////////////////////////////////////
///         FAQs area               ///
///////////////////////////////////////




function getFaqsList(locale, numPage) {


    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_FAQS + "list?lang=" + locale + "&page=" + numPage,
        headers: authHeader(),
    };

    return makeRequest(config);

}



function getFaqsId(id) {

    var config = {
        method: 'get',
        mode: 'no-cors',
        url: API_URL_FAQS + 'getbyid/' + id,
        headers: authHeader(),
        //data: JSON.stringify(id)
    };

    return makeRequest(config);
}

function addFaqs(faqs) {

   

    var config = {
        method: 'post',
        mode: 'no-cors',
        url: API_URL_FAQS + 'add',
        headers: authHeader(),
        data: JSON.stringify(faqs)
    };

    return makeRequest(config);

}


function updateFaqs(faqs) {

   

    var config = {
        method: 'put',
        mode: 'no-cors',
        url: API_URL_FAQS + "update",
        headers: authHeader(),
        data: JSON.stringify(faqs)
    };

    return makeRequest(config);
}




function deleteFaqs(id) {

    var config = {
        method: 'delete',
        mode: 'no-cors',
        url: API_URL_FAQS + 'delete/' + id,
        headers: authHeader(),
    };

    return makeRequest(config);

}

