<template>
    <div class="purchase-step-info">
        <div class="items-container">
            <div :class="['item', activeStep == 1 ? 'active' : '']">
                <span v-if="activeStep != 1" class="number">1</span>
                <span v-else class="active-text">
                    {{$t('purchase_step_info.1')}}
                </span>
            </div>
            <div class="separator"></div>
            <div :class="['item', activeStep == 2 ? 'active' : '']">
                <span  v-if="activeStep != 2" class="number">2</span>
                <span v-else class="active-text">{{$t('purchase_step_info.2')}}</span>
            </div>
            <div class="separator"></div>
            <div :class="['item', activeStep == 3 ? 'active' : '']">
                <span  v-if="activeStep != 3" class="number">3</span>
                <span v-else class="active-text">{{$t('purchase_step_info.3')}}</span>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {

        },
        data() {
            return {
            }
        },
        methods: {

        },
        computed: {
            activeStep() {
                return this.$store.state.ui.purchaseActiveStep;
            }
        }
    }
    </script>