<template>
    <div class="profile profile-menu-page">
        <b-container class="px-0 py-4">
            <b-row no-gutters class="mb-2">
                <b-col class="hide-in-desktop"></b-col>
                <b-col cols="auto">
                    <div class="text-bold-20">
                        {{$t('profile_page.welcome_user')}}
                    </div>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row no-gutters>
                <b-col class="hide-in-desktop"></b-col>
                <b-col cols="auto">
                    <div class="description description-profile" style="max-width:188px">
                        {{$t('profile_page.description')}}
                    </div>
                </b-col>
                <b-col>
                </b-col>
            </b-row>
        </b-container>
        <profile-menu></profile-menu>
    </div>
</template>
<script>
    import ProfileMenu from '@/components/ProfileMenu.vue'

    export default {
        components: {
            ProfileMenu
        },
        data() {
            return {
            };
        },
        methods: {

        },
        computed: {



        },
        mounted() {

        }

    };
</script>