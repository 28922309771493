<template>
    <div class="profile-requests">
        <b-container class="px-0 pt-4">
            <b-row no-gutters class="mb-2">
                <b-col class="hide-in-desktop"></b-col>
                <b-col cols="auto"><div class="text-bold-20">{{$t('profile_page.warnings.title')}}</div></b-col>
                <b-col></b-col>
            </b-row>
            <b-row no-gutters class="mb-4">
                <b-col class="hide-in-desktop"></b-col>
                <b-col cols="auto"><div class="description description-profile">{{$t('profile_page.warnings.description')}}</div></b-col>
                <b-col></b-col>
            </b-row>
            <b-row no-gutters class="mb-2">
                <b-col></b-col>
                <b-col cols="auto">
                    <div style="width: 250px">
                        <dropdown-default data_parent_dropdown="dropdownFilterProfileWarnings"
                                          :data_options="filterOptions"
                                          :data_selected="list_params.filter"
                                          @input="filterChange"
                                          :data_settings="{
                                                 minimumResultsForSearch: -1 ,
                                                 theme: 'filter',
                                                 dropdownParent: '#dropdownFilterProfileWarnings'}">
                        </dropdown-default>
                    </div>
                </b-col>
            </b-row>
            <b-row no-gutters>
                <b-col>
                    <div class="profile-block">
                        <div class="header bg-secondary">
                            <b-container class="py-2 px-4">
                                <b-row>
                                    <b-col>
                                        <div class="title text-semibold-14 mt-1">
                                            <span v-if="list_params.filter == 'all'">
                                                {{$t('profile_page.warnings.title_block_all')}}
                                            </span>
                                            <span v-if="list_params.filter == 'unread'">
                                                {{$t('profile_page.warnings.title_block_not_read')}}
                                            </span>
                                        </div>
                                    </b-col>
                                    <b-col cols="auto"  v-if="Alerts.nresults > 0"  >
                                        <div class="alert-count-indicator">
                                            <div class="text">{{Alerts.nresults}} </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>


                        <div class="body">
                            <div v-if="!Processing">
                                <div v-if="hasAlerts">
                                    <b-container class="p-0">
                                        <b-row v-for="a in Alerts.alerts" :key="a.alert" no-gutters class="border-bottom-gray-200 item-clickable" @click="goToDetails(a.alert)">
                                            <b-col>
                                                <b-container class="p-4">
                                                    <b-row no-gutters>
                                                        <b-col>
                                                            <b-container class="p-0">
                                                                <b-row no-gutters>
                                                                    <b-col>
                                                                        <div class="text-semibold-12">

                                                                            <span v-html="$formatRelative(a.send_date)">   </span> &nbsp;
                                                                            <span v-if="a.status == 'unread'" class="m-0 p-0 h1"  style=" color: #6666ff">.</span>
                                                                        </div>
                                                                        <div class="text-regular-14">{{a.alert_title}}</div>
                                                                    </b-col>
                                                                </b-row>

                                                            </b-container>
                                                        </b-col>
                                                        <b-col cols="auto">
                                                            <b-container class="p-0 h-100">
                                                                <b-row no-gutters align-v="center" class="h-100">
                                                                    <b-col>
                                                                        <div class="icon-wrapper-24 primary">
                                                                            <i class="icon-right"></i>
                                                                        </div>
                                                                    </b-col>
                                                                </b-row>
                                                            </b-container>
                                                        </b-col>
                                                    </b-row>
                                                </b-container>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                    <b-container class="p-4">
                                        <b-row no-gutter>
                                            <b-col></b-col>
                                            <b-col cols="auto">
                                                <pager-default :data_page="list_params.page" :data_page_count="TotalPages" @on-page-change="pageChange"></pager-default>
                                            </b-col>
                                            <b-col></b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                                <b-container v-else class="p-4">
                                    <b-row no-gutters>
                                        <b-col class="hide-in-mobile"></b-col>
                                        <b-col md="6" sm="12">
                                          

                                            <span v-if="this.$store.state.profile.get_alerts_error == false">
                                                <no-content :text="$t('profile_page.warnings.no_content')"></no-content>
                                            </span>
                                            <span v-if="this.$store.state.profile.get_alerts_error == true">
                                                <no-content :text="$t('error.error_get_list')"></no-content>
                                            </span>


                                        </b-col>
                                        <b-col class="hide-in-mobile"></b-col>
                                    </b-row>
                                </b-container>
                            </div>
                            <div v-else>
                                <b-overlay :show="Processing" spinner-variant="primary" style="height: 200px;"></b-overlay>
                            </div>
                        </div>


                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    import DropdownDefault from '@/components/DropdownDefault.vue';
    import NoContent from '@/components/NoContent.vue';
    import Config from '../../helpers/config';
    import PagerDefault from '@/components/PagerDefault.vue'

    import ListParams from '../../models/list_params';

    export default {
        components: {
            DropdownDefault,
            NoContent,
            PagerDefault
        },
        data() {
            return {
                filterOptions: [
                    { id: 'all', name: 'all', text: this.$i18n.t('profile_page.warnings.title_block_all') },
                    { id: 'unread', name: 'unread', text: this.$i18n.t('profile_page.warnings.title_block_not_read') }
                ],
                list_params: new ListParams("",
                    this.$store.state.authentication.user.id,
                    this.$store.state.authentication.user.sessionId,
                    1),
            };
        },
        methods: {
            goToDetails(id) {

                this.$router.push('/' + this.$i18n.locale + '/profile/warning-detail/' + id);

            },
            selectedFilter(val) {
                this.filterSelected = val.id
            },
            filterChange(event) {

                this.list_params.filter = event.id;

                this.$store.dispatch('profile/getAlerts',
                    this.list_params,
                    { root: true }
                );


            },
            pageChange(event) {

                this.list_params.page = event;

                this.$store.dispatch('profile/getAlerts',
                    this.list_params,
                    { root: true }
                );
            },
        },
        computed: {

            Alerts() {
                 
                return this.$store.state.profile.alerts;
             
            },
            Processing() {
                return this.$store.state.profile.operation.processing;
            },
            hasAlerts() {
                if (this.Alerts.alerts != null && this.Alerts.alerts != 'undefined' && this.Alerts.alerts.length > 0) {
                    return true;
                }
                return false;
            },
            User() {
                return this.$store.state.authentication.user;
            },
            TotalPages() {

                return Math.ceil(Number(this.Alerts.nresults) / Number(Config.NUMBER_ITEMS_PAGE));
            }
        },
        mounted() {
            var breadcrumbitems = [
                { text: this.$i18n.t('profile_page.my_account'), url: '/' + this.$i18n.locale + '/profile', urlMobile: '/' + this.$i18n.locale + '/profile/profile-menu' },
                { text: this.$i18n.t('profile_page.warnings.title'), url: '' }
            ]
            this.$store.dispatch('ui/setBreadcrumbItems', breadcrumbitems, { root: true });

            this.list_params.filter = "unread";

            this.$store.dispatch('profile/getAlerts',
                this.list_params,
                { root: true }
            );
        },
        destroyed: function () {
            this.$store.dispatch('ui/setBreadcrumbItems', [], { root: true });
        }

    };
</script>