<template>
    <div class="purchase-step-4 purchase-page">

        <ValidationObserver v-slot="{ invalid }">

            <b-container v-if="!showRequestContactForm" fluid class="p-0 content">
                <div class="hide-in-desktop">
                    <product-card-small></product-card-small>
                </div>
                <b-overlay v-if="$store.state.orders.getUserDetailsProcessing" :show="$store.state.orders.getUserDetailsProcessing" spinner-variant="primary" class="m-5"></b-overlay>
                <b-row no-gutters v-if="!$store.state.orders.getUserDetailsProcessing">
                    <b-col md="7" sm="12" class="column-left border-right-gray-200">
                        <div class="text-bold-20 px-4 mt-4 mb-4">{{$t('purchase_step_3_page.column_left_title')}}</div>

                        <div class="description px-4 mb-4">{{$t('purchase_step_3_page.column_left_description')}}</div>

                        <b-container fluid class="px-4">
                            <b-row>
                                <b-col class="mb-3">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input-text v-model="AccountableEdit.full_name"
                                                    type="text"
                                                    :label="$t('fullname')"
                                                    :data_error="errors[0]">
                                        </input-text>
                                    </validation-provider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="mb-3">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input-text v-model="AccountableEdit.address"
                                                    type="text"
                                                    :label="$t('address')"
                                                    :data_error="errors[0]">
                                        </input-text>
                                    </validation-provider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="6" sm="12" class="mb-3">
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input-text v-model="AccountableEdit.nif"
                                                    type="text"
                                                    :label="$t('nif')"
                                                    :data_error="errors[0]"></input-text>

                                    </validation-provider>
                                </b-col>
                                <b-col md="6" sm="12" class="mb-3">
                                    <!--TODO apply phone rule on vee validate-->
                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input-text v-model="AccountableEdit.contact"
                                                    type="text" :label="$t('phone')"
                                                    :data_error="errors[0]">
                                        </input-text>
                                    </validation-provider>
                                </b-col>
                            </b-row>


                            <b-row>
                                <b-col md="6" sm="12" class="mb-3">
                                    <validation-provider rules="required">
                                        <!--<input-text v-model="AccountableEdit.doctype"
                                        type="text"
                                        :label="$t('doctype')"
                                        :data_error="errors[0]"></input-text>-->
                                        <!--<dropdown-default data_parent_dropdown="dropdownBackofficeNewsListLang14"
                                                          :data_options="DocTypes"
                                                          :data_label="$t('doctype')"
                                                          :data_error="errors[0]"
                                                          @input="user.doctype = $event.name"
                                                          :data_settings="{
                                                                           minimumResultsForSearch: -1 ,
                                                                           theme: 'simple'   ,
                                                                           dropdownParent: '#dropdownBackofficeNewsListLang14' }">
                                        </dropdown-default>-->




                                        <dropdown-default data_parent_dropdown="dropdownBackofficeNewsListLang14"
                                                          :data_options="DocTypes"
                                                          :data_label="$t('doctype')"
                                                          @input="updateDocType"
                                                          :data_selected="AccountableEdit.doctype"
                                                          :data_settings="{
                                                   minimumResultsForSearch: -1 ,
                                                   theme: 'simple'   ,
                                                   dropdownParent: '#dropdownBackofficeNewsListLang14' }">
                                        </dropdown-default>



                                    </validation-provider>
                                </b-col>
                                <b-col md="6" sm="12" class="mb-3">

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input-text v-model="AccountableEdit.docnumber"
                                                    type="text" :label="$t('docnumber')"
                                                    :data_error="errors[0]">
                                        </input-text>
                                    </validation-provider>
                                </b-col>
                            </b-row>


                            <b-row>
                                <b-col md="12" sm="12" class="mb-3">

                                    <validation-provider rules="required" v-slot="{ errors }">
                                        <input-multiple-block data_name="availability"
                                                              :data_label="$t('availability_for_contact')"
                                                              :data_options="availability"
                                                              :options_key_value="true"
                                                              data_item_value="value"
                                                              data_item_text="text"
                                                              @input="changeAvailability"
                                                              :value="selectedAvailabity"
                                                              :data_error="errors[0]"
                                                              :data_has_clean="false">
                                        </input-multiple-block>
                                    </validation-provider>


                                </b-col>
                            </b-row>
                        </b-container>
                        <b-container class="px-4 py-0">
                            <!--<b-row no-gutters class="mb-3 border-top-gray-200 pt-4">
                                <b-col>
                                    <div class="text-semibold-14">{{$t('purchase_step_3_page.contract_information')}}</div>
                                </b-col>
                            </b-row>
                            <b-row no-gutters class="mb-4">
                                <b-col>
                                    <div class="text-regular-14">
                                        {{$t('purchase_step_3_page.contract_information_description')}}
                                    </div>
                                </b-col>
                            </b-row>-->
                            <!--OfferFiles-->

                            <b-row no-gutters v-for="(o,index) in Documents" :key="o.fileid" :class="[Documents.length == index + 1 ? '' : 'mb-5', 'border-top-gray-200 pt-4']">
                                <b-col>
                                    <b-container class="p-0" fluid>
                                        <b-row no-gutters class="">
                                            <b-col>
                                                <div class="text-semibold-14">{{o.name}}</div>
                                            </b-col>
                                        </b-row>
                                        <!--<b-row no-gutters>
                                            <b-col></b-col>
                                            <b-col cols="auto">
                                                <a class="download-button" @click="DownloadFile(o.fileid)">
                                                    <span class="mr-2">{{$t('download')}}</span>
                                                    <div class="icon-wrapper-24 icon">
                                                        <i class="icon-download" aria-hidden="true"></i>
                                                    </div>
                                                </a>
                                            </b-col>
                                        </b-row>-->
                                        <b-row no-gutters>
                                            <b-col cols="1">

                                                <input v-if="o.mustagree =='Yes'" type="checkbox" id="o.fileid"
                                                       v-model="o.selected"
                                                       true-value="true"
                                                       false-value="false">



                                                <div v-if="$route.query && $route.query.debug">
                                                    <!--<b-form-checkbox name="o.name" class="default-checkbox" id="o.fileid"
                                                                     v-model="o.selected">
                                                        test
                                                    </b-form-checkbox>-->
                                                    {{o}}
                                                </div>


                                                <!--</b-form-checkbox>-->
                                            </b-col>
                                            <b-col>
                                                <div class="description">
                                                    {{$t('purchase_step_3_page.i_read_and_accept')}}
                                                </div>
                                            </b-col>
                                            <b-col cols="auto">
                                                <a class="download-button" @click="DownloadFile(o.fileid)">
                                                    <span class="mr-2">{{$t('download')}}</span>
                                                    <div class="icon-wrapper-24 icon">
                                                        <i class="icon-download" aria-hidden="true"></i>
                                                    </div>
                                                </a>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </b-col>
                            </b-row>
                        </b-container>

                    </b-col>
                    <b-col md="5" sm="12">
                        <b-container fluid class="p-0 ">
                            <!--hide-in-mobile-->
                            <b-row no-gutters class="mb-0 mb-md-4">
                                <b-col class="mt-4">
                                    <div class="text-bold-20 px-4 mb-4">{{$t('purchase_step_3_page.column_right_title')}}</div>

                                    <div class="description px-4">{{$t('purchase_step_3_page.column_right_description')}}</div>
                                </b-col>
                            </b-row>


                            <b-row no-gutters class="p-4 pl-md-4 p-md-0 ">
                                <b-col>
                                    <b-container fluid class="address-container border-gray-200 p-3">
                                        <b-row no-gutters class="mb-3">
                                            <b-col>
                                                <div class="text-semibold-12">{{$t('fullname')}}</div>
                                                <div class="text-regular-14"> {{$store.getters['orders/getAccountName']}}</div>
                                            </b-col>
                                        </b-row>
                                        <b-row no-gutters class="mb-3">
                                            <b-col>
                                                <div class="text-semibold-12">{{$t('phone')}}</div>
                                                <div class="text-regular-14"> {{$store.getters['orders/getAccountContact']}}</div>
                                            </b-col>
                                        </b-row>
                                        <b-row no-gutters class="mb-3">
                                            <b-col>
                                                <div class="text-semibold-12">{{$t('date_of_birth')}}</div>
                                                <div class="text-regular-14"> {{$store.getters['orders/getAccountBirthdate']}}</div>
                                            </b-col>
                                        </b-row>
                                        <b-row no-gutters class="mb-3">
                                            <b-col>
                                                <div class="text-semibold-12">{{$t('genre.title')}}</div>
                                                <div class="text-regular-14">{{ $t('genre.' + $store.getters['orders/getAccountGender']) }}</div>
                                            </b-col>
                                        </b-row>
                                        <b-row no-gutters>
                                            <b-col>
                                                <div class="text-semibold-12">{{$t('email')}}</div>
                                                <div class="text-regular-14"> {{$store.getters['orders/getAccountEmail']}}</div>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </b-col>
                            </b-row>




                            <div class="hide-in-mobile">
                                <product-card-small></product-card-small>
                            </div>






                            <b-row no-gutters class="p-4">
                                <b-col>
                                    <button-component :text="$t('purchase_step_3_page.text_button_confirm')"
                                                      data_class="button-secondary floor-light"
                                                      :processing="$store.state.orders.confirmOrderProcessing"
                                                      @on-click="SendConfirmationOrder"
                                                      :has-go="true"
                                                      :disabled="!DocumentosSelected || invalid"
                                                      type="button">
                                    </button-component>
                                </b-col>

                                <!--TODO include next version-->
                                <!--<b-col cols="auto" class="pl-3 hide-in-desktop">
                                    <button class="button-chat">
                                        <div class="icon-wrapper-24 icon">
                                            <i class="icon-chat"></i>
                                        </div>
                                    </button>
                                </b-col>-->
                            </b-row>



                            <b-row no-gutters class="px-4 mb-4">
                                <b-col>
                                    <!--<button class="button-alternative  floor-light" @click="ShowContactRequest">
                                        <span>{{$t('request_contact')}}</span>
                                    </button>-->

                                    <button-component :text="$t('request_contact')"
                                                      data_class="button-alternative floor-light"
                                                      :processing="$store.state.orders.confirmOrderProcessing"
                                                      @on-click="ShowContactRequest"
                                                      :has-go="true"
                                                      type="button">
                                    </button-component>


                                </b-col>
                            </b-row>


                            <b-row no-gutters class="px-4 mb-4">
                                <b-col>
                                    <!--<button class="button-alternative  floor-light" @click="SubmitLatter"
                                            :processing="$store.state.orders.confirmOrderProcessing">
                                        <span>{{$t('purchase_step_3_page.button_text_open_modal_subscribe_later')}}</span>
                                    </button>-->

                                    <button-component :text="$t('purchase_step_3_page.button_text_open_modal_subscribe_later')"
                                                      data_class="button-alternative floor-light"
                                                      :processing="$store.state.orders.confirmOrderProcessing"
                                                      @on-click="SubmitLatter"
                                                      :has-go="true"
                                                      type="button">

                                    </button-component>



                                </b-col>
                            </b-row>


                        </b-container>
                    </b-col>

                </b-row>
            </b-container>


            <!--Contact request-->
            <b-container v-else class="p-0 content">
                <div class="hide-in-desktop">
                    <b-row no-gutters>
                        <b-col class="p-4 pl-md-4 p-md-0">
                            <div class="text-bold-20">{{$t('purchase_step_3_page.request_contact.column_right_title')}}</div>
                            <div class="text-regular-12 mb-4">{{$t('purchase_step_3_page.request_contact.column_right_description')}}</div>
                            <address-compact :postal_code='$store.state.orders.address.cp4'
                                             :street="$store.state.orders.address.street"
                                             :door="$store.state.orders.address.door"></address-compact>
                        </b-col>
                    </b-row>
                    <product-card-small class="mb-4"></product-card-small>
                </div>
                <b-row no-gutters class="mb-4">
                    <b-col md="7" sm="12" class="column-left border-right-gray-200">

                        <b-container fluid class="px-4">

                            <div class="text-bold-20">{{$t('purchase_step_3_page.request_contact.column_left_title')}}</div>
                            <div class="text-regular-12 mb-4">{{$t('purchase_step_3_page.request_contact.column_left_description')}}</div>
                            <request-contact-form @on-cancel-click="goToStore"
                                                  @on-submit="goToHome"
                                                  @on-error="onError"
                                                  context="order3">
                            </request-contact-form>
                        </b-container>



                    </b-col>
                    <b-col md="5" sm="12" class="hide-in-mobile">
                        <b-row no-gutters>
                            <b-col class="p-4 pl-md-4 p-md-0">
                                <div class="text-bold-20">{{$t('purchase_step_3_page.request_contact.column_right_title')}}</div>
                                <div class="text-regular-12 mb-4">{{$t('purchase_step_3_page.request_contact.column_right_description')}}</div>
                                <address-compact :postal_code='$store.state.orders.address.cp4'
                                                 :street="$store.state.orders.address.street"
                                                 :door="$store.state.orders.address.door"></address-compact>
                            </b-col>
                        </b-row>
                        <product-card-small></product-card-small>
                    </b-col>
                </b-row>
            </b-container>
        </ValidationObserver>



        <generic-modal :data_show="showContractInformation"
                       @closed="closedContractInformation"
                       :data_title="$t('purchase_step_4_page.modal_title_contract_information')"
                       :data_text_button_footer="$t('download_all')"
                       data_icon_button_footer="icon-download">
            <b-container class="p-0">
                <b-row no-gutters class="border-bottom-gray-200 ">
                    <b-col class="p-4">
                        <div class="text-semibold-14">contrato</div>
                    </b-col>
                    <b-col cols="auto" align-self="center" class="pr-4">
                        <a class="download-button">
                            <div class="icon-wrapper-24 icon">
                                <i class="icon-download" aria-hidden="true"></i>
                            </div>
                            <span>{{$t('download')}}</span>
                        </a>
                    </b-col>
                </b-row>
                <b-row no-gutters class="border-bottom-gray-200 ">
                    <b-col class="p-4">
                        <div class="text-semibold-14">portabilidade</div>
                    </b-col>
                    <b-col cols="auto" align-self="center" class="pr-4">
                        <a class="download-button">
                            <div class="icon-wrapper-24 icon">
                                <i class="icon-download" aria-hidden="true"></i>
                            </div>
                            <span>{{$t('download')}}</span>
                        </a>
                    </b-col>
                </b-row>
            </b-container>
        </generic-modal>


        <div v-if="$route.query && $route.query.debug">
            
            {{DocumentosSelected}}


        </div>


    </div>
</template>
<script>
    import InputText from '@/components/InputText.vue'
    import GenericModal from '@/components/GenericModal.vue'

    //import ModalSimple from '@/components/ModalSimple.vue'

    import AlertMessage from '../../models/alert-message';
    import ButtonComponent from '@/components/ButtonComponent.vue'

    import { brokerService } from '../../services';

    import InputMultipleBlock from '@/components/InputMultipleBlock.vue'

    import config from '../../helpers/config'

    import _ from 'lodash';

    import DropdownDefault from '@/components/DropdownDefault.vue'

    export default {
        name: 'purchase-step-3',
        components: {
            InputText,
            GenericModal,
            //ModalSimple,
            ProductCardSmall: () => import('@/components/order/ProductCardSmall.vue'),
            RequestContactForm: () => import('@/components/RequestContactForm.vue'),
            AddressCompact: () => import('@/components/order/AddressCompact.vue'),
            ButtonComponent,
            InputMultipleBlock,
            DropdownDefault
        },
        data() {
            return {
                showContractInformation: false,
                showRequestContactForm: false,
                AccountableEdit: {},
                invalid: true,
                availability: config.AVAILABILITY,
                DocTypes: config.DOCUMENT_TYPES,
                OfferFiles: [],
                //  orderfiles: [],
                offers_have_loaded: false
            }
        },
        methods: {

            onContactSubmit() {

            },
            SubmitLatter() {



                this.$store.dispatch('orders/confirmOrder', {
                    accountable: this.AccountableEdit,
                    sessionid: this.currentUser.sessionId,
                    accountid: this.$store.state.authentication.user.id,
                    orderid: this.$route.query.orderid,
                    action: "onhold"
                }).then(
                    () => {
                       // console.log(result);
                        this.$store.dispatch('alert/success',
                            new AlertMessage(
                                this.$t("request_success_title"),
                                this.$t("request_success_text")
                            )); // "O seu pedido foi enviado com sucesso."
                        //this.$router.push('/');
                        this.$store.dispatch('orders/resetState');
                        this.$router.push({ name: "home" });

                    },
                    error => {
                        this.loading = false;
                        this.message =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        // this.$store.dispatch('alert/error', new AlertMessage(this.$t("error"), this.$t("request_error_text")));
                        this.$router.push('/');
                    }
                );


            },
            ShowContactRequest() {



                this.showRequestContactForm = true;
            },
            goToHome() {
                this.$router.push({ name: "home", params: { lang: this.$i18n.locale } });
            },
            goToStore() {
                this.$router.push({ name: "store", params: { lang: this.$i18n.locale } });
            },
            cancelOrder() {
                this.$store.dispatch('orders/cancelOrder', {
                    sessionid: this.currentUser.sessionId,
                    accountid: this.$store.state.authentication.user.id,
                    orderid: this.$route.query.orderid,
                    //  accountable: { contact: this.$store.state.orders.accountable.contact, availability: this.$store.state.orders.accountable.availability }
                }).then(
                    () => {
                    //    console.log(result);
                        //this.$store.dispatch('alert/success',
                        //    new AlertMessage(
                        //        this.$t("messages.cancel_order_success_title"),
                        //        this.$t("messages.cancel_order_success_message")
                        //    ));
                   
                        this.$store.dispatch('orders/clearAddress', { root: true });
                        this.$store.dispatch('orders/resetState');
                        this.$router.push({ name: "home" });

                    },
                    error => {
                        this.loading = false;
                        this.message =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        this.$router.push('/');
                    }
                );
            },
            clickedOnContractInformation() {
                this.showContractInformation = true;
            },
            closedContractInformation() {
                this.showContractInformation = false;
            },
            SendConfirmationOrder() {
                //  this.$store.dispatch('orders/updateOrder');


                //       this.$store.dispatch('alert/success', new AlertMessage(this.$t("sucess"), this.$t("request_success_text"))); // "O seu pedido foi enviado com sucesso."



                this.$store.dispatch('orders/confirmOrder', {
                    accountable: this.AccountableEdit,
                    sessionid: this.currentUser.sessionId,
                    accountid: this.$store.state.authentication.user.id,
                    orderid: this.$route.query.orderid
                }).then(
                    () => {
                      //  console.log(result);
                        this.$store.dispatch('alert/success',
                            new AlertMessage(
                                this.$t("messages.create_order_success_title"),
                                this.$t("messages.create_order_success_message")
                            ));

                       // this.$router.push('/');
                        this.$store.dispatch('orders/clearAddress', { root: true });
                        this.$store.dispatch('orders/resetState');
                        this.$router.push({ name: "home" });

                         
                    },
                    error => {
                        this.loading = false;
                        this.message =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();


                        this.$router.push('/');
                    }
                );



            }
            ,
            DownloadFile(id) {
                //var today = new Date();
                //var dd = String(today.getDate()).padStart(2, '0');
                //var mm = String(today.getMonth() + 1).padStart(2, '0');
                //var yyyy = today.getFullYear();

                //today = yyyy + '_' + mm + '_' + dd;
                //  var downloadFilename = this.$i18n.t('profile_page.details.request_data_download_filename') + today + ".csv";

                brokerService.downloadFile(id, "");

            },
            changeAvailability(value) {

                //if (this.AccountEdit.availability == null) {
                //    this.AccountEdit.availability = [];
                //}

                var result = "";
                for (var i = 0; i < value.length; i++) {
                    result = result.concat(value[i].value, ",");
                }
                result = result.substring(0, result.length - 1);
                this.$set(this.AccountableEdit, "availability", result);


            },
            ConvertToOptionList(list) {
                return list.split(",");
            },

            onError() {


             //   console.log(" On error ");
                this.$router.push('/');

            },

            updateDocType(value) {

                //console.log("updateDocType");
                //console.log(value.name);

                this.$set(this.AccountableEdit, "doctype", value.id);

            }



        },
        computed: {

            Documents() {


                if (this.$store.state.orders && this.$store.state.orders.product && this.OfferFiles) {
                    return _.orderBy(this.OfferFiles, ['mustagree'], ['desc']);
                }


                return [];

            },

            DocumentosSelected() {

                var select = _.filter(this.OfferFiles, { 'selected': "false", 'mustagree': "Yes" });

                return select.length == 0 && this.offers_have_loaded;
            },

            Accountable() {

                return this.$store.state.orders.accountable;
            },
            //Processing() {

            //    return this.$store.state.orders.processing;
            //},
            IsAutenticated() {

                return this.$store.state.authentication.user != null;
            },
            currentUser() {

                if (this.IsAutenticated) {

                    return JSON.parse(JSON.stringify(this.$store.state.authentication.user));
                } else {

                    return null;
                }

            },
            selectedAvailabity() {

                //    AccountableEdit

                if (this.AccountableEdit.availability) {
                    return this.ConvertToOptionList(this.AccountableEdit.availability);

                }

                return [];
            }
        },


        mounted() {
            this.$store.dispatch('ui/setPurchaseActiveStep', 3, { root: true });

            if (!this.$route.query.orderid) {

                this.$router.push({ name: "step2", params: { lang: this.$i18n.locale } });

            }


            this.$store.dispatch('orders/getUserDetails',
                {
                    id: this.$store.state.authentication.user.id,
                    session: this.$store.state.authentication.user.sessionId
                },
                { root: true }
            ).then(

                () => {

                    this.AccountableEdit = Object.assign({}, this.$store.state.orders.accountable);

                    this.OfferFiles = [];

                    for (var i = 0; i < this.$store.state.orders.product.offerfiles.length; i++) {

                        var elem = this.$store.state.orders.product.offerfiles[i];

                        this.$set(elem, "selected", "false");

                        this.OfferFiles.push(elem);
                     

                    }

                    this.offers_have_loaded = true;

                }

            )
        },
        destroyed: function () {
            this.$store.dispatch('ui/setPurchaseActiveStep', null, { root: true });
        }
    }

</script>
