<template>
    <div class="page">

        <b-container>

            <b-row no-gutters class="mb-3">
                <b-col cols="auto">
                    <div class="big-text">{{$t('backoffice_page.new_page')}}</div>
                </b-col>
            </b-row>

            <b-row no-gutters class="flex-grow-1">
                <b-col>
                    <router-link :to="`/${$i18n.locale}/backoffice/pages`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col align-self="center">
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('cancel')}}</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                </b-col>
            </b-row>

            <b-form @submit.prevent="savepageInfo()" autocomplete="off">
                <b-container class="mt-4">
                    <b-row class="mb-3">
                        <b-col>
                            <input-text v-model="page.title" :label="$t('backoffice_page.title')"></input-text>
                        </b-col>
                        <b-col cols="auto">
                            <b-container class="p-0">
                                <b-row no-gutters>
                                    <b-col cols="auto" class="pr-2">
                                        <div class="py-3">
                                            {{$t('backoffice_page.language')}}
                                        </div>
                                    </b-col>
                                    <b-col class="pt-1">
                                        <dropdown-default data_parent_dropdown="dropdownLanguage"
                                                          :data_options="langs"
                                                          :data_selected="locale"
                                                          @input="changeLocale"
                                                          :data_settings="{
                                                             minimumResultsForSearch: -1 ,
                                                             theme: 'language',
                                                             dropdownParent: '#dropdownLanguage'}">
                                        </dropdown-default>
                                    </b-col>

                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col>
                            <input-text v-model="page.key" :label="$t('backoffice_page.key')"></input-text>
                        </b-col>
                        <b-col>
                            <input-text v-model="page.url_segment" :label="$t('backoffice_page.url')"></input-text>
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="6">
                            <b-form-checkbox v-model="visible_menu" value="true" class="default-checkbox">Visivel no menu</b-form-checkbox>
                        </b-col>
                        <b-col cols="6">
                            <b-form-checkbox v-model="visible_footer" value="true" class="default-checkbox">{{$t('backoffice_page.visible_footer')}}</b-form-checkbox>
                        </b-col>
                    </b-row>

                    <b-row class="mb-3">
                        <b-col cols="6">
                            <input-text v-if="visible_menu" v-model="page.order" :label="$t('backoffice_page.order_menu')" type="number"></input-text>
                        </b-col>
                        <b-col cols="6">
                            <input-text v-if="visible_footer" v-model="page.orderfooter" :label="$t('backoffice_page.order_footer')" type="number"></input-text>
                        </b-col>
                    </b-row>


                    <b-row class="mt-2">
                        <b-col cols="12">
                            <div class="text-semibold-14 mb-3">{{$t('backoffice_page.content')}}</div>
                        </b-col>
                        <b-col class="mb-3" v-if="page.key != 'faq'">
                           
                            <html-editor v-model="page.html_content"></html-editor>
                        </b-col>
                        <b-col class="mb-3" v-else>
                            {{$t('backoffice_page.define_faq_section')}}
                        </b-col>
                    </b-row>

                    <b-row class="mt-2 mb-3">
                        <b-col></b-col>
                        <b-col>
                            <router-link :to="`/${$i18n.locale}/backoffice/pages`">
                                <button type="button" class="button-alternative floor-light">
                                    <span>{{$t('cancel')}}</span>
                                </button>
                            </router-link>
                        </b-col>

                        <b-col>
                            <button type="button" @click="savepageInfo" class="button-secondary floor-light">
                                <span>{{$t('save')}}</span>
                            </button>
                        </b-col>

                    </b-row>
                </b-container>
            </b-form>


        </b-container>
    </div>
</template>

<script>
    import InputText from '@/components/InputText.vue'
    import DropdownDefault from '@/components/DropdownDefault.vue';
    import Page from '@/models/page_content.js';
    import HtmlEditor from '@/components/HtmlEditor.vue'


    export default {
        name: 'pageNew',
        components: {
            InputText,
            DropdownDefault,
            HtmlEditor
        },
        data() {
            return {
                page: new Page(),
                visible_menu: false,
                visible_footer: false,
                locale: this.$i18n.locale,
                langs: this.pushingLangs(),
            };
        },
        methods: {
            savepageInfo() {

                //let editor = this.$refs.editor1;
                //var content = editor.getContent();

               // this.page.html_content = content;

                this.page.locale = this.locale;
                this.page.visible_in_menu = this.visible_menu;
                this.page.visible_in_footer = this.visible_footer;

                if (this.page.visible_in_menu && (this.page.order === null || this.page.order.length == "")) {
                    alert("Não pode ter a visibilidade no menu sem ter a ordem no menu definida");
                 //   console.log("Show error of estado menu checked without order of menu defined");
                } else if (this.page.visible_in_footer && (this.page.orderfooter === null || this.page.orderfooter.length == "")) {
                    alert("Não pode ter a página visivel no footer sem ter a ordem do footer definida");
                  //  console.log("Show error of estado do footer checked without order of footer set");
                } else {

                    //console.log("on save - page");
                    //console.log(this.page);

                    this.$store.dispatch('page/addPage', this.page).then(
                        () => {

                          //  console.log(response);

                            this.$router.go(-1);
                        },
                        error => {
                            this.message = (error.response && error.response.data) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
            pushingLangs() {
                var list = []
                this.$i18n.availableLocales.forEach((value) => {
                    list.push({ id: value, name: value, text: value });
                });

                return list;
            },
            changeLocale(val) {
                this.locale = val.text;
            }
        },
        created() {
        },
      


    };
</script>