<template>
    <div class="breadcrumb-component">
        <div v-for="(item, index) in items" :key="index" class="d-inline-block">
            <div class="icon-wrapper-16 primary d-inline-block mr-3">
                <i class="icon-right"></i>
            </div>
            <span v-if="item.urlMobile">
                <span class="hide-in-mobile">
                    <a :href="item.url" class="text-medium-14 d-inline-block mr-1 breadcrumb-link">
                        {{item.text}}
                    </a>
                </span>
                <span class="hide-in-desktop">
                    <a :href="item.urlMobile" class="text-medium-14 d-inline-block mr-1 breadcrumb-link">
                        {{item.text}}
                    </a>
                </span>

            </span>
            <span v-else>
                <a :href="item.url" class="text-medium-14 d-inline-block mr-1 breadcrumb-link">
                    {{item.text}}
                </a>
            </span>

        </div>
    </div>
</template>
<style>
    .breadcrumb-link:hover{
        color: #292929;
        text-decoration:none;
    }
</style>
<script>
    export default {
        props: {
            items: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                closed: false
            }
        },
        methods: {
            closeBar() {
                this.closed = true;
                this.$emit('action-bar-closed');
            }
        }
    }

</script>