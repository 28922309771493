import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import i18n from './i18n'
import Notifications from 'vue-notification'
import Nl2br from 'vue-nl2br'
import linkify from 'vue-linkify'
import 'leaflet/dist/leaflet.css';
import VueScrollTo from 'vue-scrollto';
//import router from './router'

import { store } from './store';
import { router } from './helpers';
//import store from './store';


import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email, confirmed, numeric } from 'vee-validate/dist/rules';
//import pt from "vee-validate/dist/locale/pt.json";

import Vuex from 'vuex';

import 'leaflet/dist/leaflet.css';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

 

//import VueAnalytics from 'vue-analytics'
 
//Vue.use(VueAnalytics, {
//    id: 'G-VX0TTRCNX7',
//    router
//});


//import VueAnalytics from 'vue-ua'


Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Notifications)

Vue.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

var VueIntl = require('vue-intl');
Vue.use(VueIntl);

import ptLocaleData from 'vue-intl/locale-data/pt';
import { addLocaleData } from 'vue-intl';
addLocaleData(ptLocaleData);

Vue.setLocale('pt');


Vue.component('nl2br', Nl2br)
Vue.directive('linkified', linkify)


Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.prototype.$http = axios

Vue.use(Vuex);

 
 


//import Vueditor from 'vueditor'
//import 'vueditor/dist/style/vueditor.min.css'

//let Vueditorconfig = {
//    toolbar: [
//        'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor', 'divider',
//        'bold', 'italic', 'underline', 'strikeThrough', '|', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', '|', 'indent', 'outdent',
//        'insertOrderedList', 'insertUnorderedList', '|', 'link', 'divider', 'subscript', 'superscript', '|', 'switchView', 'sourceCode'
//    ],

  

//    fontName: [
//        { val: 'arial black' },
//        { val: 'times new roman' },
//        { val: 'Courier New' }
//    ],
//    fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
//    uploadUrl: ''
//};
//Vue.use(Vueditor, Vueditorconfig);


extend('required', {
    ...required,
    message: i18n.t('form_error.required')
});
extend('email', {
    ...email,
    message: i18n.t('form_error.email')
});

extend('confirmed', {
    ...confirmed,
    message: i18n.t('form_error.confirmed')
});

extend('numeric', {
    ...numeric,
    message: i18n.t('form_error.numeric')
});

//extend('min', {
//    ...min,
//    message: 'O campo tem que ter pelo menos 6 caracteres'
//});

//extend('is', {
//    ...is,
//    message: 'Para preseguir tem que selecionar este campo'
//});



extend('min', {
    validate(value, { length }) {
        return value.length >= length;
    },
    params: ['length'],
    message: i18n.t('form_error.min')
});


extend('isTrue', {
    validate(value) {
        return value == true;
    },
    params: [''],
    message: i18n.t('form_error.istrue')
});


extend('passwordcomplexity', {
    validate(value) {
        const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        return pattern.test(value);
    },
    params: [''],
    message: ' '
});

extend('phone', {
    validate(value) {
        const pattern = new RegExp("^[+]?[0-9]+$");
        return pattern.test(value);
    },
    params: [''],
    message: i18n.t('form_error.phone')
});

extend('privacymultiplerequired', {
    validate(value) {
        var totalConfirmed = 0;
        var arrayOptions = value.split(";");
        for (var i = 0; i < arrayOptions.length; i++) {
            if (arrayOptions[i].split("=")[1] == "1") {
                totalConfirmed++
            }
        }
        return totalConfirmed > 0;
    },
    params: [''],
    message: i18n.t('form_error.privacy_multiple_required')
});



//Vue.use(VueAnalytics, {
//    appName: 'GetOn', // Mandatory
//    appVersion: '1', // Mandatory
//    trackingId: 'G-VX0TTRCNX7', // Mandatory
//    debug: true, // Whether or not display console logs debugs (optional)
//    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//    ignoredViews: ['homepage'], // If router, you can exclude some routes name (case insensitive) (optional)
//    trackPage: true | false, // Whether you want page changes to be recorded as pageviews (website) or screenviews (app), default: false
//    createOptions: { // Optional, Option when creating GA tracker, ref: https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference
//        siteSpeedSampleRate: 10,
//    },
//    globalDimensions: [ // Optional
//        { dimension: 1, value: 'MyDimensionValue' },
//        { dimension: 2, value: 'AnotherDimensionValue' }
//    ],
//    globalMetrics: [ // Optional
//        { metric: 1, value: 'MyMetricValue' },
//        { metric: 2, value: 'AnotherMetricValue' }
//    ]
//})


 //Ensure we checked auth before each page load.
router.beforeEach((to, from, next) =>

    //      dispatch('authentication/login',

    Promise.all([store.dispatch("authentication/check_auth")]).then(next)

);

 
 


new Vue({
    //components: {
    //    "vue-select": require("vue-select")
    //},
    router,
    store,
    i18n,
  render: h => h(App)
}).$mount('#app')


 
