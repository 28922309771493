
//import { router } from '../helpers';
import { brokerService } from '../services';
//import { apiService } from '../services';
import i18n from '../i18n'

export const profile = {
    namespaced: true,
    state: {
        operation: { processing: false, success: false, message: "" },

        product: {},

        get_orders_error: false,
        get_order_detail_error: false,

        get_order_history_error: false,

        get_alerts_error: false,
        get_alert_detail_error: false,


        get_account_history_error: false,



        account: {},
        accountable: {},
        privacy: [],
        orders: [],
        orderdetail: {},
        orderhistory: [],
        accounthistory: [],
        alerts: [],
        alertdetail: [],
        num_alerts_unread: 0,


        getUserDetailsError: false,

        // getProductDetailProcessing: false

    },
    actions: {
        getUserDetails({ commit }, param) {

            commit('getUserDetailsRequest');

            return new Promise((resolve, reject) => {
                brokerService.getUserDetails(param.id, param.session)
                    .then(({ data }) => {

                        commit('getUserDetailsSucess', data);

                        resolve(data);
                    }).catch(error => {


                        commit('getUserDetailsFailure', error);

                        reject(error)

                    });




            });
        },
        saveUserDetails({ commit }, param) {

            commit('saveUserDetailsRequest');

            return new Promise((resolve, reject) => {
                brokerService.saveUserDetails(param.user, param.userid, param.session)
                    .then(({ data }) => {

                        commit('saveUserDetailsSucess', data);

                        //dispatch('alert/success', { title: '', description: i18n.t('success.data_saved') }, { root: true });

                        resolve(data);
                    })
                    .catch(error => {

                        commit('saveUserDetailsFailure', error);
                        //dispatch('alert/error', { title: "Erro", description: i18n.t('error.an_error_occurred') }, { root: true });
                        reject(error)
                    });
            });

        },
        saveUserPrivacy({ dispatch }, param) {
            return new Promise((resolve, reject) => {
                brokerService.saveUserDetails(param.user, param.userid, param.session)
                    .then(() => {
                        //dispatch('alert/success', { title: '', description: i18n.t('success.data_saved') }, { root: true });
                        resolve(true);
                    })
                    .catch(() => {
                        dispatch('alert/error', { title: "", description: i18n.t('error.generic') }, { root: true });
                        reject(false);
                    });
            });
        },
        getOrders({ commit }, param) {
            //console.log(" getOrders  store");
            //console.log(param);
            commit('getOrdersRequest');

            return new Promise((resolve, reject) => {
                brokerService.getOrders(param)
                    .then(({ data }) => {
                        commit('getOrdersSuccess', data);
                        resolve(data);
                    })
                    .catch(error => {
                        commit('getOrdersFailure', error);
                        reject(error)
                    });
            });
        },
        getRequestDetails({ commit }, params) {


            commit('getRequestDetailsRequest');

            return new Promise((resolve, reject) => {
                brokerService.getRequestDetails(params)
                    .then(({ data }) => {

                        commit('getRequestDetailsSuccess', data);
                        //   console.log(data);
                        resolve(data);

                    })
                    .catch(error => {

                        commit('getRequestDetailsFailure', error);
                        reject(error)
                    });
            });
        },

        getOrderHistory({ commit }, param) {

            commit('getOrderHistoryRequest');


            // console.log("getOrderHistory");     
            //console.log(param);     


            return new Promise((resolve, reject) => {
                brokerService.getOrderHistory(param)
                    .then(({ data }) => {

                        commit('getOrderHistorySuccess', data);

                        resolve(data);
                    })
                    .catch(error => {

                        commit('getOrderHistoryFailure', error);
                        reject(error)
                    });
            });
        },

        getAccountHistory({ commit }, param) {

            commit('getAccountHistoryRequest');

            return new Promise((resolve, reject) => {
                brokerService.getAccountHistory(param)
                    .then(({ data }) => {


                        commit('getAccountHistorySuccess', data);

                        resolve(data);
                    })
                    .catch(error => {

                        commit('getAccountHistoryFailure', error);
                        reject(error)
                    });
            });
        },
        //num_alerts_unread

        getAlerts({ commit }, param) {

            commit('getAlertsRequest');


            return new Promise((resolve, reject) => {
                brokerService.getAlerts(param)
                    .then(({ data }) => {


                        commit('getAlertsSuccess', { data: data, filter: param.filter });
                        // console.log(data);
                        resolve(data);

                    })
                    .catch(error => {

                        commit('getAlertsFailure', error);
                        reject(error)
                    });
            });
        },

        getAlertDetails({ commit }, params) {


            commit('getAlertDetailsRequest');

            return new Promise((resolve, reject) => {
                brokerService.getAlertDetails(params)
                    .then(({ data }) => {

                        commit('getAlertDetailsSuccess', data);

                        //console.log(data);

                        resolve(data);

                    })
                    .catch(error => {

                        commit('getAlertDetailsFailure', error);
                        reject(error)
                    });
            });
        },



    },
    getters: {
        getAccountName: state => {

            if (state.account) {

                return state.account.name + " " + state.account.surname; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },
        getAccountContact: state => {

            if (state.account) {

                return state.account.contact; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },
        getAccountBirthdate: state => {

            if (state.account) {

                return state.account.birth_date; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },
        getAccountGender: state => {

            if (state.account) {

                return state.account.genre; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },
        getAccountEmail: state => {

            if (state.account) {

                return state.account.email; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },


    },
    mutations: {

        getUserDetailsRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.accountable = {};
            state.account = {};
            state.privacy = [];

            state.getUserDetailsError = false;

        },
        getUserDetailsSucess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.accountable = data.data.accountable;
            state.account = data.data.account;
            state.privacy = data.data.privacy;

            state.getUserDetailsError = false;
        },
        getUserDetailsFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.message };
            state.accountable = {};
            state.account = {};
            state.privacy = [];

            state.getUserDetailsError = true;
        },


        /**
         *  Save user Details 
         */

        saveUserDetailsRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            //   state.user = {};
        },
        saveUserDetailsSucess: function (state) {
            state.operation = { processing: false, success: true, message: "" };
            // state.user = data.data;
        },
        saveUserDetailsFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.message };
            // state.user = {};
        },
        /*
         * Get Orders getOrdersRequest
         */
        getOrdersRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.orders = [];
            state.get_orders_error = false;
        },
        getOrdersSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.orders = data.data;
            state.get_orders_error = false;
        },
        getOrdersFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.message };
            state.orders = {};
            state.get_orders_error = true;
        },

        /**/

        getRequestDetailsRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.orderdetail = {};
            state.get_order_detail_error = false;
        },
        getRequestDetailsSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.orderdetail = data.data;
            state.get_order_detail_error = false;
        },
        getRequestDetailsFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.message };
            state.orderdetail = {};
            state.get_order_detail_error = true;
        },
        /*
         * Orders history
         */
        getOrderHistoryRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.orderhistory = [];
            state.get_order_history_error = false;
        },
        getOrderHistorySuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.orderhistory = data.data;
            state.get_order_history_error = false;
        },
        getOrderHistoryFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.message };
            state.orderhistory = [];
            state.get_order_history_error = true;
        },
        /*
        * Account history
        */
        getAccountHistoryRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.accounthistory = {};
            state.get_account_history_error = false;
        },
        getAccountHistorySuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.accounthistory = data.data;
            state.get_account_history_error = false;
        },
        getAccountHistoryFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.message };
            state.accounthistory = {};
            state.get_account_history_error = true;
        },

        /*
         *  Get alerts
         */
        getAlertsRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.alerts = [];
            state.get_alerts_error = false;
        },
        getAlertsSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.alerts = data.data.data;
            state.get_alerts_error = false;


            //console.log("Alert filter"); 
            //console.log(data.filter); 

            if (data.filter == "unread") {
                state.num_alerts_unread = data.data.data.nresults;

            }


        },
        getAlertsFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.message };
            state.alerts = [];
            state.get_alerts_error = true;
        },

        /**
         * Alert Details
         */

        getAlertDetailsRequest(state) {
            state.operation = { processing: true, success: false, message: "" };
            state.alertdetail = {};
            state.get_alert_detail_error = false;
        },
        getAlertDetailsSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "" };
            state.alertdetail = data.data;
            state.get_alert_detail_error = false;
        },
        getAlertDetailsFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: data.message };
            state.alertdetail = {};
            state.get_alert_detail_error = true;
        },




    }

}