import Vue from 'vue';
import Router from 'vue-router';

import i18n from '../i18n'


import { store } from '../store'

 


const Home = () => import(/* webpackChunkName: "home" */  '../views/Home');

const Store = () => import(/* webpackChunkName: "store" */  '../views/Store');

const Coverage = () => import(/* webpackChunkName: "store" */ '../views/Coverage');

//const About = () => import(/* webpackChunkName: "about" */ '../views/About');

//const News = () => import(/* webpackChunkName: "news" */ '../views/News');

//const NewsDetail = () => import(/* webpackChunkName: "newsdetails" */ '../views/NewsDetails');

const Profile = () => import(/* webpackChunkName: "profile" */ '../views/Profile');

const Order = () => import(/* webpackChunkName: "profile" */ '../views/Order');


const RequestContact = () => import(/* webpackChunkName: "profile" */ '../views/RequestContact');

const PageNotFound = () => import('../views/PageNotFound');


import ProfileMenuPage from '../views/ProfileMenuPage'

import Test from '../views/Test.vue';


const ProfileRequests = () => import(/* webpackChunkName: "profilerequests" */ '../views/profile/Requests.vue');

const ProfileRequestDetail = () => import(/* webpackChunkName: "requestdetail" */ '../views/profile/RequestDetail.vue');


const Backoffice = () => import(/* webpackChunkName: "requestdetail" */ '../views/backoffice/Backoffice.vue');




import ProfileDetails from '../views/profile/Details.vue';
import ProfileWhishlist from '../views/profile/Whishlist.vue';
import ProfileHistoric from '../views/profile/Historic.vue';
import ProfileWarnings from '../views/profile/Warnings.vue';
import ProfileWarningDetail from '../views/profile/WarningDetail.vue';
import ProfileMyAddresses from '../views/profile/MyAddresses.vue';
import ProfilePermissions from '../views/profile/Permissions.vue';


import NewsPage from '../views/backoffice/News.vue';
import NewsList from '../views/backoffice/news/List.vue';
import NewsNew from '../views/backoffice/news/New.vue';
import NewsEdit from '../views/backoffice/news/Edit.vue';
import NewsDetails from '../views/backoffice/news/Details.vue';

import PagesPage from '../views/backoffice/Page.vue';
import PagesList from '../views/backoffice/pages/List.vue';
import PagesNew from '../views/backoffice/pages/New.vue';
import PagesEdit from '../views/backoffice/pages/Edit.vue';
import PageDetail from '../views/backoffice/pages/Details.vue';

import FaqsPage from '../views/backoffice/Faqs.vue';
import FaqsList from '../views/backoffice/faqs/List.vue';
import FaqsNew from '../views/backoffice/faqs/New.vue';
import FaqsEdit from '../views/backoffice/faqs/Edit.vue';
import FaqsDetail from '../views/backoffice/faqs/Details.vue';


import PurchaseStep1 from '../views/order/PurchaseStep1.vue';
import PurchaseStep2 from '../views/order/PurchaseStep2.vue';
import PurchaseStep3 from '../views/order/PurchaseStep3.vue';

//import SimplePage from '../views/Page.vue';

import SimplePageKey from '../views/Pagekey.vue';

import UsersList from '../views/backoffice/users/List.vue';

import UsersEdit from '../views/backoffice/users/Edit.vue';


//import SupportedBrowsers from '../views/SupportedBrowsers.vue'

import EmptyRouterView from '../views/EmptyRouterView.vue';

import { Role } from '@/helpers/role.js';

import { SUPPORTED_LOCALES } from '../locales/locales.js'

Vue.use(Router);


function getLocaleRegex() {
    let reg = ''
    SUPPORTED_LOCALES.forEach((locale, index) => {
        reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`
    })
    return `(${reg})`
}


export const router = new Router({
    mode: 'history',  // to this work enable route re-write on server
    base: '/',
    routes: [
        {
           path: '/',
            redirect: i18n.locale
        }, 
        {
         
            path: `/:lang${getLocaleRegex()}?`,
            name: "lang",
            component: EmptyRouterView,            
            children: [
                {
                    path: '',
                    name: "home",
                    meta: {
                        title: 'home',
                        description: 'home_description',
                        keywords: 'home_keywords'
                    },
                    component: Home
                },

                //{ path: 'page/:type', component: SimplePage },


                { path: 'pagek/:keyType', component: SimplePageKey },

                {
                    path: 'store',
                    name: "store",
                    meta: {
                        title: 'store',
                        description: 'store_description',
                        keywords: 'store_keywords'
                    },
                    component: Store
                },
                //{
                //    path: 'about',
                //    name: "about",
                //    meta: {
                //        title: 'about',
                //        description: 'about_description',
                //        keywords: 'about_keywords'
                //    },
                //    component: About
                //},
                {
                    path: 'requestcontact',
                    name: "requestcontact",                     
                    component: RequestContact,
                    meta: {
                        title: 'request_contact',
                        description: 'request_contact_description',
                        keywords: 'request_contact_keywords',
                        authorize: [Role.Admin, Role.User]
                    }
                },
                
                {
                    path: 'coverage', 
                    name: "coverage",
                    meta: {
                        title: 'coverage',
                        description: 'coverage_description',
                        keywords: 'coverage_keywords'
                    },
                    component: Coverage
                },
                {
                    path: 'order/:id',
                    name: "order",
                    component: Order,
                    meta: {
                        title: 'order',
                        description: 'order_description',
                        keywords: 'order_keywords',
                        authorize: [Role.Admin, Role.User]
                    },
                    redirect: 'order/:id/step1',
                    children: [
                        { path: 'step1', name: "step1", component:  PurchaseStep1 },
                        { path: 'step2', name: "step2",  component: PurchaseStep2 , meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'step3', name: "step3",  component: PurchaseStep3 , meta: { authorize: [Role.Admin, Role.User] } },
                       ]

                },

                { path: 'test', component: Test },          

                {
                    path: 'profile-menu',
                    component: ProfileMenuPage,
                    meta: {
                        title: 'profile',
                        authorize: []
                    }
                },  
                {
                    path: 'profile',
                    component: Profile,
                    meta:
                    {
                        title: 'profile',
                        description: 'profile_description',
                        keywords: 'profile_keywords',
                       authorize: [Role.Admin, Role.User]
                      //  authorize: []
                    },
                    redirect: 'profile/details',
                    children: [
                        { path: 'details', component: ProfileDetails, meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'requests', component: ProfileRequests, meta: { authorize: []  } },
                        { path: 'request-detail/:id', component: ProfileRequestDetail, meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'whishlist', component: ProfileWhishlist, meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'historic', component: ProfileHistoric, meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'warnings', component: ProfileWarnings, meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'warning-detail/:id', component: ProfileWarningDetail, meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'my-addresses', component: ProfileMyAddresses, meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'permissions', component: ProfilePermissions, meta: { authorize: [Role.Admin, Role.User] } },
                        { path: 'profile-menu', name: "profile-menu", component: ProfileMenuPage, meta: { authorize: [] } },

                    ]
                },
                //{
                //    path: 'news',
                //    meta: {
                //        title: 'news',
                //        description: 'news_description',
                //        keywords: 'news_keywords'
                //    },
                //    component: News
                //}, 
                //{
                //    path: 'newsdetail/:newsDetId',
                //    meta: {
                //        title: 'news',
                //    },
                //    component: NewsDetail
                //},
                {
                    path: 'backoffice',
                    component: Backoffice,
                    meta: {
                        title: 'backoffice',
                        description: 'backoffice_description',
                        keywords: 'backoffice_keywords',
                       authorize: [Role.Admin]
                      //  authorize: []

                    },
                    children: [
                        {
                            path: 'news',
                            component: NewsPage,
                            meta: { authorize: [] },
                            redirect: 'news/list',
                            children: [
                                { path: 'list', component: NewsList, meta: { authorize: [] } },
                                { path: 'new', component: NewsNew, meta: { authorize: [] } },
                                { path: 'edit/:newsId', component: NewsEdit, meta: { authorize: [] } },
                                { path: 'details/:detailId', component: NewsDetails, meta: { authorize: [] } }
                            ]
                        }
                        ,
                        {
                            path: 'pages',
                            component: PagesPage,
                            meta: { authorize: [] },
                            redirect: 'pages/list',
                            children: [
                                { path: 'list', component: PagesList, meta: { authorize: [] } },
                                { path: 'new', component: PagesNew, meta: { authorize: [] } },
                                { path: 'edit/:pageEditId', component: PagesEdit, meta: { authorize: [] } },
                                { path: 'details/:pageDetId', component: PageDetail, meta: { authorize: [] } }
                            ]
                        },
                        {
                            path: 'faqs',
                            component: FaqsPage,
                            meta: {
                                title: 'faq',
                                description: 'faq_description',
                                keywords: 'faq_keywords',
                                authorize: []
                            },
                            redirect: 'faqs/list',
                            children: [
                                { path: 'list', component: FaqsList, meta: { authorize: [] } },
                                { path: 'new', component: FaqsNew, meta: { authorize: [] } },
                                { path: 'edit/:faqsEditId', component: FaqsEdit, meta: { authorize: [] } },
                                { path: 'details/:detailFaqsId', component: FaqsDetail, meta: { authorize: [] } }
                            ]
                        },
                        {
                            path: 'users',
                            component: EmptyRouterView,
                            meta: {
                                title: 'users',
                                description: 'users_description',
                                keywords: 'users_keywords',
                                authorize: []
                            },
                            redirect: 'users/list',
                            children: [
                                { path: 'list', component: UsersList, meta: { authorize: [] } }, 
                                { path: 'edit/:id', component: UsersEdit, meta: { authorize: [] } }
                            ]
                        },
                    ]
                }

            ]
        },
        // , 
        {
            path: '*',
            meta:
            {
                title: 'page_not_found',
                description: 'page_not_found_description',
                keywords: 'page_not_found_keywords',
                authorize: [Role.Admin, Role.User]
                //  authorize: []
            },

            component: PageNotFound
        }
    ],
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
});
 




router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    let language = to.params.lang; 

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);


// next();  // use only for testing pages this bypass autentication


    if (nearestWithTitle) {

        document.title = i18n.t("page_meta." + nearestWithTitle.meta.title);


        if (nearestWithTitle.meta.description) {

            const descEl = document.querySelector('head meta[name="description"]');

            descEl.setAttribute('content', i18n.t("page_meta." + nearestWithTitle.meta.description) );

        }

        if (nearestWithTitle.meta.keywords   ) {

            const keywordsEl = document.querySelector('head meta[name="keywords"]');

            keywordsEl.setAttribute('content', i18n.t("page_meta." + nearestWithTitle.meta.keywords));

          //  keywordsEl     document.description = i18n.t("page_meta." + nearestWithTitle.meta.keywords);

        }
    }
 

    if (!language
   || SUPPORTED_LOCALES.filter(l => l.code == language).length == 0
    ) {
        language = i18n.locale;
        to.params.lang = language;

       // console.log("no language");

    } else {

        i18n.locale = language;
    }


     


    //SUPPORTED_LOCALES

    const { authorize } = to.meta;
    const currentUser = JSON.parse( localStorage.getItem('user'));

    if (authorize) {

      

        if (!currentUser) {
            // not logged in so redirect to login page with the return url

            //console.log("Redirect");
            
            

            store.dispatch('ui/showLogin', { show: true, redirectTo: to.path, query: to.query    });

            return;
            
        }

        //// check if route is restricted by role
        if (authorize.length && !authorize.includes(currentUser.role)) {

             
            store.dispatch('ui/showLogin', { show: true, redirectTo: to.path  , query: to.query  });

            return;

        }



    }



    next();
})


