<template>
    <div class="datepicker-parent">
        <div :class="['datepicker', isDirty ? 'is-dirty' : '', focused ? 'focused' : '', hasErrors ? 'has-errors' : '']">
            <label class="label-textbox">{{data_label}}</label>
            <b-form-datepicker @input="onInput"
                               @shown="onFocus"
                               @hidden="onBlur"
                               class="input"
                               v-model="value"
                               :show-decade-nav="true"
                               :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                               :locale="data_locale"
                               placeholder=""
                               :hide-header="true"
                               :max="data_max"
                               :disabled="data_readonly"
                               :readonly="data_readonly"
                               nav-button-variant="primary"></b-form-datepicker>
        </div>
        <div v-if="hasErrors" class="error-message pl-2 mb-2">{{data_error}}</div>
    </div>
</template>
<script>
    export default {
        props: {
            data_label: String,
            data_value: Date,
            data_locale: String,
            data_error: String,
            data_max: Date,
            data_readonly: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                focused: false,
                isDirty: this.data_value != null && this.data_value.length > 0,
                value: this.data_value,
            }
        },
        computed: {
            hasErrors: function () {
                return this.data_error != null && this.data_error.length > 0;
            }
        },
        methods: {
            onBlur() {
                this.focused = false;
            },
            onFocus() {
                this.focused = true;
            },
            onInput() {
                this.isDirty = this.value.length > 0;
                this.$emit('input', this.value)
            }
        },
        watch: {
            data_value: function (val) {
                this.value = val;
                this.isDirty = this.value.length > 0 || val != null;

            }
        }
    }
</script>