<template>
    <div class="faqs no-text-transform">

        <b-container>

            <b-row no-gutters class="flex-grow-1" style="text-align: center;">
                <b-col>
                    <router-link :to="`/${$i18n.locale}/backoffice/faqs`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col align-self="center">
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('back')}}</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                </b-col>
            </b-row>

            <b-container>
                <b-row no-gutters class="mt-4">
                    <b-col></b-col>
                    <b-col cols="auto"><div class="big-text">{{faqsDetail.Question}}</div></b-col>

                    <b-col></b-col>
                </b-row>
                <b-row class="mt-3 mb-3">
                    <p v-html="$formatDate(faqsDetail.date)"></p>
                </b-row>
                <b-row no-gutters class="mb-4">
                    <b-col></b-col>
                    <b-col cols="auto">
                        <div class="description" style="text-align:left;">
                            <span v-html="faqsDetail.answer"></span>
                        </div>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </b-container>


        </b-container>
    </div>
</template>

<script>

    export default {
        name: 'FaqsDetails',

        data() {
            return {};
        },
        methods: {
        },
        created() {
        },
        components: {
        },

        computed: {
            faqsDetail() {
                return this.$store.state.faqs.faqsInfo;
            },

        },
        mounted() {

            this.$store.dispatch('faqs/getFaqsId', { id: this.$route.params.detailFaqId, locale: this.$i18n.locale });

        }

    };
</script>