export default class OrderCreate {


    constructor() {

        this.account = {};
        this.account.accountid = "";
        this.account.email = "";
        this.account.url = "";

        this.order = {};
        this.order.type = "";
        this.order.addressid = "";
        this.order.productid = "";
        this.order.comments = "";


   

    }

}
