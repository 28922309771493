<template>
    <div class="pages">
        <b-container>
            <!--<b-row no-gutters class="flex-grow-1" style="text-align: center;">
                <b-col>
                    <router-link :to="`/${$i18n.locale}/backoffice`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col align-self="center">
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('back')}}</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                </b-col>
            </b-row>-->

            <b-row>
                <b-container>
                    <b-row no-gutters class="mt-2 mb-4">
                        <b-col cols="auto"><div class="big-text">{{$t('pages')}}</div></b-col>
                    </b-row>

                </b-container>
            </b-row>

            <b-row class="mb-4 mt-3">
                <b-col cols="4">
                    <router-link :to="`/${$i18n.locale}/backoffice/pages/New`">
                        <button type="button" class="button-alternative">
                            <span>{{$t('backoffice_page.new_page')}}</span>
                        </button>
                    </router-link>
                </b-col>
                <b-col></b-col>
                <b-col cols="6" style="display:flex; justify-content:flex-end;">
                    <span class="filter-text">{{$t('filter_language')}}</span>
                    <div class="d-inline-block right" style="width: 140px;">
                        <dropdown-default class="pt-1"
                                          :data_options="langs"
                                          :data_selected="localeFilter"
                                          data_parent_dropdown="dropdownBackofficePagesListLang"
                                          @input="changeLocale"
                                          :data_settings="{
                                                minimumResultsForSearch: -1 ,
                                                theme: 'filter',
                                                placeholder: 'idioma',
                                                dropdownParent: '#dropdownBackofficePagesListLang'
                                      }">
                        </dropdown-default>
                    </div>
                        <!--<button type="button" class="button-secondary col-sm-3" @click="filterList()">
            <span>{{$t('filter')}}</span>
        </button>-->
                </b-col>


            </b-row>

            <b-row v-if="pageLists.length == 0">
                <b-col style="text-align:center;">
                    {{$t('no_content_found')}}
                </b-col>
            </b-row>
            <b-row no-gutters class="mb-4" v-if="pageLists.length > 0">
                <b-container>
                    <b-row style="justify-content:center">
                        <table class="pages-table">
                            <tr class="table-title">
                                <th>{{$t('backoffice_page.key')}}</th>
                                <th>{{$t('backoffice_page.title')}}</th>
                                <th>{{$t('backoffice_page.language')}}</th>
                                <th></th>
                            </tr>
                            <tr v-for="(item,index) in pageLists" :key="index">

                                <td>{{item.key}}</td>
                                <td class="table-cell">
                                    {{item.title}}
                                </td>
                                <td class="table-cell">
                                    {{item.locale}}
                                </td>
                                
                                <td>
                                    <b-row>
                                        <b-col cols="auto">
                                            <router-link :to="`/${$i18n.locale}/backoffice/pages/Edit/${item.id}`">
                                                <span>{{$t('edit')}}</span>
                                            </router-link>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="auto">
                                            <router-link :to="item" class="item-entry red">
                                                <span v-on:click="onDelete(`${item.id}`)">{{$t('delete')}}</span>
                                            </router-link>
                                        </b-col>
                                    </b-row>
                                </td>
                            </tr>
                        </table>

                    </b-row>
                </b-container>
            </b-row>


            <b-container class="p-4">
                <b-row no-gutter>
                    <b-col></b-col>
                    <b-col cols="auto">
                        <pager-default :data_page="currentPage" :data_page_count="totalPages" @on-page-change="changePage"></pager-default>
                    </b-col>
                    <b-col></b-col>
                </b-row>
            </b-container>

        </b-container>

        <!--<router-view></router-view>-->
        
    </div>
</template>


<script>

    import PagerDefault from '@/components/PagerDefault.vue';
    import DropdownDefault from '@/components/DropdownDefault.vue';

    export default {
        components: {
            PagerDefault,
            DropdownDefault
        },
        name: 'PageList',
        data() {
            return {
                currentPage: 1,
              //  totalPages: 1,
                localeFilter: '',
              //  pageLists: [],
                langs: this.pushingLangs(),
            };
        },
        methods: {
            onDelete(id) {

                if (confirm(this.$i18n.t('delete_message'))) {
                    this.$store.dispatch('page/deletePage', id ).then(
                    () => {

                    
                            this.$store.dispatch('page/getPageList', { lang: this.localeFilter, page: 1 });


                        },
                        error => {
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    });
  
                } 

            },

            filterList() {
              //  console.log("locale filter " + this.localeFilter);

                this.$store.dispatch('page/getPageList', { lang: this.localeFilter, page: this.currentPage });
                
            //    .then(
            //    response => {

            //        this.pageLists = response;
            //        this.totalPages = Math.floor(this.$store.state.page.numPages / 12) + 1;
            //    //    console.log("Total pages " + this.totalPages);

            //    },
            //    error => {
            //        this.message = (error.response && error.response.data) ||
            //            error.message ||
            //            error.toString();
            //    }
            //);


            },

            changePage(page) {

                //if (this.localeFilter == "") {
                //    this.$store.dispatch('page/getPageListByPage', { locale: this.$i18n.locale, numPage: page });
                //} else {
                //    this.$store.dispatch('page/getPagesByPage', { locale: this.$i18n.locale, numPage: page });
                //}

                //changePage
                    this.$store.dispatch('page/getPageList', { lang:this.localeFilter, page :page});

            },
             pushingLangs() {
                var list = []
                this.$i18n.availableLocales.forEach((value) => {
                    list.push({ id: value, name: value, text: value });
                });

             
                return list;
            },
            changeLocale(val) {
                this.localeFilter = val.text;
                this.filterList();
            }
        },
        computed: {
            pageLists() {
                return this.$store.state.page.pages;
            },
            totalPages()  {

                 return this.$store.state.page.totalPages;
            }
        },
        mounted() {
        },
        created() {
            this.$store.dispatch('page/getPageList', { lang: "", page :1 });
        
        }

    };
</script>
