<template>
    <div :class="['generic-modal', show ? 'show' : '', !show && clickedToClose ? 'hide' : '']">
        <div :class="['generic-modal-container', !displayNavbar ? 'no-navbar' : '',  hasFooterButton ? 'has-footer' : '']">
                <div class="header">
                    <button @click="headerButtonClicked" class="button-simple-text header-button">{{data_text_button_header}}</button>
                    <div class="title">{{data_title}}</div>
                    <button @click="close()" class="button-close">
                        <div class="icon-wrapper-16 icon">
                            <i class="icon-close" aria-hidden="true"></i>
                        </div>
                    </button>
                </div>
                <div class="body">
                        <b-overlay :show="data_is_loading" :class="['h-100', data_is_loading ? 'd-block' : 'd-none']" spinner-variant="primary" ></b-overlay>
                        <slot :class="['h-100', data_is_loading ? 'd-block' : 'd-none']"></slot>
                </div>
                <div v-if="hasFooterButton" class="footer-modal">
                    <button class="button-simple-secondary" @click="footerButtonClicked">
                        <div v-if="hasFooterIcon" class="icon icon-wrapper-24">
                            <i :class="data_icon_button_footer" aria-hidden="true"></i>
                        </div>
                        <span>{{data_text_button_footer}}</span>
                    </button>
                </div>
        </div>
        <div class="backdrop-modal"></div>
    </div>
</template>
<script>
    export default {
        props: {
            data_show: Boolean,
            data_title: String,
            data_hasCloseButton: Boolean,
            data_hasCleanButton: Boolean,
            data_text_button_footer: String,
            data_text_button_header: String,
            data_icon_button_footer: String,
            data_is_loading: Boolean
        },
        data() {
            return {
                show: this.data_show,
                clickedToClose: false,
                hasFooterButton: this.data_text_button_footer != null ? true : false,
                hasHeaderButton: this.data_text_button_header != null ? true : false,
                hasFooterIcon: this.data_icon_button_footer != null ? true : false,
            }
        },
        methods: {
            close() {
                this.show = false;
                this.clickedToClose = true;
                this.$emit('closed');
            },
            footerButtonClicked() {
                this.$emit('footer-button-clicked');

            },
            headerButtonClicked() {
                this.$emit('header-button-clicked');
            }
        },
        computed: {
            displayNavbar() {
                return this.$store.state.ui.displayNavbar;
            },
        },
        watch: {
            data_show: function (val) {
                this.show = val;
                if (!val) {
                    this.clickedToClose = true;
                }

            },
            show: function (val) {
                this.$store.commit('ui/setScrollStatus', !val, { root: true })
                if (val) {
                    this.$emit('opened');
                }
            }
        }
    }
</script>
