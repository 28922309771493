import { apiService } from '../services';
import i18n from '../i18n'


export const page = {
    namespaced: true,
    state: {
        operation: {},
        pages: [],

        //pagesMenu: [], // todo: needs to be cached for improved performance
        //pagesFooter: [], // todo: needs to be cached for improved performance

        pageLinks: [],

        page: null,
        numPages: 1,
        total: 0,
        totalPages: 0
    },
    actions: {


        getPageList({ commit }, request) {

            commit('getPageListRequest');

            return new Promise(resolve => {
                apiService.getPageList(request)
                    .then(({ data }) => {

                        //console.log("Get all response success for pageList");
                        //console.log(data);

                        commit('getPageListSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        //console.log("Get all response error for pageList");
                        //console.log(response);

                        commit('getPageListFailure', response);
                    });
            });

        },


        getPageById({ commit }, id) {

            commit('getPageIdRequest');
            //console.log(String(pageData.locale));
            //console.log(String(pageData.id));


            return new Promise(resolve => {
                apiService.getPageId(id)
                    .then(({ data }) => {

                        //console.log("Get all response success");
                        //console.log(data);

                        commit('getPageIdSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        //console.log("Get all response error");
                        //console.log(response);

                        commit('getPageIdFailure', response);
                    });
            });

        },


        getPageUrl({ commit }, pageData) {

            commit('getPageUrlRequest');
            //console.log(String(pageData.locale));
            //console.log(String(pageData.url));


            return new Promise(resolve => {
                apiService.getPageUrl(pageData.locale, pageData.url)
                    .then(({ data }) => {

                        //console.log("Get all response success for pageUrl");
                        //console.log(data);

                        commit('getPageUrlSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {


                        //console.log("Get all response error for pageUrl");
                        //console.log(response);

                        commit('getPageUrlFailure', response);
                    });
            });

        },

        getPageKey({ commit }, pageData) {

            commit('getPageUrlRequest');
            //console.log(String(pageData.locale));
            //console.log(String(pageData.key));


            return new Promise(resolve => {
                apiService.getPageKey(pageData.locale, pageData.key)
                    .then(({ data }) => {

                        //console.log("Get all response success for pageKey");
                        //console.log(data);

                        commit('getPageUrlSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {


                        //console.log("Get all response error for pageKey");
                        //console.log(response);

                        commit('getPageUrlFailure', response);
                    });
            });

        },


        getPagelinks({ commit }, request) {

       
            commit('getPageLinksRequest');
           // console.log( request.section);

            return new Promise(resolve => {
                apiService.getPagelinks(request)
                    .then(({ data }) => {

                        //console.log("Get all response success for pageMenu");
                        //console.log("response");
                        //console.log(request.section);

                        commit('getPageLinksSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {
                         

                        commit('getPageLinksFailure', response);
                    });
            });

        },

 

        addPage({ commit, dispatch }, page) {

            commit('operationPageRequest');

            return new Promise(resolve => {
                apiService.addPage(page)
                    .then(({ data }) => {

                        //console.log("Saved page with sucess");
                        //console.log(data);

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_saved') }, { root: true });

                        commit('operationPageSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        //console.log("Save page response error");
                        //console.log(response);

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });
                        commit('operationPageFailure', response.data.errors);
                    });
            });

        },

        updatePage({ commit, dispatch }, pageData) {

            //console.log("reached update page");
            //console.log(pageData);
            commit('operationPageRequest');

            return new Promise(resolve => {
                apiService.updatePage(pageData)
                    .then(({ data }) => {

                        //console.log("Update page with sucess");
                        //console.log(data);
                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_saved') }, { root: true });
                        commit('operationPageSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });
                        commit('operationPageFailure', response.data.errors);
                    });
            });

        },

        deletePage({ commit, dispatch }, id) {

            commit('operationPageRequest');

            return new Promise(resolve => {
                apiService.deletePage(id)
                    .then(({ data }) => {

                        //console.log("Deleted page with sucess");
                        //console.log(data);

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_deleted') }, { root: true });

                        commit('operationPageSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response}, { root: true });
                        commit('operationPageFailure', response.data.errors);
                    });
            });

        }



    },

    getters: {
      //  GetLinksForFooter: (state) => (lang) => {
         GetLinksForFooter: (state) =>  {

            return state.pageLinks.filter(l => l.Visible_in_footer == true); //  .find(l => l.Locale = lang  );

        }

        },

    mutations: {

        /**
         *  get page by id
         */

        getPageIdRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.page = {};
        },
        getPageIdSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            state.page = data;

        },
        getPageIdFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.page = {};
        },


        /**
         *  get page by url
         */

        getPageUrlRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.page = {};
         
        },
        getPageUrlSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            state.page = data;
         
        },
        getPageUrlFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.page = {};
        
        },

        /**
         *  get page list
         */

        getPageListRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.pages = [];
            state.total = 0;
            state.totalPages = 0;

        },
        getPageListSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            state.pages = data.result;
            state.total = data.total;
            state.totalPages = data.pages;
        },
        getPageListFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.pages = [];
            state.total = 0;
            state.totalPages = 0;

        },


        /**
         *  get page list by page
         */
        
         getPageLinksRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.pageLinks = [];
        },
        getPageLinksSuccess: function (state, data ) {
            state.operation = { processing: false, success: true, message: "", res: "" };             
            state.pageLinks = data;
        },
        getPageLinksFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.pageLinks = [];
        },

 
        
        /**
        *  operation mutations
        */


        operationPageRequest(state) {
            state.operation = { processing: true, success: false, message: "", result: false };
        },
        operationPageSuccess(state, res) {
            state.operation = { processing: false, success: true, message: res, result: true };

        },
        operationPageFailure(state, res) {
            state.operation = { processing: false, success: false, message: res, result: false };
        },


    }
}
