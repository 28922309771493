//import { apiService } from '../services';
import { brokerService } from '../services';
import i18n from '../i18n'


export const products = {
    namespaced: true,
    state: {
        operation: {},
        products: [],
        totalproducts: -1,
        productsDetails: [],
        loadingProductDetail: false,
        loadingProductDetailId: -1,
        productsmetadata: {},
        address: {},
        availability: {},

        getProductsError: false
    },
    actions: {

        getProductsMetadata({ commit, dispatch }) {

            commit('getProductsMetadataRequest');
      // console.log("Loading products");

            return new Promise((resolve, reject) => {
                brokerService.getProductsMetadata()
                    .then(({ data }) => {
 
                        commit('getProductsMetadataSucess', data);
                        resolve(data);
                    })
                    .catch( error => {

                     
                        commit('getProductsMetadataFailure', error);

                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });


                        reject(error);

                    });
            });
        },

        getProducts({ commit, dispatch }, search) {

            commit('searchProductsRequest');

            return new Promise((resolve, reject) => {
                brokerService.getProducts(search)
                    .then(({ data }) => {

                     
                        commit('searchProductsSuccess', data);
                        resolve(data);


                    })
                    .catch( error => {
                         
                        commit('searchProductsFailure', error);
                         

                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });
                         
                        reject(error);

                    });
            });

        },


        getProductDetail({ commit, dispatch, state }, id) {


            commit('getProductDetailRequest', id);

            if (state.productsDetails.find(f => f.productid == id)) {

                commit('getProductDetailSucess', null);
                return;
            }

            return new Promise((resolve, reject) => {
                brokerService.getProductDetail(id)
                    .then(({ data }) => {

                     

                        commit('getProductDetailSucess', data.data.details);

                        resolve(data.data.details);

                    })
                    .catch( error => {

                        commit('getProductDetailFailure', error);

                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });

                        reject(error);


                    });
            });

        },


        setAddress({ commit }, value) {
    
            commit('setAddress', value);
            localStorage.setItem('address'  , JSON.stringify(value));
             

        }
      



    },

    getters: {

        ProductGroups: (state) => (id) => {

            if (state.productsDetails.lenght == 0) {
                return "";
            }


            var product = state.productsDetails.find(e => e.productid == id);

            if (product) {
                return product.groups;
            } else {
                return "";
            }

        },
        ProductAtributes: (state) => (id) => {

            if (state.productsDetails.lenght == 0) {
                return "";
            }

            var product = state.productsDetails.find(e => e.productid == id);

            if (product) {
                return product.groups.attributes;
            } else {
                return "";
            }

        },

        GetAddress: (state) =>   {

            
            return state.address;

        },
        // ProductDetailField: (state,id)  => {

        //     //return state.todos.filter(todo => todo.done)
        //     return state.productdetail.details.groups.find(e => e.groupid == 1).attributes.find(e1 => e1.attrid == id).value;
        // },
        // ProductDetailPrice: (state) => {

        //     //return state.todos.filter(todo => todo.done)
        //     return this.ProductDetailField(state, 4);
        // },
        //ProductFieldFromProductList: (state,product,id) => {
        //    // var attributes = product.groups.find(e => e.groupid == 1).attributes.find(e1 => e1.attrid == id).value; 
        //    return product.groups.find(e => e.groupid == 1).attributes.find(e1 => e1.attrid == id).value; 
        // },
        // ProductPriceFromProductList: (state, product) => {
        //     // var attributes = product.groups.find(e => e.groupid == 1).attributes.find(e1 => e1.attrid == id).value; 
        //     return this.ProductFieldFromProductList(state, product, 4);
        // }     

    },
    mutations: {

        /**
         *  Product Details
         */

        getProductDetailRequest(state, id) {
            state.loadingProductDetail = true;
            state.loadingProductDetailId = id;
            state.processing = { processing: true, success: false, error: "" };

        },

        getProductDetailSucess: function (state, result) {

            state.loadingProductDetail = false;
            state.loadingProductDetailId = -1;

            state.processing = { processing: false, success: true, error: "" };

          //  console.log(result);

            if (result != null) {
                state.productsDetails.push(result);
            }
        },
        getProductDetailFailure: function (state, data) {

            state.loadingProductDetail = false;
            state.loadingProductDetailId = -1;

            state.processing = { processing: false, success: false, error: data };
        },


        /*
         * Get product metadata
         */

        getProductsMetadataRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.productsmetadata = {};
        },
        getProductsMetadataSucess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            data.data.groups.sort((a, b) => (a.group_position > b.group_position) ? 1 : ((b.group_position > a.group_position) ? -1 : 0));
            for (var i = 0; i < data.data.groups.length; i++) {
                data.data.groups[i].attributes.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));
            }
            state.productsmetadata = data.data;

        },
        getProductsMetadataFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.productsmetadata = {};
        },

        /**
         *  get product 
         */

        searchProductsRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.products = [];
            state.getProductsError = false;
        },
        searchProductsSuccess: function (state, result) {

             state.totalproducts = result.data.nresults;
          
            state.products = result.data.offerlist; 

            state.operation = { processing: false, success: true, message: "", res: "" };

            state.getProductsError = false;

        },
        searchProductsFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred'), res: data };
            state.products = [];
            state.getProductsError = true;
        },
       
       
        setAddress: function (state, data) {
            state.address =  data; 
        },

    }
}
