<template>
    <div class="profile-details">

        <b-container class="px-0 py-4" v-show="!Processing" v-if="!Profile.getUserDetailsError">
            <b-row no-gutters class="mb-2">
                <b-col class="hide-in-desktop"></b-col>
                <b-col cols="auto"><div class="text-bold-20">{{$t('profile_page.details.title')}}</div></b-col>
                <b-col></b-col>
            </b-row>
            <b-row no-gutters class="mb-4">
                <b-col class="hide-in-desktop"></b-col>
                <b-col cols="auto">
                    <div class="description description-profile">
                        {{$t('profile_page.details.description')}}
                    </div>
                </b-col>
                <b-col></b-col>
            </b-row>
            <b-row no-gutters>
                <b-col>
                    <div class="profile-block">
                        <div class="header bg-secondary">
                            <b-container class="py-2 px-4">
                                <b-row>
                                    <b-col>
                                        <div class="title text-semibold-14 mt-1">
                                            {{$t('profile_page.details.title_block_account_data')}}
                                        </div>
                                    </b-col>
                                    <b-col cols="auto" class="hide-in-mobile">
                                        <button v-if="showUserFormAcountDataDesktop == false" @click="showUserFormAcountDataDesktop = true" class="link-primary">{{$t('change')}}</button>
                                        <button v-else @click="showUserFormAcountDataDesktop = false" class="link-primary">{{$t('cancel')}}</button>
                                    </b-col>
                                    <b-col cols="auto" class="hide-in-desktop">
                                        <button @click="showUserFormAcountDataMobile = true" class="link-primary">{{$t('change')}}</button>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                        <div class="body">
                            <div v-if="showUserFormAcountDataDesktop">
                                <user-form-account-data @saved="saveUserAccount"></user-form-account-data>
                            </div>
                            <div v-else>
                                <b-container class="p-4">
                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('firstname')}}</div>
                                            <div class="text-regular-14 text-transform-none">{{Account.name}} &nbsp; {{Account.surname}}</div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('phone')}}</div>
                                            <div class="text-regular-14">{{getContactFormat(Account.contact)}}</div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('email')}}</div>
                                            <div class="text-regular-14">{{ Account.email}}</div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('date_of_birth')}}</div>
                                            <div class="text-regular-14">{{$formatDate( FixDate(Account.birth_date))}}</div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('genre.title')}}</div>
                                            <div class="text-regular-14">{{ GetGender }}</div>
                                        </b-col>
                                    </b-row>

                                </b-container>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row no-gutters>
                <b-col>
                    <div class="profile-block">
                        <div class="header bg-secondary">
                            <b-container class="py-2 px-4">
                                <b-row>
                                    <b-col>
                                        <div class="title text-semibold-14 mt-1">
                                            {{$t('profile_page.details.title_block_holder_data')}}
                                        </div>
                                    </b-col>
                                    <b-col cols="auto" class="hide-in-mobile">
                                        <button v-if="showUserFormHolderDataDesktop == false" @click="showUserFormHolderDataDesktop = true" class="link-primary">{{$t('change')}}</button>
                                        <button v-else @click="showUserFormHolderDataDesktop = false" class="link-primary">{{$t('cancel')}}</button>
                                    </b-col>
                                    <b-col cols="auto" class="hide-in-desktop">
                                        <button @click="showUserFormHolderDataMobile = true" class="link-primary">{{$t('change')}}</button>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                        <div class="body">
                            <div v-if="showUserFormHolderDataDesktop">
                                <user-form-holder-data @saved="saveUserData"></user-form-holder-data>
                            </div>
                            <div v-else>
                                <b-container class="p-4">

                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('fullname')}}</div>
                                            <div class="text-regular-14 text-transform-none">{{Accountable.full_name}}</div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('tax_number')}}</div>
                                            <div class="text-regular-14">{{Accountable.nif}}</div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('address_of_contact')}}</div>
                                            <div class="text-regular-14">{{Accountable.address}}</div>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('phone_of_contact')}}</div>
                                            <div class="text-regular-14">{{getContactFormat(Accountable.contact)}}</div>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('doctype')}}</div>
                                            <div class="text-regular-14">{{Accountable.doctype}}</div>
                                        </b-col>
                                    </b-row>


                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('docnumber')}}</div>
                                            <div class="text-regular-14">{{Accountable.docnumber}}</div>
                                        </b-col>
                                    </b-row>



                                    <b-row class="mb-3">
                                        <b-col>
                                            <div class="text-semibold-12">{{$t('availability_for_contact')}}</div>
                                            <div v-for="(a,index) in AccountableAvailability" :key="index" class="text-regular-14">
                                                {{a}}
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row no-gutters>
                <b-col>
                    <div class="profile-block">
                        <div class="header bg-secondary">
                            <b-container class="py-2 px-4">
                                <b-row>
                                    <b-col>
                                        <div class="title text-semibold-14 mt-1">
                                            {{$t('profile_page.details.title_block_security')}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                        <div class="body">
                            <b-container class="p-0">
                                <b-row no-gutters class="border-bottom-gray-200">
                                    <b-col v-if="RequestCodeForChangePasswordProcessing">
                                        <b-overlay :show="RequestCodeForChangePasswordProcessing" spinner-variant="primary" style="height: 100px;"></b-overlay>
                                    </b-col>
                                    <b-col v-else-if="showUserFormChangePasswordDesktop">
                                        <b-container class="p-0 pt-4">
                                            <b-row class="px-4">
                                                <b-col>
                                                    <div class="text-bold-20">{{$t('change_password')}}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters>
                                                <b-col>
                                                    <password-change @saved="updatedPasswordSuccess"
                                                                     @on-cancel-click="showUserFormChangePasswordDesktop = false"
                                                                     @on-resend-click="requestChangePassword">
                                                    </password-change>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>
                                    <b-col v-else>
                                        <b-container class="p-4">
                                            <b-row>
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('password')}}</div>
                                                    <div class="text-regular-14">
                                                        <p class="m-0" v-if="$store.state.authentication.user.passwordUpdated" v-html="$formatRelative($store.state.authentication.user.passwordUpdated)"></p>
                                                    </div>
                                                </b-col>
                                                <b-col align-self="center" cols="auto" class="hide-in-mobile">
                                                    <button v-if="showUserFormChangePasswordDesktop == false" @click="requestChangePassword" class="link-primary">{{$t('change')}}</button>
                                                </b-col>
                                                <b-col align-self="center" cols="auto" class="hide-in-desktop">
                                                    <button @click="requestChangePasswordMobile" class="link-primary">{{$t('change')}}</button>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>

                                </b-row>
                                <b-row no-gutters class="border-bottom-gray-200">
                                    <b-col v-if="RequestCodeForDataDownloadProcessing">
                                        <b-overlay :show="RequestCodeForDataDownloadProcessing" spinner-variant="primary" style="height: 100px;"></b-overlay>
                                    </b-col>
                                    <b-col v-else-if="showUserFormRequestDataDownloadDesktop">
                                        <b-container class="p-0 pt-4">
                                            <b-row class="px-4">
                                                <b-col>
                                                    <div class="text-bold-20">{{$t('profile_page.details.request_data_download')}}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters>
                                                <b-col>
                                                    <request-data-download @saved="showUserFormRequestDataDownloadDesktop = false"
                                                                           @on-cancel-click="showUserFormRequestDataDownloadDesktop = false"
                                                                           @on-resend-click="requestDataDownload"></request-data-download>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>
                                    <b-col v-else>
                                        <b-container class="p-4">
                                            <b-row>
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.details.request_data_download')}}</div>
                                                    <div class="text-regular-14" v-if="$store.state.authentication.user.lastRequestDataDownload">
                                                        {{$t('profile_page.details.request_data_download_last_update')}}
                                                        <span class="m-0">{{getDate($store.state.authentication.user.lastRequestDataDownload)}}</span>
                                                    </div>
                                                    <div class="text-regular-14">
                                                        {{$t('profile_page.details.request_data_download_message')}}
                                                    </div>
                                                </b-col>
                                                <b-col align-self="center" cols="auto" class="hide-in-mobile">
                                                    <button @click="requestDataDownload" class="link-primary">{{$t('solicit')}}</button>
                                                </b-col>
                                                <b-col align-self="center" cols="auto" class="hide-in-desktop">
                                                    <button @click="requestDataDownloadMobile" class="link-primary">{{$t('solicit')}}</button>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>
                                </b-row>
                                <b-row no-gutters class="border-bottom-gray-200">
                                    <b-col v-if="RequestCodeForDeleteAccountProcessing">
                                        <b-overlay :show="RequestCodeForDeleteAccountProcessing" spinner-variant="primary" style="height: 100px;"></b-overlay>
                                    </b-col>
                                    <b-col v-else-if="showUserFormRequestDeleteAccountDesktop">
                                        <b-container class="p-0 pt-4">
                                            <b-row class="px-4">
                                                <b-col>
                                                    <div class="text-bold-20">{{$t('profile_page.details.delete_account')}}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters>
                                                <b-col>
                                                    <delete-account @saved="showUserFormRequestDeleteAccountDesktop = false"
                                                                    @on-cancel-click="showUserFormRequestDeleteAccountDesktop = false"
                                                                    @on-resend-click="requestDeleteAccount"></delete-account>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>
                                    <b-col v-else>
                                        <b-container class="p-4">
                                            <b-row>
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.details.delete_account')}}</div>
                                                    <div class="text-regular-14">
                                                        {{$t('profile_page.details.delete_account_message')}}
                                                    </div>
                                                </b-col>
                                                <b-col align-self="center" cols="auto" class="hide-in-mobile">
                                                    <button @click="requestDeleteAccount" class="link-primary">{{$t('delete')}}</button>
                                                </b-col>
                                                <b-col align-self="center" cols="auto" class="hide-in-desktop">
                                                    <button @click="requestDeleteAccountMobile" class="link-primary">{{$t('delete')}}</button>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row no-gutters>
                <b-col>
                    <div class="profile-block">
                        <div class="header bg-secondary">
                            <b-container class="py-2 px-4">
                                <b-row>
                                    <b-col>
                                        <div class="title text-semibold-14 mt-1">
                                            {{$t('profile_page.details.title_block_privacy')}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>
                        <div class="body">
                            <b-container class="px-4 pt-4 pb-0">
                                <b-row v-if="PrivacyItems.length > 0">
                                    <b-col>

                                        <privacy-checkboxes v-if="!Processing"
                                                            :value="Privacy"
                                                            :items="PrivacyItems"
                                                            @change="userPrivacyChange"
                                                            @input="saveUserPrivacy"
                                                            :loading="userPrivacyLoading">
                                        </privacy-checkboxes>

                                        <!--<privacy-checkboxes v-if="!Processing"
                                                            :value="Privacy"
                                                            :items="PrivacyItems"
                                                            @input="saveUserPrivacy"
                                                            readonly=true
                                                            :loading="userPrivacyLoading">
                                        </privacy-checkboxes>-->


                                    </b-col>
                                </b-row>

                            </b-container>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <div v-show="Processing">
            <b-overlay :show="Processing" spinner-variant="primary" style="height: 1000px;"></b-overlay>
        </div>

        <div v-if="Profile.getUserDetailsError">

            <no-content :text="$t('error.error_get_list')"></no-content>

        </div>


        <generic-modal :data_show="showUserFormAcountDataMobile"
                       @closed="showUserFormAcountDataMobile = false"
                       data_title="editar dados conta">

            <user-form-account-data v-if="showUserFormAcountDataMobile"></user-form-account-data>

        </generic-modal>
        <generic-modal :data_show="showUserFormHolderDataMobile"
                       @closed="showUserFormHolderDataMobile = false"
                       data_title="editar dados titular">


            <user-form-holder-data v-if="showUserFormHolderDataMobile"></user-form-holder-data>


        </generic-modal>
        <generic-modal :data_show="showUserFormChangePasswordMobile"
                       @closed="showUserFormChangePasswordMobile = false"
                       data_title="alterar palavra-passe">


            <password-change-mobile @saved="updatedPasswordSuccess" v-if="showUserFormChangePasswordMobile"
                                    @on-resend-click="requestChangePasswordMobile">
            </password-change-mobile>

        </generic-modal>

        <generic-modal :data_show="showUserFormRequestDataDownloadMobile"
                       @closed="showUserFormRequestDataDownloadMobile = false"
                       :data_title="$t('profile_page.details.request_data_download')">
            <request-data-download @saved="showUserFormRequestDataDownloadMobile = false"
                                   @on-resend-click="requestDataDownloadMobile"></request-data-download>
        </generic-modal>

        <generic-modal :data_show="showUserFormRequestDeleteAccountMobile"
                       @closed="showUserFormRequestDeleteAccountMobile = false"
                       :data_title="$t('profile_page.details.delete_account')">
            <delete-account @saved="showUserFormRequestDeleteAccountMobile = false"
                            @on-resend-click="requestDeleteAccountMobile"></delete-account>
        </generic-modal>

        <div v-if="$route.query && $route.query.debug">
            {{$store.state.authentication.user}}
        </div>

    </div>
</template>
<script>

    import GenericModal from '@/components/GenericModal.vue'
    import UserFormAccountData from '@/components/profile/UserFormAccountData'
    import UserFormHolderData from '@/components/profile/UserFormHolderData'



    import PasswordChange from '@/components/profile/PasswordChange'
    import PasswordChangeMobile from '@/components/profile/PasswordChange'
    import RequestDataDownload from '@/components/profile/RequestDataDownload'
    import DeleteAccount from '@/components/profile/DeleteAccount'
    import NoContent from '@/components/NoContent.vue';
    import PrivacyCheckboxes from '@/components/PrivacyCheckboxes.vue';
    //import _ from 'lodash';

    export default {
        components: {
            GenericModal,
            UserFormAccountData,
            UserFormHolderData,
            PasswordChange,
            PasswordChangeMobile,
            RequestDataDownload,
            DeleteAccount,
            NoContent,
            PrivacyCheckboxes
        },
        data() {
            return {
                showUserFormAcountDataDesktop: false,
                showUserFormAcountDataMobile: false,
                showUserFormHolderDataDesktop: false,
                showUserFormHolderDataMobile: false,
                showUserFormChangePasswordDesktop: false,
                showUserFormChangePasswordMobile: false,
                showUserFormRequestDataDownloadDesktop: false,
                showUserFormRequestDataDownloadMobile: false,
                showUserFormRequestDeleteAccountDesktop: false,
                showUserFormRequestDeleteAccountMobile: false,
                userInteractionOnPrivacy: false,
                userPrivacyLoading: false,
            };
        },
        methods: {
            saveUserAccount() {
                this.showUserFormAcountDataDesktop = false;
                this.showUserFormAcountDataMobile = false;
            },
            updatedPasswordSuccess() {
                this.showUserFormChangePasswordDesktop = false;
                this.showUserFormChangePasswordMobile = false;
            },
            getDate(date) {
                var newDate = new Date(date);

                return this.FixDate(newDate);

                // return newDate.toISOString().substr(0, 16).replace('T', ' ');
            },
            getContactFormat(val) {
                if (val) {
                    let contact = val.match(/.{1,3}/g);
                    return contact.join(' ');
                }
                return "";
            },
            saveUserData() {
                this.showUserFormHolderDataDesktop = false;
                this.showUserFormHolderDataMobile = false;
            },
            userPrivacyChange() {
                this.userInteractionOnPrivacy = true;
            },
            saveUserPrivacy(val) {

                var self = this;

                this.userPrivacyLoading = true;

                var account_new = {
                    account: self.$store.state.profile.account,
                    accountable: self.$store.state.profile.accountable,
                    privacy: val
                };

                this.$store.dispatch('profile/saveUserPrivacy',
                    {
                        user: account_new,
                        userid: this.$store.state.authentication.user.id,
                        session: this.$store.state.authentication.user.sessionId
                    }).then(

                        () => {

                            this.userPrivacyLoading = false;
                        }

                    ).catch(({ response }) => {

                        this.userPrivacyLoading = false;

                        console.log(response);

                    });
            },
            requestDataDownload() {
                this.$store.dispatch('authentication/requestCodeForDataDownload',
                    {
                        "UserId": this.CurrentUser.id,
                        "Email": this.CurrentUser.email,
                        "ClientSessionId": this.CurrentUser.sessionId
                    }
                ).then(
                    () => {
                        //this.$emit('saved');
                        this.showUserFormRequestDataDownloadDesktop = true;
                    }
                );
            },
            requestDataDownloadMobile() {
                this.$store.dispatch('authentication/requestCodeForDataDownload',
                    {
                        "UserId": this.CurrentUser.id,
                        "Email": this.CurrentUser.email,
                        "ClientSessionId": this.CurrentUser.sessionId
                    }
                ).then(
                    () => {
                        this.showUserFormRequestDataDownloadMobile = true;
                    }
                );
            },
            requestDeleteAccount() {
                this.$store.dispatch('authentication/requestCodeForDeleteAccount',
                    {
                        "UserId": this.CurrentUser.id,
                        "Email": this.CurrentUser.email,
                        "ClientSessionId": this.CurrentUser.sessionId
                    }

                ).then(
                    () => {
                        this.showUserFormRequestDeleteAccountDesktop = true;
                    }
                );
            },
            requestDeleteAccountMobile() {
                this.$store.dispatch('authentication/requestCodeForDeleteAccount',
                    {
                        "UserId": this.CurrentUser.id,
                        "Email": this.CurrentUser.email,
                        "ClientSessionId": this.CurrentUser.sessionId
                    }
                ).then(
                    () => {
                        this.showUserFormRequestDeleteAccountMobile = true;
                    }
                );
            },
            requestChangePassword() {



                this.$store.dispatch('authentication/requestCodeForChangePassword',
                    {
                        "UserId": this.CurrentUser.id,
                        "Email": this.CurrentUser.email,
                        "ClientSessionId": this.$store.state.authentication.user.sessionId
                    }

                ).then(
                    () => {
                        this.showUserFormChangePasswordDesktop = true;
                    }
                );
            },
            requestChangePasswordMobile() {

                this.$store.dispatch('authentication/requestCodeForChangePassword',
                    {
                        "UserId": this.CurrentUser.id,
                        "Email": this.CurrentUser.email,
                        "ClientSessionId": this.$store.state.authentication.user.sessionId
                    }
                ).then(
                    () => {
                        this.showUserFormChangePasswordMobile = true;
                    }
                );
            },
            FixDate(date) {

                if (date) {
                    return date.replace(/-/g, '/');
                } else {


                    return "";
                }



            }
        },
        computed: {
            Profile() {
                return this.$store.state.profile;
            },
            CurrentUser() {
                return this.$store.state.authentication.user;
            },
            Account() {
                if (this.$store.state.profile.account) {
                    return this.$store.state.profile.account;
                } else {
                    return "";
                }
            },
            Accountable() {
                if (this.$store.state.profile.accountable) {
                    return this.$store.state.profile.accountable;
                } else {

                    return "";
                }
            },
            Privacy() {

                if (this.$store.state.profile.privacy) {
                    return this.$store.state.profile.privacy;
                } else {

                    return [];
                }
            },
            AccountableAvailability() {
                if (this.Accountable && this.Accountable.availability) {
                    var list = this.Accountable.availability.split(",");
                    for (var i = 0; i < list.length; i++) {
                        var hours = list[i].split('-');
                        if (hours[0] && hours[1]) {
                            list[i] = hours[0] + "h" + " " + this.$i18n.t('profile_page.details.hour_separator') + " " + hours[1] + "h";
                        }
                    }
                    return list;
                }
                return [];
            },
            Processing() {
                return this.$store.state.profile.operation.processing;
            },
            RequestCodeForDataDownloadProcessing() {
                if (this.$store.state.authentication.requestCodeForDataDownload) {
                    return this.$store.state.authentication.requestCodeForDataDownload.processing;
                } else {

                    return false;
                }
            },
            RequestCodeForDeleteAccountProcessing() {
                return this.$store.state.authentication.requestCodeForDeleteAccount.processing;
            },
            RequestCodeForChangePasswordProcessing() {

                if (this.$store.state.authentication.requestCodeForChangePassword) {
                    return this.$store.state.authentication.requestCodeForChangePassword.processing;
                } else {
                    return false;
                }
            },
            GetGender() {
                return this.$t("genre." + this.Account.genre);
            },
            PrivacyItems() {

                if (this.$store.state.privacy.privacyItemsAccount.data && this.$store.state.privacy.privacyItemsAccount.data.PrivacyItem) {
                    return this.$store.state.privacy.privacyItemsAccount.data.PrivacyItem;
                }
                return [];
            }
        },
        watch: {

        },
        mounted() {
            var breadcrumbitems = [
                { text: this.$i18n.t('profile_page.my_account'), url: '', urlMobile: '/' + this.$i18n.locale + '/profile/profile-menu' },
                { text: this.$i18n.t('profile_page.details.title'), url: '' }
            ]
            this.$store.dispatch('ui/setBreadcrumbItems', breadcrumbitems, { root: true });
        },
        beforeMount() {
            this.$store.dispatch('profile/getUserDetails',
                {
                    id: this.$store.state.authentication.user.id,
                    session: this.$store.state.authentication.user.sessionId
                },
                { root: true }
            );
            this.$store.dispatch('privacy/getPrivacyItemsAccount', this.$i18n.locale);
        },
        destroyed: function () {
            this.$store.dispatch('ui/setBreadcrumbItems', [], { root: true });
        }
    };
</script>
