<template>
    <div class="warning-detail">


        <b-container class="px-0 pt-4">

            <b-row no-gutters class="mb-4">
                <a :href="`/${$i18n.locale}/profile/warnings`" class="go-back">
                    <div class="icon-wrapper-24">
                        <i class="icon-arrow-left"></i>
                    </div>
                    <span>{{$t('back')}}</span>
                </a>
            </b-row>
            <b-row no-gutters>
                <b-col>
                    <div class="profile-block">
                        <div class="header bg-secondary">
                            <b-container class="py-2 px-4">
                                <b-row>
                                    <b-col>
                                        <div class="title text-semibold-14 mt-1">
                                            {{$t('profile_page.warning_detail.title_block')}}
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>

                        <!-- Body -->

                        <div class="body" v-if="Error">
                            <b-container class="p-4">
                                <b-row no-gutters>
                                    <b-col class="hide-in-mobile"></b-col>
                                    <b-col md="6" sm="12">
                                        <no-content :text="$t('error.generic')"></no-content>
                                    </b-col>
                                    <b-col class="hide-in-mobile"></b-col>
                                </b-row>
                            </b-container>
                        </div>




                        <div class="body" v-if="!Processing">
                            <b-container class="p-0" v-if="!Error">
                                <b-row no-gutters>
                                    <b-col>
                                        <b-container class="px-0 py-4">
                                            <b-row no-gutters class="mb-3 px-4">
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.warning_detail.alert_title')}}</div>
                                                    <div class="text-regular-14">{{Details.alert_title}}</div>
                                                </b-col>
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.warning_detail.send_date')}}</div>
                                                    <div class="text-regular-14">{{Details.send_date}} </div>
                                                </b-col>
                                                <!--<b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.warning_detail.alert_type')}}</div>
                                                    <div class="text-regular-14">{{Details.alert_type}}</div>
                                                </b-col>-->
                                            </b-row>
                                            <b-row no-gutters class="mb-3 px-4">
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.warning_detail.alert_id')}}</div>
                                                    <div class="text-regular-14">{{Details.alert}}</div>
                                                </b-col>
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.warning_detail.destiny')}}</div>
                                                    <div class="text-regular-14">{{Details.destiny}}</div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters class="mb-3 px-4">
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.warning_detail.channel')}}</div>
                                                    <div class="text-regular-14">{{Details.channel}}</div>
                                                </b-col>
                                            </b-row>
                                            <!--<b-row no-gutters class="mb-3 px-4">
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.warning_detail.alert_desc')}}</div>
                                                    <div class="text-regular-14">{{Details.alert_desc}}</div>
                                                </b-col>
                                            </b-row>-->
                                            <b-row no-gutters class="mb-3 px-4">
                                                <b-col>
                                                    <div class="text-semibold-12">{{$t('profile_page.warning_detail.alert_message')}}</div>
                                                    <div class="text-regular-14" v-linkified:options="linkifiedOptions" v-html="Details.alert_message">
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-row no-gutters v-if="Details.file_id">
                                                <b-col class="pr-4">
                                                    <a class="download-button" @click="DownloadFile">
                                                        <div class="icon-wrapper-24 icon">
                                                            <i class="icon-download" aria-hidden="true"></i>
                                                        </div>
                                                        <span>{{$t('download')}}</span>
                                                    </a>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </div>

                        <!-- End Body  -->

                        <div v-else>
                            <b-overlay :show="Processing" spinner-variant="primary" style="height: 200px;"></b-overlay>
                        </div>


                    </div>
                </b-col>
            </b-row>
        </b-container>




    </div>
</template>
<script>

    import DetailsParams from '../../models/details_params';
    import NoContent from '@/components/NoContent.vue';

    import { brokerService } from '../../services';

    export default {

        components: {
            NoContent
        },
        data() {
            return {
                details_params: new DetailsParams(
                    this.$route.params.id,
                    this.$store.state.authentication.user.id,
                    this.$store.state.authentication.user.sessionId),
                linkifiedOptions: {
                    nl2br: true,
                    validate: {
                        url: function (value) {
                            return /^(http|ftp)s?:\/\//.test(value);
                        }
                    }
                },
                alertMessage: this.htmlEnconde(`Clique no link abaixo para<b>alert('ola');</b> confirmar a conta e o pedido:\n\nhttp://url_to_confirm_account?orderid=102\n\nOutro link de.pt`)
            };
        },
        methods: {
            htmlEnconde(val) {
                return val.replace(/[\u00A0-\u9999<>&]/gim, function (i) {
                    return '&#' + i.charCodeAt(0) + ';';
                });

            },
            DownloadFile() {
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();

                today = yyyy + '_' + mm + '_' + dd;
                var downloadFilename = this.$i18n.t('profile_page.details.request_data_download_filename') + today + ".csv"
                brokerService.downloadFile(this.Details.file_id, downloadFilename);
            }
        },
        computed: {

            Details() {

                return this.$store.state.profile.alertdetail
            },
            Processing() {
                return this.$store.state.profile.operation.processing;
            },
            Error() {
                return !this.$store.state.profile.operation.success && !this.$store.state.profile.operation.processing;
            },


        },
        mounted() {
            var breadcrumbitems = [
                { text: this.$i18n.t('profile_page.my_account'), url: '/' + this.$i18n.locale + '/profile', urlMobile: '/' + this.$i18n.locale + '/profile/profile-menu' },
                { text: this.$i18n.t('profile_page.warnings.title'), url: '/' + this.$i18n.locale + '/profile/warnings' }
            ]
            this.$store.dispatch('ui/setBreadcrumbItems', breadcrumbitems, { root: true });

            this.$store.dispatch('profile/getAlertDetails',
                this.details_params,
                { root: true }
            );


        },
        destroyed: function () {
            this.$store.dispatch('ui/setBreadcrumbItems', [], { root: true });
        }

    };
</script>