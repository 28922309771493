import AlertMessage from '../models/alert-message';

export const alert = {
    namespaced: true,
    state: {
        alertMessage: new AlertMessage(),
    },
    getters: {
        getAlertMessage: state => {
            return state.alertMessage;
        }
    },
    actions: {
        success({ commit }, alertMessage) {
            //console.log(alertMessage);

            commit('success', alertMessage);
        },
        error({ commit }, alertMessage) {
            commit('error', alertMessage);
        },
        info({ commit }, alertMessage) {
            commit('info', alertMessage);
        },
        warn({ commit }, alertMessage) {
            commit('warn', alertMessage);
        },
        clear({ commit }) {
            commit('clear');
        },
        brokererror({ commit }, alertMessage) {
             commit('brokererror', alertMessage);
        }

    },
    mutations: {
        success(state, alertMessage) {
            //console.log(alertMessage);
            state.alertMessage.title = alertMessage.title;
            state.alertMessage.description = alertMessage.description;
        },
        error(state, alertMessage) {
            state.alertMessage.title = alertMessage.title;
            state.alertMessage.description = alertMessage.description;
        },
        info(state, alertMessage) {
            state.alertMessage.title = alertMessage.title;
            state.alertMessage.description = alertMessage.description;
        },
        warn(state, alertMessage) {
            state.alertMessage.title = alertMessage.title;
            state.alertMessage.description = alertMessage.description;
        },
        brokererror(state, alertMessage) {
            state.alertMessage.title = alertMessage.title;
            state.alertMessage.description = alertMessage.description;
        },
        clear(state) {
            state.alertMessage = new AlertMessage();
        }
    }
}
