<template>
    <div class="news">

        <b-container>

            <b-row no-gutters class="mb-3">
                <b-col cols="auto"><div class="big-text">{{$t('backoffice_news.edit_news')}}</div></b-col>
            </b-row>


            <b-row no-gutters class="flex-grow-1">
                <b-col>
                    <router-link :to="`/${$i18n.locale}/backoffice/news`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col align-self="center">
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('cancel')}}</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                </b-col>
            </b-row>


            <ValidationObserver>
                <b-form @submit.prevent="saveNewsInfo()" autocomplete="off">
                    <b-container class="mt-4">

                        <b-row class="mb-3">
                            <b-col>
                                <datepicker v-model="date" :data_value="date" :data_label="$t('backoffice_news.date')" data_locale="pt"></datepicker>
                            </b-col>
                            <b-col></b-col>
                            <b-col>
                                <b-container class="p-0 pl-5">
                                    <b-row no-gutters>
                                        <b-col cols="auto" class="pr-2">
                                            <div class="py-3">
                                                {{$t('backoffice_page.language')}}:
                                            </div>
                                        </b-col>
                                        <b-col class="pt-1">
                                            <dropdown-default data_parent_dropdown="dropdownLanguage"
                                                              :data_options="langs"
                                                              :data_selected="locale"
                                                              @input="changeLocale"
                                                              :data_settings="{
                                                 minimumResultsForSearch: -1 ,
                                                 theme: 'language',
                                                 dropdownParent: '#dropdownLanguage'}">
                                            </dropdown-default>
                                        </b-col>
                                    </b-row>
                                </b-container>

                            </b-col>

                        </b-row>

                        <b-row class="mb-3">
                            <b-col>
                                <input-text v-model="news.title" :label="$t('backoffice_news.title')"></input-text>
                            </b-col>
                        </b-row>

                        <b-row class="mb-3">
                            <b-col cols="12">
                                <div class="text-semibold-14 mb-3">{{$t('backoffice_news.content')}}</div>
                            </b-col>
                            <b-col class="mb-3">

                                <html-editor v-if="news.html_content" v-model="news.html_content"></html-editor>

                            </b-col>
                        </b-row>
                        
                        <b-row v-if="hasImage" class="mt-3 mb-3">
                            <b-col class="img-placer">
                                <img :src="imagepath" class="img-display" />
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <div class="text-semibold-14 mb-3">{{$t('backoffice_news.image')}}</div>
                                <input type="file" class="form-control" @change="onFileChange" style="padding:3px 5px;" />
                            </b-col>
                        </b-row>

                        <b-row class="mt-4 mb-3">
                            <b-col></b-col>
                            <b-col>
                                <router-link :to="`/${$i18n.locale}/backoffice/news`">
                                    <button type="button" class="button-alternative floor-light">
                                        <span>{{$t('cancel')}}</span>
                                    </button>
                                </router-link>
                            </b-col>
                            <b-col>
                                <button type="button" @click="updateNews" class="button-secondary floor-light">
                                    <span>{{$t('save')}}</span>
                                </button>
                            </b-col>

                        </b-row>


                    </b-container>
                </b-form>
            </ValidationObserver>

        </b-container>
        <!--{{this.$route.params.newsId}}-->
    </div>
</template>

<script>
    import News from '@/models/news.js';
    import InputText from '@/components/InputText.vue'
    import DropdownDefault from '@/components/DropdownDefault.vue';
    import Datepicker from '@/components/Datepicker.vue'
  
    import HtmlEditor from '@/components/HtmlEditor.vue'


    export default {
        name: 'NewsEdit',
        components: {
            InputText,
            DropdownDefault,
            Datepicker,
            HtmlEditor
        },
        data() {
            return {
                news: new News(),
                file1: null,
                locale: this.$i18n.locale,
                hasImage: false,
                imagepath: '',
                date: null,
                langs: this.pushingLangs(),
                
            };
        },
        methods: {

            
            onFileChange(e) {
                this.file = e.target.files[0];
            },

            updateNews() {

                
                //let editor = this.$refs.editor1;
                //var content = editor.getContent();

              //  this.news.html_content = content;
                this.news.date = this.date;
                this.news.id = this.$route.params.newsId;
                this.news.locale = this.locale;

                if (typeof this.file !== "undefined" && this.file !== null){
                  
                    this.saveFile();


                } else {


                    this.updateNewsOnly();

                    //this.$store.dispatch('news/updateNews', this.news).then(
                    //response => {

                    //    console.log("correctly saved");
                    //    console.log(response);
                   

                    //         this.$router.go(-1);


                    //},
                    //error => {
                    //    this.message = (error.response && error.response.data) ||
                    //        error.message ||
                    //        error.toString();
                    //}
                    //);



                }


            },



            updateNewsOnly() {


                this.$store.dispatch('news/updateNews', this.news).then(
                    () => {
 
                        this.$router.go(-1);

                    },
                    error => {
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                    }
                );



            },

            saveFile() {

           

                 let formData = new FormData();
                formData.append('file', this.file);
                
                var filename = "";

                this.$store.dispatch('news/saveFile', {news: this.news, file: formData }).then(
                    response => {

                        //console.log("correctly saved with file");
                        //console.log(response);

                        filename = response;
                        this.news.image = filename;

                        this.updateNewsOnly();


                        //console.log("************* EDIT VUE **************");
                        //console.log("news");
                        //console.log(this.news);

                        
                    //    this.$store.dispatch('news/updateNews', this.news).then(
                    //    response => {

                    //        console.log("correctly saved");
                    //        console.log(response);
                    //         //   window.location.href = '../';

                    //                  this.$router.go(-1);
                               

                    //    },
                    //    error => {
                    //        this.message = (error.response && error.response.data) ||
                    //            error.message ||
                    //            error.toString();
                    //    }
                    //);

                  },
                        error => {
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        }
                );

            },

            pushingLangs() {
                var list = []
                this.$i18n.availableLocales.forEach((value) => {
                    list.push({ id: value, name: value, text: value });
                });

                return list;
            },
            changeLocale(val) {
                this.locale = val.text;
            }
        },
        mounted() {


            this.$store.dispatch('news/getNewsId',this.$route.params.newsId).then(response => {

                //console.log("got data correctly");
                //console.log("Response");
                //console.log(response);

                this.news.title = response.title;
                this.news.locale = response.locale;
                this.news.image = response.image;
                this.locale = response.locale;

                //console.log("Loading the news locale: " + this.news.locale);
                //console.log("Loading the locale: " + this.locale);

                this.news.html_content = response.html_content.replace(/<div/g, "<p").replace(/<\/div>/g, "</p>") ;

                var convertedDate = new Date(response.date);
                var otherdate = convertedDate.toLocaleDateString();
                this.date = otherdate.substr(6, 4) + '-' + otherdate.substr(3, 2) + '-' + otherdate.substr(0, 2);

                //let editor = this.$refs.editor1;
                //editor.setContent(this.news.html_content);
                //editor.getContent();

                this.news.id = response.id;

              //  console.log("image length " + response.image);
                
                var IMAGE_URL = process.env.VUE_APP_IMAGE_PATH; 


                if (typeof response.image !== "undefined" && response.image !== null && response.image != "") {
                    this.imagepath = IMAGE_URL + response.image;
                    this.hasImage = true;
                }
                //else {
                //    console.log("image is null", response.image);
                //}
             
                
            },
                error => {
                    this.message = (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    

        computed: {
        },
    

    };
</script>