var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"h-100",attrs:{"name":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleReset($event)}}},[_c('b-container',{staticClass:"p-4 d-flex flex-column h-100"},[_c('b-row',{staticClass:"mb-0"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-text',{attrs:{"label":"código","data_error":errors[0]},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('button',{staticClass:"link-primary",attrs:{"type":"button"},on:{"click":_vm.resendClick}},[_vm._v(_vm._s(_vm.$t('resend_code')))]),_c('span',{staticStyle:{"display":"inline-block"}},[_c('b-overlay',{staticStyle:{"height":"18px"},attrs:{"show":_vm.RequestCodeForChangePasswordProcessing,"spinner-variant":"primary","no-center":"","spinner-small":""}})],1)])],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|passwordcomplexity|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-text',{attrs:{"type":"password","label":_vm.$t('new_password'),"data_error":errors ? errors[0]: ''},model:{value:(_vm.user.Password),callback:function ($$v) {_vm.$set(_vm.user, "Password", $$v)},expression:"user.Password"}}),_c('strength-password',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.Password.length > 0),expression:"user.Password.length > 0"}],attrs:{"value":_vm.user.Password},on:{"input":function($event){_vm.user.Password = $event}}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-text',{attrs:{"type":"password","label":_vm.$t('confirm_password'),"data_error":errors ? errors[0]: ''},model:{value:(_vm.user.PasswordConfirm),callback:function ($$v) {_vm.$set(_vm.user, "PasswordConfirm", $$v)},expression:"user.PasswordConfirm"}})]}}],null,true)})],1)],1),_c('b-row',{staticClass:"flex-grow-1"},[_c('b-col',{staticClass:"hide-in-mobile",staticStyle:{"text-align":"center"},attrs:{"align-self":"end"}},[_c('button',{staticClass:"button-alternative",attrs:{"type":"button"},on:{"click":_vm.clickedOnCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('cancel')))])])]),_c('b-col',{staticStyle:{"text-align":"center"},attrs:{"align-self":"end"}},[_c('button',{staticClass:"button-secondary floor-light",attrs:{"type":"submit","disabled":invalid}},[_c('span',[_vm._v(_vm._s(_vm.$t('save')))])])])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }