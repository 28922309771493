<template>
    <div>
        <ValidationObserver v-slot="{ invalid }">
            <b-form name="form" @submit.prevent="SaveForm" class="h-100">
                <b-container class="p-4 d-flex flex-column h-100">
                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-text type="text" v-model="AccountEdit.full_name" :label="$t('fullname')" :data_error="errors[0]"></input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-text type="text" :label="$t('tax_number')" v-model="AccountEdit.nif" :data_error="errors[0]"></input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-text type="text" v-model="AccountEdit.address" :label="$t('address_of_contact')" :data_error="errors[0]"></input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required|phone" v-slot="{ errors }">
                                <input-text type="text" :label="$t('phone_of_contact')" v-model="AccountEdit.contact" :data_error="errors[0]"></input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required">
                                <!--<dropdown-default data_parent_dropdown="dropdownBackofficeNewsListLang14"
                      :data_options="DocTypes"
                      :data_label="$t('doctype')"
                      :data_error="errors[0]"
                      @input="AccountEdit.doctype = $event.name"
                      :data_settings="{
                                               minimumResultsForSearch: -1 ,
                                               theme: 'simple'   ,
                                               dropdownParent: '#dropdownBackofficeNewsListLang14' }">
    </dropdown-default>-->
                                <!--<dropdown-default data_parent_dropdown="dropdownBackofficeNewsListLang14"
                      :data_options="DocTypes"
                      :data_label="$t('doctype')"
                      :data_settings="{
                       minimumResultsForSearch: -1 ,
                       theme: 'simple'   ,
                       dropdownParent: '#dropdownBackofficeNewsListLang14' }">
    </dropdown-default>-->



                                <dropdown-default data_parent_dropdown="dropdownBackofficeNewsListLang14"
                                                  :data_options="DocTypes"
                                                  :data_label="$t('doctype')"
                                                  @input="updateDocType"
                                                  :data_selected="$t('AccountEdit.doctype')"
                                                  :data_settings="{
                                                   minimumResultsForSearch: -1 ,
                                                   theme: 'simple'   ,
                                                   dropdownParent: '#dropdownBackofficeNewsListLang14' }">
                                </dropdown-default>
                                 

                            </validation-provider>
                        </b-col>
                    </b-row>


                    <b-row class="mb-3">
                        <b-col>
                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-text v-model="AccountEdit.docnumber"
                                            type="text" :label="$t('docnumber')"
                                            :data_error="errors[0]">
                                </input-text>
                            </validation-provider>
                        </b-col>
                    </b-row>

                  

                    <b-row class="mb-3">
                        <b-col>

                            <validation-provider rules="required" v-slot="{ errors }">
                                <input-multiple-block data_name="availability"
                                                      :data_label="$t('availability_for_contact')"
                                                      :data_options="availability"
                                                      :options_key_value="true"
                                                      data_item_value="value"
                                                      data_item_text="text"
                                                      @input="changeAvailability"
                                                      :value="selectedAvailabity"
                                                      :data_error="errors[0]"
                                                      :data_has_clean="false">
                                </input-multiple-block>
                            </validation-provider>

                        </b-col>
                    </b-row>

                    <b-row class="flex-grow-1">
                        <b-col align-self="end" style="text-align: center">
                            <button class="button-secondary floor-light" style="max-width: 344px;" :disabled="invalid">
                                <span>{{$t('save')}}</span>
                            </button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-form>
        </ValidationObserver>
    </div>
</template>
<script>
    import InputText from '@/components/InputText.vue'
    //import DropdownDefault from '@/components/DropdownDefault.vue'
    import InputMultipleBlock from '@/components/InputMultipleBlock.vue'
    import config from '../../helpers/config'


    import DropdownDefault from '@/components/DropdownDefault.vue'

    export default {
        components: {
            InputText,
            //DropdownDefault,
            InputMultipleBlock,
            DropdownDefault
        },
        data() {
            return {
                AccountEdit:  Object.assign({}, this.$store.state.profile.accountable), 
                availability: config.AVAILABILITY,
                DocTypes: config.DOCUMENT_TYPES
            }
        },
        methods: {
            changeAvailability(value) {

                //if (this.AccountEdit.availability == null) {
                //    this.AccountEdit.availability = [];
                //}

                var result = "";
                for (var i = 0; i < value.length; i++) {
                    result = result.concat(value[i].value, ",");
                }
                result = result.substring(0, result.length - 1);
                this.$set(this.AccountEdit, "availability", result);


            },
            SaveForm() {
              

                var account_new = {
                    accountable: this.AccountEdit,
                    account: this.$store.state.profile.account

                };

                account_new.account.birthdate = this.$store.state.profile.account.birth_date;


                this.$store.dispatch('profile/saveUserDetails',
                    {
                        user: account_new,
                        userid: this.$store.state.authentication.user.id,
                        session: this.$store.state.authentication.user.sessionId
                    }).then(

                        () => {
                            this.$emit('saved');
                            this.$store.dispatch('profile/getUserDetails',
                                {
                                    id: this.$store.state.authentication.user.id,
                                    session: this.$store.state.authentication.user.sessionId
                                });
                        }
                    )
            },
            ConvertToOptionList(list) {
                return list.split(",");
            },
            updateDocType(value) {

              

             this.$set(this.AccountEdit,"doctype",value.id );

            }


        },
        computed: {
            selectedAvailabity() {

                //if (this.$store.state.profile.accountable.availability) {
                //    return this.ConvertToOptionList(this.$store.state.profile.accountable.availability);
                //} else {

                if (this.AccountEdit.availability) {
                return this.ConvertToOptionList(this.AccountEdit.availability);

                }
              
               return [];
            }
        },
        mounted() {

        }
    };
</script>