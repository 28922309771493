import { apiService } from '../services';
import i18n from '../i18n'

export const faqs = {
    namespaced: true,
    state: {
        operation: {},
        faqsList: {},
        faqsInfo: null,
        totalFaqs: 0,
        totalPages: 0
    },
    actions: {

        getFaqsId({ commit }, id) {

            commit('getFaqsIdRequest');
       
            return new Promise(resolve => {
                apiService.getFaqsId( id)
                    .then(({ data }) => {

                   

                        commit('getFaqsIdSucess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {
                         

                        commit('getFaqsIdFailure', response);
                    });
            });

        },

        getFaqsList({ commit }, request) {

            
            commit('getFaqsListRequest');

            return new Promise(resolve => {
                apiService.getFaqsList(request.lang, request.page)
                    .then(({ data }) => {

                     
                        commit('getFaqsListSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {                    

                        console.log(response);

                        commit('getFaqsListFailure');
                    });
            });

        },

       

        addFaqs({ commit, dispatch }, faqs) {

            //console.log("Chegou aqui o pedido");
            //console.log(faqs);

            commit('mutationFaqsRequest');
            //console.log("something");
            return new Promise(resolve => {
                apiService.addFaqs(faqs)
                    .then(({ data }) => {

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_saved') }, { root: true });

                        commit('mutationFaqsSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });

                        commit('mutationFaqsFailure', response.data.errors);




                    });
            });

        },

        
        updateFaqs({ commit, dispatch }, faqsData) {

            commit('mutationFaqsRequest');

            return new Promise(resolve => {
                apiService.updateFaqs(faqsData)
                    .then(({ data }) => {

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_saved') }, { root: true });

                        commit('mutationFaqsSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {
                        
                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });

                        commit('mutationFaqsFailure', response.data.errors);
                    });
            });

        },

        deleteFaqs({ commit, dispatch }, id) {

            commit('mutationFaqsRequest');

            return new Promise(resolve => {
                apiService.deleteFaqs(id)
                    .then(({ data }) => {

                        dispatch('alert/success', { title: i18n.t('success.generic'), description: i18n.t('success.regist_deleted') }, { root: true });

                        commit('mutationFaqsSuccess', data);
                        resolve(data);

                    })
                    .catch(({ response }) => {

                        dispatch('alert/error', { title: "", description: i18n.t('error.an_error_occurred') + ": " + response }, { root: true });

                        commit('mutationFaqsFailure', response.data.errors);
                    });
            });

        }


    },
    mutations: {

        /**
         *  get faqs by id
         */

        getFaqsIdRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.faqsInfo = null;
        },
        getFaqsIdSucess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            state.faqInfo = data;
        },
        getFaqsIdFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred') , res: data };
            state.faqsInfo = null;
        },


        /**
         *    get faqs list
         */
         
        getFaqsListRequest(state) {
            state.operation = { processing: true, success: false, message: "", res: "" };
            state.faqsList = [];
            state.numFaqs = 0;
            state.totalFaqs = 0;
            state.totalPages = 0;
        },
        getFaqsListSuccess: function (state, data) {
            state.operation = { processing: false, success: true, message: "", res: "" };
            state.faqsList = data.result;       
            state.totalFaqs = data.total;
            state.totalPages = data.pages;
        },
        getFaqsListFailure: function (state, data) {
            state.operation = { processing: false, success: false, message: i18n.t('error.an_error_occurred') , res: data };
            state.faqsList = [];
            state.totalFaqs = 0;
            state.totalPages = 0;
        },


        /**
         *  save faqs, edit faqs, delete faqs
         */

        mutationFaqsRequest(state) {
            state.operation = { processing: true, success: false, message: "", result: false };
        },
        mutationFaqsSuccess(state, res) {
            state.operation = { processing: false, success: true, message: res, result: true };

        },
        mutationFaqsFailure(state, res) {
            state.operation = { processing: false, success: false, message: res, result: false };
        },

    }
}
