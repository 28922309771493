import { brokerService } from '../services';


//import OrderCreate from '../models/order_create';
//import OrderUser from '../models/order_user';
//import OrderAddress from '../models/order_address';


import Constants from '../helpers/config';

import i18n from '../i18n';


export const orders = {
    namespaced: true,
    state: {
        operation: {},    

        orderType: "request",
        accountid: "",
        addressid: "",
        addrproductid: "",
        accountable: {},
        account: {},
        order: {},
        step: 1,
        response: {},
        error: {},
        orderid: {},
        product: {},
        address: {},
        availability: {},

        created_draft: false,

        getUserDetailsProcessing: false,
        createOrderDraftProcessing: false,

        createContactRequestProcessing: false,

        confirmOrderProcessing: false,
        getProductAvailabilityProcessing: false,
        getProductDetailProcessing: false,
        cancelOrderProcessing: false

    },
    actions: {

        resetState({ commit }) {



            commit('resetState');

        },



        initOrder({ commit }, data) {

           

            commit('initOrder', data);

        },


        getUserDetails({ commit, dispatch }, param) {

            commit('getUserDetailsRequest');

            return new Promise((resolve, reject) => {
                brokerService.getUserDetails(param.id, param.session)
                    .then(({ data }) => {

                        commit('getUserDetailsSuccess', data);

                        resolve(data);
                    })
                    .catch( error => {

                        commit('getUserDetailsFailure', error);

                          // todo: notifications should be removed from here they are ui not state logic
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });


                        reject(error);
                    });
            });
        },

        cancelOrder({ commit, dispatch }, params) {

            commit('cancelOrderRequest');


            return new Promise((resolve, reject) => {
                brokerService.cancelOrder({ accountable: params.accountable }, params.orderid, params.accountid, params.sessionid)
                    .then(({ data }) => {

                        commit('cancelOrderSuccess');


                        resolve(data);
                    })
                    .catch(error => {

                       
                        commit('cancelOrderFailure');

                          // todo: notifications should be removed from here they are ui not state logic
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });


                        reject(error);

                    });
            });

        },


        createContactRequest({ commit, dispatch }, params) {


            commit('createContactRequestRequest');


            return new Promise((resolve, reject) => {
                brokerService.createContactRequest({ order: params.order }, params.accountid, params.sessionid)
                    .then(({ data }) => {

                         

                        commit('createContactRequestSuccess');

                        resolve(data);
                    })
                    .catch(error  => {

                    
                        commit('createContactRequestFailure');

                        // todo: notifications should be removed from here they are ui not state logic
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });

                         

                        reject(error);

                    

                    });
            });

        },



        confirmContactRequest({ commit, dispatch }, params) {

            commit('confirmOrderRequest');

            //   brokerService.confirmOrder({ accountable: params.accountable }, params.orderid, params.accountid, params.sessionid)
             
            return new Promise((resolve, reject) => {
                brokerService.confirmContactRequest({ accountable: params.accountable }, params.orderid, params.accountid, params.sessionid)
                    .then(({ data }) => {

                        commit('confirmOrderSuccess', data);
                        resolve(data);

                    })
                    .catch(error => {

                         


                        commit('confirmOrderFailure', error);

                        // notifications should be removed from here they are ui not state logic
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });



                        reject(error);

                    });
            });

        },



        createOrderDraft({ commit, dispatch }, param) {

            commit('createOrderDraftRequest');

            var order = {

                type: "request", addressid: String(param.addressid), addrproductid: param.addrproductid

            };

            return new Promise( (resolve, reject) => {
                brokerService.createOrderDraft({ order: order }, param.accountid, param.sessionid)
                    .then(({ data }) => {

                     

                        commit('createOrderDraftSuccess', data);
                        resolve(data);

                    })
                    .catch(error => {

                   
                        commit('createOrderDraftFailure', error);

                        // todo: notifications should be removed from here they are ui not state logic
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });

                        reject(error);

                    });
            });

        },

        //

        confirmOrder({ commit, dispatch }, params) {

            commit('confirmOrderRequest');

       

            var action = null;

            if (params.action) {

                action = params.action;

            }


            return new Promise( (resolve,reject) => {
                brokerService.confirmOrder({ accountable: params.accountable }, params.orderid, params.accountid, params.sessionid, action)
                    .then(({ data }) => {

                        commit('confirmOrderSuccess', data);
                        resolve(data);

                    })
                    .catch( error  => {

                        commit('confirmOrderFailure', error);

                          // todo: notifications should be removed from here they are ui not state logic
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });

                        reject(error);


                    });
            });

        },


        getProductDetail({ commit, dispatch }, id) {


            commit('getProductDetailRequest');


            return new Promise((resolve,reject) => {
                brokerService.getProductDetail(id)
                    .then(({ data }) => {

                        commit('getProductDetailSuccess', data.data.details);



                        resolve(data.data.details);

                    }).catch(error => {


                        commit('getProductDetailFailure', error);

                          // todo: notifications should be removed from here they are ui not state logic
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });


                        reject(error)


                    });
            });

        },


        loadAddress({ commit }) {

            let value = JSON.parse(localStorage.getItem('address'));
            //localStorage.load setItem('address', JSON.stringify(value));

            commit('loadAddress', value);

        },
        clearAddress() {
            localStorage.removeItem('address');
        },
        getProductAvailability({ commit, dispatch }, value) {

            commit('getProductAvailabilityRequest');

            return new Promise((resolve, reject )=> {
                brokerService.getProductAvailability(value)
                    .then(({ data }) => {


                        commit('getProductAvailabilitySuccess', data);

                        resolve(data);

                    }).catch( error => {


                        commit('getProductAvailabilityFailure', error);

                         // todo: notifications should be removed from here they are ui not state logic
                        dispatch('alert/' + error.type, { title: i18n.t('error.an_error_occurred'), description: i18n.t('error.' + error.message) }, { root: true });


                        reject(error)
                    });
            });



        },

    },

    getters: {

    getFileList: (state) => {

       // state.orders.product.offerfiles

     
       
        var files = [];

        if (state.product && state.product.offerfile ) {


            for (var i = 0; i < state.product.offerfiles.length; i++) {

                // console.log("For each");
                //console.log(state.product.offerfiles[i]);

                state.product.offerfiles[i].selected = false;
              //  file.selected = false;
                files.push(state);
            }
         

            //state.product.offerfiles.forEach(function (file) {
            //    console.log("For each");
            //    console.log(file);
            //        file.selected = false;
            //        files.push(file);

            //});

            return files;

        } else {

            return [];
        }

       

       // return state.product.
        
    },



    IsProcessing: (state) => {


            return state.getUserDetailsProcessing ||
                state.createOrderDraftProcessing ||
                state.confirmOrderProcessing ||
                state.getProductAvailabilityProcessing ||
                state.getProductDetailProcessing ||
                state.createContactRequestProcessing
        },


        GetValueById: (state) => (value) => {

            var attribute = state.product.details.groups.find(e => e.groupid == 1).attributes.find(e1 => e1.attrid == value);

            if (attribute) {
                return attribute.value;
            }

            return "";
        },
        ProductPrice: (state) => {

            if (!state.product || !state.product.groups) {
                return "";
            }

            var attribute = state.product.groups.find(e => e.groupid == 1).attributes.find(e1 => e1.attrid == Constants.PRICE_BROKER_FIELD);

            if (attribute) {
                return attribute.value;
            }

            return "";
        },
        ProductName: (state) => {

            if (state.product) {

                return state.product.name;
            } else {
                return "";
            }
        },
        getAccountName: state => {

            if (state.account) {

                return state.account.name + " " + state.account.surname; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },
        getAccountContact: state => {

            if (state.account) {

                return state.account.contact; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },
        getAccountBirthdate: state => {

            if (state.account) {

                return state.account.birth_date; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },
        getAccountGender: state => {

            if (state.account) {

                return state.account.genre; //   { fullname: state.user.firstname + " " + state.user.lastname }

            } else {
                return "";
            }
        },
        getAccountEmail: state => {

            if (state.account && state.account.email) {

                return state.account.email; //   { fullname: state.user.firstname + " " + state.user.lastname }

            }

            return "";
            //if (state.accountable && state.accountable.email) 
            //     {
            //        return state.accountable.email;
            //    }                 
            //    else {

            //    return "";
            //}
        },


    },
    mutations: {


        /*
         */

        initOrder(state, data) {

            state.order = data;

        },

        /**
         * 
         * Create request
         */


        createContactRequestRequest(state) {
            state.createContactRequestProcessing = true;

        },
        createContactRequestSuccess: function (state) {
            state.createContactRequestProcessing = false;

        },
        createContactRequestFailure: function (state) {
            state.createContactRequestProcessing = false;
        },


        /**
         *
         * createOrderDraft
         */


        createOrderDraftRequest(state) {


            state.createOrderDraftProcessing = true;
            state.response = {};
            state.created_draft = false;
        },
        createOrderDraftSuccess: function (state, data) {


            state.createOrderDraftProcessing = false;
            state.response = data;
            state.created_draft = true;
            state.orderid = data.data.orderid;

        },
        createOrderDraftFailure: function (state, data) {

            state.createOrderDraftProcessing = false;
            state.error = data;
            state.created_draft = false;
            state.orderid = null ;
        },

        /**
        *
        * createOrderOrRequest
        */


        confirmOrderRequest(state) {



            state.confirmOrderProcessing = true;
            state.response = {};

        },
        confirmOrderSuccess: function (state, data) {

            state.confirmOrderProcessing = false;
            state.response = data;

        },
        confirmOrderFailure: function (state, data) {

            state.confirmOrderProcessing = false;
            state.error = data;

        },

        /**
      *
      * cancelOrder
      */


        cancelOrderRequest(state) {



            state.cancelOrderProcessing = true;
            state.response = {};

        },
        cancelOrderSuccess: function (state, data) {

            state.cancelOrderProcessing = false;
            state.response = data;

        },
        cancelOrderFailure: function (state, data) {

            state.cancelOrderProcessing = false;
            state.error = data;

        },




        /*
        *   get product detail
        */

        getProductDetailRequest(state) {
            // state.processing = true;
            // state.operation = { processing: true, success: false, message: "", res: "" };

            state.getProductDetailProcessing = true;
            state.product = {};
            state.error = {};
        },
        getProductDetailSuccess: function (state, data) {

            // state.operation = { processing: false, success: true, message: "", res: "" };

            state.getProductDetailProcessing = false;
            state.product = data;

        },
        getProductDetailFailure: function (state, data) {


            state.getProductDetailProcessing = false;
            state.product = {};
            state.error = data;
        },
        /*
         */

        getUserDetailsRequest(state) {

            state.getUserDetailsProcessing = true;
            // state.processing = true;
            state.accountable = {};
            state.account = {};
        },
        getUserDetailsSuccess: function (state, data) {

            state.getUserDetailsProcessing = false;

            state.accountable = data.data.accountable;
            state.account = data.data.account;
        },
        getUserDetailsFailure: function (state, data) {

            state.getUserDetailsProcessing = false;

            state.accountable = {};
            state.account = {};
            state.error = data;
        },

        /*
        getProductAvailability
        */

        getProductAvailabilityRequest(state) {

            state.getProductAvailabilityProcessing = true;
            state.availability = {};

        },
        getProductAvailabilitySuccess: function (state, result) {

            state.getProductAvailabilityProcessing = false;
            state.availability = result.data;


        },
        getProductAvailabilityFailure: function (state, data) {

            state.getProductAvailabilityProcessing = false;
            console.log(data);
            state.availability = {};

        },


        /*
         *  Load address
        */
        loadAddress: function (state, data) {
            state.address = data;
        },

        resetState: function (state) {

            state.operation = { };
            state.orderType = "request";
            state.accountid= "";
            state.addressid= "";
            state.addrproductid= "";
            state.accountable= {};
            state.account= {};
            state.order= {};
            state.step= 1;
            state.response= {};  
            state.orderid= {};
            state.product= {};
            state.address= {};
            state.availability= {};

            state.created_draft= false;

            state.getUserDetailsProcessing= false;
            state.createOrderDraftProcessing= false;

            state.createContactRequestProcessing= false;

            state.confirmOrderProcessing= false;
            state.getProductAvailabilityProcessing= false;
            state.getProductDetailProcessing= false;
            state.cancelOrderProcessing = false;


        }

    }
}
