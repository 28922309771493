<template>
    <div :class="['footer', ShowFooter ? '' : 'd-none']">
        <b-container v-if="!isSimpleLayout" fluid class="p-0">
            <b-row class="section-contacts" no-gutters id="help">
                <b-col md="12" sm="12" class="bg-primary">
                    <div class="contacts-container">
                        <div class="contacts-content">
                            <div class="big-text light title">{{$t('footer.contact_title')}}</div>
                            <div class="description-light mb-5 no-text-transform">{{$t('footer.contact_description')}}</div>
                            <a class="button-primary small mb-4" href="tel:800910550">
                                <div class="icon-wrapper-24 icon">
                                    <i class="icon-phone" aria-hidden="true"></i>
                                </div>
                                <span>800 910 550</span>
                            </a>
                            <!--TODO uncomment this in next version-->
                            <!--<a class="button-primary small  mb-4" href="/coverage">
    <div class="icon-wrapper-24 icon">
        <i class="icon-chat" aria-hidden="true"></i>
    </div>
    <span>{{$t('footer.help_chat')}}</span>
    </a>-->
                            <a class="button-primary small mb-4" href="mailto:geton@geton.pt">
                                <div class="icon-wrapper-24 icon">
                                    <i class="icon-email" aria-hidden="true"></i>
                                </div>
                                <span>geton@geton.pt</span>
                            </a>
                            <!--?t=${getTimeStap()}-->
                            <a :href="`/${$i18n.locale}/page/ajuda`" class="button-primary small mb-5">
                                <span>{{$t('footer.contact_btn_faqs_text')}}</span>
                            </a>



                            <div class="description-light mb-3">{{$t('footer.follow_us')}}</div>
                            <div>
                                <a href="https://www.facebook.com/GetOn.pt/" target="_blank" rel="noopener" class="button-icon-primary small mr-4">
                                    <div class="icon-wrapper-24 icon">
                                        <i class="icon-facebook" aria-hidden="true"></i>
                                    </div>
                                </a>
                                <!--<a href="https://www.instagram.com/dstgroup/" target="_blank" rel="noopener" class="button-icon-primary small mr-4">
            <div class="icon-wrapper-24 icon">
                <i class="icon-instagram" aria-hidden="true"></i>
            </div>
        </a>
        <a href="https://www.youtube.com/user/dstgrupo" target="_blank" rel="noopener" class="button-icon-primary small">
            <div class="icon-wrapper-16 icon">
                <i class="icon-youtube" aria-hidden="true"></i>
            </div>
        </a>-->

                            </div>

                        </div>
                    </div>
                </b-col>
                <!-- dont delete this comment block, iclude next version -->
                <!--<b-col md="6" sm="12">
                <div class="newsletter-container">
                    <div class="top-decorator" id="topDecoratorNewsletter" :style="{ background: newsletterTopDecoratorColor}"></div>
                    <div class="newsletter-content">
                        <div class="big-text mb-3">{{$t('footer.receive_our_newsletter')}}</div>
                        <div class="description mb-4">{{$t('footer.newsletter_description')}}</div>
                        <b-form>
                            <input-text type="email" :label="$t('email')" class="mb-3"></input-text>
                            <div style="text-align: left">
                                <b-form-group class="mb-4">
                                    <b-form-checkbox value="true" class="default-checkbox">{{$t('footer.newsletter_checkbox_text')}}</b-form-checkbox>
                                </b-form-group>
                            </div>
                            <button class="button-alternative" href="/store">
                                <span>{{$t('footer.newsletter_button')}}</span>
                            </button>
                        </b-form>

                    </div>

                </div>
            </b-col>-->
            </b-row>
        </b-container>
        <div class="footer-bottom">
            <div class="footer-links-container">

                <!--@click="loadPage(menuItem.url_Segment)"-->

                <div v-for="(menuItem,index) in MenuItems" :key="index" :class="[MenuItems.length == index + 1 ? '' : 'mb-2']">
                    <a :href="`/${$i18n.locale}/page/${ menuItem.url_Segment }`" class="link-simple-primary">
                        <span>
                            {{menuItem.title}}
                        </span>
                    </a>
                </div>
            </div>
            <div class="footer-copyright-container">
                <span>
                    Copyright © {{new Date().getFullYear()}}
                    <a href="https://www.geton.pt/" class="link-simple-primary-small" target="_blank">
                    GetOn
                     </a>
                     
                    <!--{{$t('footer.developed_by')}}
                    <a href="https://www.innovpoint.com/" class="link-simple-primary-small" target="_blank">Innovation Point.</a>-->

                </span>
            </div>
        </div>
 
    </div>

</template>
<script>
    //import InputText from '@/components/InputText.vue'

    export default {
        props: {
            msg: String
        },
        components: {
            //InputText
        },
        computed: {
            newsletterTopDecoratorColor() {
                return this.$store.state.ui.newsletterTopDecoratorColor;
            },
            isSimpleLayout() {
                return this.$store.state.ui.isSimpleLayout;
            },
            MenuItems() {

                return this.$store.state.page.pageLinks.filter(l => l.locale == this.$i18n.locale).filter(f => f.visible_in_footer);
            },
            ShowFooter() {
                return this.$store.state.ui.displayFooter;
            }
        },
        methods: {
            loadPage(type) {

                 this.$store.dispatch('page/getPageUrl', { url: type, locale: this.$i18n.locale });
            },
            getTimeStap() {

                // todo: hack to allow the page to navigate to another page
                return Math.floor(Date.now() / 1000);
            }
        },


        mounted() {

           // this.$store.dispatch('page/getPagelinks', { lang: this.$i18n.locale , section : "footer" }  );
            //      console.log("----router------");
            //console.log(this.$router);

        },
    }
</script>        