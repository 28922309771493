<template>
    <div class="faqs">

        <b-container>

            <b-row no-gutters class="mb-3">
                <b-col cols="auto"><div class="big-text">{{$t('backoffice_faqs.edit_faq')}}</div></b-col>
            </b-row>


            <b-row no-gutters class="flex-grow-1">
                <b-col>
                    <router-link :to="`/${$i18n.locale}/backoffice/faqs`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col align-self="center">
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">{{$t('cancel')}}</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                </b-col>
            </b-row>


            <ValidationObserver>
                <b-form @submit.prevent="saveFaqs()" autocomplete="off"  >
                    <b-container class="mt-4">

                        <b-row class="mb-3">
                            <b-col cols="3">
                                <input-text v-model="faqs.order" :label="$t('backoffice_faqs.order')" type="number"></input-text>
                            </b-col>
                            <b-col></b-col>
                            <b-col>
                                <b-container class="p-0 pl-5">
                                    <b-row no-gutters>
                                        <b-col cols="auto" class="pr-">
                                            <div class="py-3">
                                                {{$t('backoffice_page.language')}}:
                                            </div>
                                        </b-col>
                                        <b-col class="pt-1">
                                            <dropdown-default data_parent_dropdown="dropdownLanguage"
                                                              :data_options="langs"
                                                              :data_selected="locale"
                                                              @input="changeLocale"
                                                              :data_settings="{
                                                 minimumResultsForSearch: -1 ,
                                                 theme: 'language',
                                                 dropdownParent: '#dropdownLanguage'}">
                                            </dropdown-default>
                                        </b-col>
                                    </b-row>
                                </b-container>

                            </b-col>

                        </b-row>

                        <b-row class="mb-3">
                            <b-col>
                                <input-text v-model="faqs.question" :label="$t('backoffice_faqs.question')"></input-text>
                            </b-col>
                        </b-row>

                        <b-row class="mb-3">
                            <b-col cols="12">
                                <div class="text-semibold-14 mb-3">{{$t('backoffice_faqs.answer')}}</div>
                            </b-col>
                            <b-col class="mb-3">

                              
                                <html-editor   v-model="faqs.answer"></html-editor>

                            </b-col>
                        </b-row>

                        <b-row class="mt-4 mb-3">
                            <b-col></b-col>
                            <b-col>
                                <router-link :to="`/${$i18n.locale}/backoffice/faqs`">
                                    <button type="button" class="button-alternative floor-light">
                                        <span>{{$t('cancel')}}</span>
                                    </button>
                                </router-link>
                            </b-col>
                            <b-col>
                                <button type="button" @click="updateFaqs" class="button-secondary floor-light">
                                    <span>{{$t('save')}}</span>
                                </button>
                            </b-col>

                        </b-row>


                    </b-container>
                </b-form>
            </ValidationObserver>

        


        </b-container>
    </div>
</template>

<script>
    import Faqs from '@/models/faqs.js';
    import InputText from '@/components/InputText.vue'
    import DropdownDefault from '@/components/DropdownDefault.vue';

    //import HtmlEditor from '@/components/HtmlEditor.vue'
   
    import HtmlEditor from '@/components/HtmlEditor.vue'


    export default {
        name: 'FaqsEdit',

        data() {
            return {
                faqs: new Faqs(),
                locale: this.$i18n.locale,
                langs: this.pushingLangs(),
                id: this.$route.params.faqsEditId,
                isLoading: true,
               
            };
        },
        components: {
            InputText,
            DropdownDefault,
          
            HtmlEditor
            /*HtmlEditor*/
        },
        methods: {
                     
            updateFaqs() {

                if (this.faqs.order > 0) {
                

                   // this.faqs.answer = content;
                    this.faqs.locale = this.locale;

               

                    this.$store.dispatch('faqs/updateFaqs', this.faqs).then(
                        () => {

                            
                            this.$router.go(-1);


                        },
                        error => {
                            this.message = (error.response && error.response.data) ||
                                error.message ||
                                error.toString();
                        }
                    );
                } else {
                    alert("O valor da ordem deve ser superior a zero.");
                    }
                
                


            },

             pushingLangs() {
                var list = []
                this.$i18n.availableLocales.forEach((value) => {
                    list.push({ id: value, name: value, text: value });
                });

                return list;
            },
            changeLocale(val) {
                this.locale = val.text;
            }
            ,
            load() {

                this.isLoading = true;

              //  let editor = this.$refs.editor1;


                this.$store.dispatch('faqs/getFaqsId', this.id).then(response => {

                    this.faqs.question = response.question;
                    this.faqs.date = response.date;
                    this.faqs.locale = response.locale;
                    this.faqs.answer = response.answer;
                    this.faqs.order = response.order;

                    this.locale = response.locale;
                     
                    //editor.setContent(this.faqs.answer);
                    //editor.getContent();
                  
                    this.faqs.id = response.id;

                    this.isLoading = false;


                },
                    error => {
                        this.message = (error.response && error.response.data) ||
                            error.message ||
                            error.toString();

                        this.isLoading = false;

                    }
                );


            }


        },
        mounted() {

            this.load();
         
        },
      
        //watch: {
            
        //     id: function () {
        //         this.load();
        //    },

       // },
     

    };
</script>