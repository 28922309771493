<template>
    <div class="pages">

        <b-container>

            <b-row no-gutters class="flex-grow-1" style="text-align: center;">
                <b-col>
                    <!--<form name="form" class="h-100">
                <b-container fluid class="pt-0 pr-4 pl-4 pb-4 h-100 d-flex flex-column">-->
                    <router-link :to="`/${$i18n.locale}/`" class="item-entry">
                        <b-row no-gutters class="flex-grow-1 mt-2">
                            <b-col align-self="center">
                                <button class="button-transparent">
                                    <i class="icon-arrow-left"></i><span style="padding-left:10px;">Voltar</span>
                                </button>
                            </b-col>
                        </b-row>
                    </router-link>
                    <!--</b-container>
            </form>-->
                </b-col>
            </b-row>

            <b-row no-gutters>
                <b-container>
                    <b-row no-gutters class="mt-4">
                        <b-col></b-col>
                        <b-col cols="auto"><div class="big-text">{{Page.title}}</div></b-col>

                        <b-col></b-col>
                    </b-row>
                    <b-row no-gutters class="mb-4">
                        <b-col></b-col>
                        <b-col cols="auto"><div class="description no-text-transform" style="max-width:188px; text-align:center">{{Page.description}}</div></b-col>
                        <b-col></b-col>
                    </b-row>
                </b-container>
            </b-row>

            <b-row class="mb-5">
                <b-container>
                    <b-row no-gutters>
                        <b-col>

                            <div class="description" style="text-align:left;" v-if="Page.key !='faq'">
                                <span v-html="Page.html_content"></span>
                            </div>
                            
                            <div v-else>

                                <b-row v-if="Faqs.length == 0">
                                    <b-col style="text-align:center;">
                                        {{$t('no_faqs_found')}}
                                    </b-col>
                                </b-row>

                                <div no-gutters class="mb-4" v-if="Faqs.length > 0">


                                    <b-container v-for="(item,index) in Faqs" :key="index">

                                        <b-card no-body class="collapse-faq">
                                            <b-row>
                                                <b-card-header header-tag="header" class="header-collapse p-0" role="tab">
                                                    <button block v-b-toggle="'accordion-default' + index">
                                                        <span class="medium-text">{{item.question}}</span>
                                                        <div class="icon-wrapper-24 icon">
                                                            <i class="icon-mais" aria-hidden="true"></i>
                                                        </div>
                                                    </button>
                                                </b-card-header>
                                            </b-row>
                                            <b-row>
                                                <div>
                                                    <b-collapse :id="'accordion-default' + index" accordion="my-accordion" role="tabpanel"
                                                                @show="toggle(index)"
                                                                @hide="toggle(index)">
                                                        <b-card-body class="pt-1">
                                                            <div class="faq-item" v-show="faqsVisible[index]">
                                                                <span v-html="item.answer"></span>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </div>
                                            </b-row>
                                        </b-card>

                                    </b-container>
                                </div>
                            </div>

                        </b-col>
                    </b-row>
                </b-container>
            </b-row>
        </b-container>


        <!--{{$route.params.keyType  }}-->

    </div>
</template>

<script>
    import PageContent from '../models/page_content';
    import Vue from 'vue'

    export default {
        name: 'Page',

        data() {
            return {
                attributes: new PageContent(),
                pagina: new PageContent(),
                key: this.$route.params.keyType,
                toggletFaqs: ['+', '-'],
                faqsVisible: []
            };
        },
        created() {
        },
        components: {
        },
        computed: {
            Page() {
                return this.$store.state.page.page;
            },
            Faqs() {
                return this.$store.state.faqs.faqsList;
            }
        },
        mounted() {
            //this.$store.dispatch('page/getPageUrl', { url: this.$route.params.type, locale: this.$i18n.locale });
            this.$store.dispatch('page/getPageKey', { locale: this.$i18n.locale, key: this.$route.params.keyType });

            this.$store.dispatch('faqs/getFaqsList', { lang: this.$i18n.locale, page: 1 }).then(
                response => {
                    
                    for (var i = 0; i < response.total; i++)
                    {
                        this.faqsVisible.push(false);
                    }
                },
                error => {
                    this.message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                }
            );
            
        },
        methods: {
             toggle(index) {
                Vue.set(this.faqsVisible, index, !this.faqsVisible[index])
                //this.faqsVisible[index] = !this.faqsVisible[index];
            }
        }
    };
</script>
